import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "co-facilitating-an-introduction-to-relational-databases-workshop-at-colang-2024",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#co-facilitating-an-introduction-to-relational-databases-workshop-at-colang-2024",
        "aria-label": "co facilitating an introduction to relational databases workshop at colang 2024 permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Co-facilitating an Introduction to Relational Databases workshop at CoLang 2024`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/512ad427218e344b5afb944f3914bb13/b5b8d/moo.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.75675675675677%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAALiMAAC4jAHM9rsvAAADZUlEQVQoz0WTy0sqURyAXRW0SGqhFWRwd9aidJHZIqRCC3pgFATia2kPahQxMDNXec0cgkDLhoKyfGSWjxltjo3Ow8fMkFQQ9Wf0H1y0y73f4nA2H9858PsJMAzDcZwkyUqLWotqtVqpVKrVKsuyPM+zLMtx3M/5c2EYhiRJAYqihULh9vYWQZBQKHRycnJ6ehoMBs/Pz4PBIAzDgUDg6OjI6/X6fD6/3w/DcCKRqNfrNE3/lZ1Op8vlgiDIYDBMT08vLS2tra2ZzWaDwaBWq0dHR4eHh+VyuUwmUyqVDoeDZdmmjGEYAACCIJfLdXx8vLe3p9PpjEajxWKx2+2rq6tSqVQul6+srCwuLur1+tnZWafT+b9cLBYhCJqZmVGr1TKZbGFhwWQyqVSqyclJsVjc09MjkUh6e3s1Gs38/LzRaLRarbVa7b9ssViEQqFUKhWLxSqVymw26/V6kUgkFAo7OzvlcvmvFoODgzs7OwcHBwzDUBQlyOVyAACn06nVasPhsM/n29jY/P3bR5Llubm5tra2iYkJHMcVCoVUKh0aGlIqlbu7u5VKpVwuN8v5fD4QCGxtbblcLr/ff3FxEYlELi8uo9Go1+tNJpMcx8EwLBKJ+vv7BwYGdDpdpVIhCKJZfnrCrVbr9vZ2JBL5eRWCIBsbGx6PJxwOb25ujoyMeL1eiUTS3t7e1dWl0+lomi4Wi4JMJoPjuM1mgyDo/j4VjUY9Hg+CICiK3t/fJ5NJn89nMBi6u7vbWnR0dGi1WpqmAQBNGUWxq6sru93udrv39/fdbjdFUSzLlkoliqJCoZBGoxEKhSaTyeFwnJ2d2Ww2AMDfcjabRRDk4eEhlUphGPb4+Fir1TiOSyQSAIDLy8uxsbG+vj6CIL6/v7++vnAcLxQKOI4L0ul0+jGdSWfS6XQ+ny+1KJfLjUYjl8ulUqnr6+vx8XGFQhGLxQ4PD4PBYC6X+9mIZjmTydzd3cXjcRRFAQD//Gw2G4vF4vH4+vr68vJyNBqdmpqCYfjm5uZnLgUEQfA8//T0lM1mmwPT/EuRIIhSqUSSJMMw1Wq10Wi8vr7W6/VGo0FRFEEQxWKxUCgIeJ5/f3//+PgAAGAY9vn5SbcolUovLy80TTMMw/M8wzBvb28cxwEAnp+fcRzHMOwPUCgY5rtFsiEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Cow Meets Computer. From https://digital.library.cornell.edu/catalog/ss:550668",
            "title": "Cow Meets Computer. From https://digital.library.cornell.edu/catalog/ss:550668",
            "src": "/static/512ad427218e344b5afb944f3914bb13/fcda8/moo.png",
            "srcSet": ["/static/512ad427218e344b5afb944f3914bb13/12f09/moo.png 148w", "/static/512ad427218e344b5afb944f3914bb13/e4a3f/moo.png 295w", "/static/512ad427218e344b5afb944f3914bb13/fcda8/moo.png 590w", "/static/512ad427218e344b5afb944f3914bb13/efc66/moo.png 885w", "/static/512ad427218e344b5afb944f3914bb13/c83ae/moo.png 1180w", "/static/512ad427218e344b5afb944f3914bb13/b5b8d/moo.png 5356w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Along with my AILT colleagues, `}<a parentName="p" {...{
        "href": "https://linguistics.arizona.edu/person/amy-fountain",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Amy Fountain`}</a>{` and John Ivens, I will be co-facitilitating an introductory workshop on relational databases during the first week of `}<a parentName="p" {...{
        "href": "https://www.colang2024.org/workshops",
        "target": "_self",
        "rel": "nofollow"
      }}>{`CoLang 2024`}</a>{`.  I hope to see you there!  If you're unable to attend but interested in the topic, `}<a parentName="p" {...{
        "href": "https://arizona-linguistics.github.io/ailt-intro-to-databases/colang/",
        "target": "_self",
        "rel": "nofollow"
      }}>{`the workshop materials are freely available`}</a>{`.  Contributions in the form of PRs are always welcome!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      