import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import { HTMLTable } from '@blueprintjs/core';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ol {...{
      "start": 6
    }}>
      <li parentName="ol"><strong parentName="li">{`LinkedIn makes money by selling access to recruiters`}</strong>{`.  Because they sit on a trove of valuable information, LinkedIn is able to charge recruiters for access to advanced search features over this social network.  `}<a parentName="li" {...{
          "href": "https://business.linkedin.com/talent-solutions/recruiter",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Understanding how that service is marketed to recruiters will help you understand how recruiters search are likely to discover you (or not)`}</a>{`.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      