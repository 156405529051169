import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "what-well-learn-about-in-this-course",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-well-learn-about-in-this-course",
        "aria-label": "what well learn about in this course permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What we'll learn about in this course`}</h3>
    <p>{`This course focuses on deep learning (DL) methods for natural language processing (NLP).  `}</p>
    <svg {...{
      "width": "483pt",
      "height": "432pt",
      "viewBox": "0.00 0.00 483.39 432.00",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 428)"
      }}>
        <title parentName="g">{`G`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-428 479.3869,-428 479.3869,4 -4,4"
        }}></polygon>
        <g parentName="g" {...{
          "id": "clust1",
          "className": "cluster"
        }}>
          <title parentName="g">{`cluster_0`}</title>
          <polygon parentName="g" {...{
            "fill": "none",
            "stroke": "#ffffff",
            "points": "8,-35 8,-319 65.3869,-319 65.3869,-35 8,-35"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "36.6935",
            "y": "-302.4",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`layer 1`}</text>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "36.6935",
            "y": "-285.6",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`(input)`}</text>
        </g>
        <g parentName="g" {...{
          "id": "clust2",
          "className": "cluster"
        }}>
          <title parentName="g">{`cluster_hidden`}</title>
          <polygon parentName="g" {...{
            "fill": "#d3d3d3",
            "stroke": "#d3d3d3",
            "points": "113.3869,-8 113.3869,-416 363.3869,-416 363.3869,-8 113.3869,-8"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "238.3869",
            "y": "-399.4",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`hidden layers`}</text>
        </g>
        <g parentName="g" {...{
          "id": "clust3",
          "className": "cluster"
        }}>
          <title parentName="g">{`cluster_h1`}</title>
          <polygon parentName="g" {...{
            "fill": "#d3d3d3",
            "stroke": "#d3d3d3",
            "points": "121.3869,-16 121.3869,-383 202.3869,-383 202.3869,-16 121.3869,-16"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "161.8869",
            "y": "-366.4",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`layer 2 (h1)`}</text>
        </g>
        <g parentName="g" {...{
          "id": "clust4",
          "className": "cluster"
        }}>
          <title parentName="g">{`cluster_h2`}</title>
          <polygon parentName="g" {...{
            "fill": "#d3d3d3",
            "stroke": "#d3d3d3",
            "points": "274.3869,-16 274.3869,-383 355.3869,-383 355.3869,-16 274.3869,-16"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "314.8869",
            "y": "-366.4",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`layer 3 (h2)`}</text>
        </g>
        <g parentName="g" {...{
          "id": "clust5",
          "className": "cluster"
        }}>
          <title parentName="g">{`cluster_3`}</title>
          <polygon parentName="g" {...{
            "fill": "none",
            "stroke": "#ffffff",
            "points": "411.3869,-161 411.3869,-255 472.3869,-255 472.3869,-161 411.3869,-161"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "441.8869",
            "y": "-238.4",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`layer 4`}</text>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "441.8869",
            "y": "-221.6",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`(output)`}</text>
        </g>
        {
          /* b1 */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`b1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#00008b",
            "cx": "36.6935",
            "cy": "-251",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "36.6935",
            "y": "-246.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* a12 */
        }
        <g parentName="g" {...{
          "id": "node5",
          "className": "node"
        }}>
          <title parentName="g">{`a12`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "161.3869",
            "cy": "-158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* b1&#45;&gt;a12 */
        }
        <g parentName="g" {...{
          "id": "edge16",
          "className": "edge"
        }}>
          <title parentName="g">{`b1->a12`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M51.4871,-239.9665C72.9544,-223.9556 112.9402,-194.133 138.3048,-175.2153"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "140.689,-177.8034 146.6125,-169.0192 136.504,-172.1922 140.689,-177.8034"
          }}></polygon>
        </g>
        {
          /* a22 */
        }
        <g parentName="g" {...{
          "id": "node6",
          "className": "node"
        }}>
          <title parentName="g">{`a22`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "161.3869",
            "cy": "-100",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* b1&#45;&gt;a22 */
        }
        <g parentName="g" {...{
          "id": "edge17",
          "className": "edge"
        }}>
          <title parentName="g">{`b1->a22`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M48.2008,-237.0649C70.0068,-210.6585 117.4859,-153.1628 143.4855,-121.6781"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "146.2387,-123.8408 149.9074,-113.9014 140.8411,-119.3836 146.2387,-123.8408"
          }}></polygon>
        </g>
        {
          /* a32 */
        }
        <g parentName="g" {...{
          "id": "node8",
          "className": "node"
        }}>
          <title parentName="g">{`a32`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "161.3869",
            "cy": "-274",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* b1&#45;&gt;a32 */
        }
        <g parentName="g" {...{
          "id": "edge18",
          "className": "edge"
        }}>
          <title parentName="g">{`b1->a32`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M54.559,-254.2953C75.2076,-258.104 109.4385,-264.418 133.5777,-268.8705"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "133.0855,-272.3387 143.5545,-270.7108 134.3553,-265.4548 133.0855,-272.3387"
          }}></polygon>
        </g>
        {
          /* a42 */
        }
        <g parentName="g" {...{
          "id": "node9",
          "className": "node"
        }}>
          <title parentName="g">{`a42`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "161.3869",
            "cy": "-216",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* b1&#45;&gt;a42 */
        }
        <g parentName="g" {...{
          "id": "edge19",
          "className": "edge"
        }}>
          <title parentName="g">{`b1->a42`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M54.0315,-246.1334C74.7596,-240.3153 109.6997,-230.508 134.0694,-223.6677"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "135.112,-227.0104 143.7941,-220.9381 133.2203,-220.2708 135.112,-227.0104"
          }}></polygon>
        </g>
        {
          /* a52 */
        }
        <g parentName="g" {...{
          "id": "node10",
          "className": "node"
        }}>
          <title parentName="g">{`a52`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "161.3869",
            "cy": "-42",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* b1&#45;&gt;a52 */
        }
        <g parentName="g" {...{
          "id": "edge20",
          "className": "edge"
        }}>
          <title parentName="g">{`b1->a52`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M46.0657,-235.291C67.6473,-199.1179 120.9783,-109.7293 146.8483,-66.3684"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "149.958,-67.9872 152.076,-57.6062 143.9466,-64.4006 149.958,-67.9872"
          }}></polygon>
        </g>
        {
          /* x1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`x1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#00008b",
            "cx": "36.6935",
            "cy": "-190",
            "rx": "20.8887",
            "ry": "20.8887"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "36.6935",
            "y": "-185.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`x1`}</text>
        </g>
        {
          /* x1&#45;&gt;a12 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`x1->a12`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M57.0051,-184.7874C77.9509,-179.4121 110.7447,-170.9963 133.9787,-165.0338"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "135.0881,-168.3625 143.9042,-162.4866 133.348,-161.5823 135.0881,-168.3625"
          }}></polygon>
        </g>
        {
          /* x1&#45;&gt;a22 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`x1->a22`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M53.5102,-177.8622C75.4163,-162.051 113.8111,-134.3388 138.3955,-116.5945"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "140.7118,-119.2392 146.7719,-110.5487 136.615,-113.5632 140.7118,-119.2392"
          }}></polygon>
        </g>
        {
          /* x1&#45;&gt;a32 */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`x1->a32`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M54.0315,-201.6798C75.8449,-216.3745 113.3977,-241.672 137.8069,-258.1153"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "135.9025,-261.0524 146.1516,-263.7367 139.8134,-255.2468 135.9025,-261.0524"
          }}></polygon>
        </g>
        {
          /* x1&#45;&gt;a42 */
        }
        <g parentName="g" {...{
          "id": "edge4",
          "className": "edge"
        }}>
          <title parentName="g">{`x1->a42`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M57.0051,-194.2352C77.8312,-198.5777 110.3703,-205.3625 133.5795,-210.2018"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "133.0071,-213.6577 143.511,-212.2727 134.436,-206.8051 133.0071,-213.6577"
          }}></polygon>
        </g>
        {
          /* x1&#45;&gt;a52 */
        }
        <g parentName="g" {...{
          "id": "edge5",
          "className": "edge"
        }}>
          <title parentName="g">{`x1->a52`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M50.0378,-174.1614C72.4347,-147.5783 117.6229,-93.9439 143.0077,-63.8145"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "145.8403,-65.8845 149.6069,-55.9818 140.487,-61.3742 145.8403,-65.8845"
          }}></polygon>
        </g>
        {
          /* x2 */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`x2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#00008b",
            "cx": "36.6935",
            "cy": "-127",
            "rx": "20.8887",
            "ry": "20.8887"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "36.6935",
            "y": "-122.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`x2`}</text>
        </g>
        {
          /* x2&#45;&gt;a12 */
        }
        <g parentName="g" {...{
          "id": "edge6",
          "className": "edge"
        }}>
          <title parentName="g">{`x2->a12`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M57.0051,-132.0497C77.9509,-137.257 110.7447,-145.4099 133.9787,-151.1861"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "133.3551,-154.6375 143.9042,-153.6536 135.044,-147.8443 133.3551,-154.6375"
          }}></polygon>
        </g>
        {
          /* x2&#45;&gt;a22 */
        }
        <g parentName="g" {...{
          "id": "edge7",
          "className": "edge"
        }}>
          <title parentName="g">{`x2->a22`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M57.0051,-122.6019C77.8312,-118.0924 110.3703,-111.0467 133.5795,-106.0212"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "134.4782,-109.4078 143.511,-103.8707 132.9968,-102.5663 134.4782,-109.4078"
          }}></polygon>
        </g>
        {
          /* x2&#45;&gt;a32 */
        }
        <g parentName="g" {...{
          "id": "edge8",
          "className": "edge"
        }}>
          <title parentName="g">{`x2->a32`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M50.2752,-143.0114C72.7768,-169.5383 117.8088,-222.6262 143.089,-252.4288"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "140.5225,-254.8139 149.6604,-260.1758 145.8607,-250.2857 140.5225,-254.8139"
          }}></polygon>
        </g>
        {
          /* x2&#45;&gt;a42 */
        }
        <g parentName="g" {...{
          "id": "edge9",
          "className": "edge"
        }}>
          <title parentName="g">{`x2->a42`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M53.7701,-139.1885C75.631,-154.7917 113.6027,-181.894 138.1008,-199.3796"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "136.2893,-202.3867 146.4621,-205.3474 140.356,-196.6891 136.2893,-202.3867"
          }}></polygon>
        </g>
        {
          /* x2&#45;&gt;a52 */
        }
        <g parentName="g" {...{
          "id": "edge10",
          "className": "edge"
        }}>
          <title parentName="g">{`x2->a52`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M54.0315,-115.1811C75.8449,-100.3115 113.3977,-74.7129 137.8069,-58.0738"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "139.8602,-60.91 146.1516,-52.3855 135.9174,-55.126 139.8602,-60.91"
          }}></polygon>
        </g>
        {
          /* x3 */
        }
        <g parentName="g" {...{
          "id": "node4",
          "className": "node"
        }}>
          <title parentName="g">{`x3`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#00008b",
            "cx": "36.6935",
            "cy": "-64",
            "rx": "20.8887",
            "ry": "20.8887"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "36.6935",
            "y": "-59.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`x3`}</text>
        </g>
        {
          /* x3&#45;&gt;a12 */
        }
        <g parentName="g" {...{
          "id": "edge11",
          "className": "edge"
        }}>
          <title parentName="g">{`x3->a12`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M53.2518,-76.4825C75.1784,-93.0118 113.9444,-122.2355 138.6158,-140.834"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "136.6117,-143.7063 146.7038,-146.9312 140.8255,-138.1167 136.6117,-143.7063"
          }}></polygon>
        </g>
        {
          /* x3&#45;&gt;a22 */
        }
        <g parentName="g" {...{
          "id": "edge12",
          "className": "edge"
        }}>
          <title parentName="g">{`x3->a22`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M56.7276,-69.784C77.7169,-75.8438 110.8201,-85.401 134.1676,-92.1416"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "133.2424,-95.5173 143.8209,-94.9285 135.1841,-88.792 133.2424,-95.5173"
          }}></polygon>
        </g>
        {
          /* x3&#45;&gt;a32 */
        }
        <g parentName="g" {...{
          "id": "edge13",
          "className": "edge"
        }}>
          <title parentName="g">{`x3->a32`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M47.3247,-81.9045C69.7114,-119.6065 121.7247,-207.2037 147.0364,-249.8318"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "144.0395,-251.6401 152.1546,-258.4516 150.0584,-248.0661 144.0395,-251.6401"
          }}></polygon>
        </g>
        {
          /* x3&#45;&gt;a42 */
        }
        <g parentName="g" {...{
          "id": "edge14",
          "className": "edge"
        }}>
          <title parentName="g">{`x3->a42`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M50.0378,-80.2667C72.5496,-107.7083 118.087,-163.2179 143.3966,-194.07"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "140.9145,-196.5628 149.9629,-202.0743 146.3264,-192.123 140.9145,-196.5628"
          }}></polygon>
        </g>
        {
          /* x3&#45;&gt;a52 */
        }
        <g parentName="g" {...{
          "id": "edge15",
          "className": "edge"
        }}>
          <title parentName="g">{`x3->a52`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M57.284,-60.3671C78.1591,-56.6841 110.5925,-50.9618 133.7067,-46.8837"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "134.3562,-50.3233 143.5959,-45.1389 133.1399,-43.4297 134.3562,-50.3233"
          }}></polygon>
        </g>
        {
          /* a13 */
        }
        <g parentName="g" {...{
          "id": "node12",
          "className": "node"
        }}>
          <title parentName="g">{`a13`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "314.3869",
            "cy": "-158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* a12&#45;&gt;a13 */
        }
        <g parentName="g" {...{
          "id": "edge21",
          "className": "edge"
        }}>
          <title parentName="g">{`a12->a13`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M179.5388,-158C205.8794,-158 254.9423,-158 286.065,-158"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "286.2586,-161.5001 296.2586,-158 286.2586,-154.5001 286.2586,-161.5001"
          }}></polygon>
        </g>
        {
          /* a23 */
        }
        <g parentName="g" {...{
          "id": "node13",
          "className": "node"
        }}>
          <title parentName="g">{`a23`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "314.3869",
            "cy": "-100",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* a12&#45;&gt;a23 */
        }
        <g parentName="g" {...{
          "id": "edge26",
          "className": "edge"
        }}>
          <title parentName="g">{`a12->a23`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M178.3452,-151.5714C204.8829,-141.5113 256.438,-121.9676 287.9007,-110.0405"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "289.2427,-113.2749 297.3527,-106.4574 286.7613,-106.7294 289.2427,-113.2749"
          }}></polygon>
        </g>
        {
          /* a33 */
        }
        <g parentName="g" {...{
          "id": "node14",
          "className": "node"
        }}>
          <title parentName="g">{`a33`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "314.3869",
            "cy": "-42",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* a12&#45;&gt;a33 */
        }
        <g parentName="g" {...{
          "id": "edge31",
          "className": "edge"
        }}>
          <title parentName="g">{`a12->a33`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M175.7808,-147.087C202.4353,-126.8783 259.6665,-83.4874 291.6231,-59.2589"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "294.0657,-61.7992 299.9198,-52.9685 289.8366,-56.2211 294.0657,-61.7992"
          }}></polygon>
        </g>
        {
          /* a43 */
        }
        <g parentName="g" {...{
          "id": "node15",
          "className": "node"
        }}>
          <title parentName="g">{`a43`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "314.3869",
            "cy": "-274",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* a12&#45;&gt;a43 */
        }
        <g parentName="g" {...{
          "id": "edge36",
          "className": "edge"
        }}>
          <title parentName="g">{`a12->a43`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M175.7808,-168.913C202.4353,-189.1217 259.6665,-232.5126 291.6231,-256.7411"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "289.8366,-259.7789 299.9198,-263.0315 294.0657,-254.2008 289.8366,-259.7789"
          }}></polygon>
        </g>
        {
          /* a53 */
        }
        <g parentName="g" {...{
          "id": "node16",
          "className": "node"
        }}>
          <title parentName="g">{`a53`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "314.3869",
            "cy": "-216",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* a12&#45;&gt;a53 */
        }
        <g parentName="g" {...{
          "id": "edge41",
          "className": "edge"
        }}>
          <title parentName="g">{`a12->a53`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M178.3452,-164.4286C204.8829,-174.4887 256.438,-194.0324 287.9007,-205.9595"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "286.7613,-209.2706 297.3527,-209.5426 289.2427,-202.7251 286.7613,-209.2706"
          }}></polygon>
        </g>
        {
          /* a22&#45;&gt;a13 */
        }
        <g parentName="g" {...{
          "id": "edge22",
          "className": "edge"
        }}>
          <title parentName="g">{`a22->a13`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M178.3452,-106.4286C204.8829,-116.4887 256.438,-136.0324 287.9007,-147.9595"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "286.7613,-151.2706 297.3527,-151.5426 289.2427,-144.7251 286.7613,-151.2706"
          }}></polygon>
        </g>
        {
          /* a22&#45;&gt;a23 */
        }
        <g parentName="g" {...{
          "id": "edge27",
          "className": "edge"
        }}>
          <title parentName="g">{`a22->a23`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M179.5388,-100C205.8794,-100 254.9423,-100 286.065,-100"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "286.2586,-103.5001 296.2586,-100 286.2586,-96.5001 286.2586,-103.5001"
          }}></polygon>
        </g>
        {
          /* a22&#45;&gt;a33 */
        }
        <g parentName="g" {...{
          "id": "edge32",
          "className": "edge"
        }}>
          <title parentName="g">{`a22->a33`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M178.3452,-93.5714C204.8829,-83.5113 256.438,-63.9676 287.9007,-52.0405"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "289.2427,-55.2749 297.3527,-48.4574 286.7613,-48.7294 289.2427,-55.2749"
          }}></polygon>
        </g>
        {
          /* a22&#45;&gt;a43 */
        }
        <g parentName="g" {...{
          "id": "edge37",
          "className": "edge"
        }}>
          <title parentName="g">{`a22->a43`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M173.3926,-113.6535C199.9826,-143.8931 263.9956,-216.6923 295.803,-252.8654"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "293.2052,-255.2114 302.437,-260.4099 298.462,-250.589 293.2052,-255.2114"
          }}></polygon>
        </g>
        {
          /* a22&#45;&gt;a53 */
        }
        <g parentName="g" {...{
          "id": "edge42",
          "className": "edge"
        }}>
          <title parentName="g">{`a22->a53`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M175.7808,-110.913C202.4353,-131.1217 259.6665,-174.5126 291.6231,-198.7411"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "289.8366,-201.7789 299.9198,-205.0315 294.0657,-196.2008 289.8366,-201.7789"
          }}></polygon>
        </g>
        {
          /* b2 */
        }
        <g parentName="g" {...{
          "id": "node7",
          "className": "node"
        }}>
          <title parentName="g">{`b2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "161.3869",
            "cy": "-332",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "161.3869",
            "y": "-327.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* b2&#45;&gt;a13 */
        }
        <g parentName="g" {...{
          "id": "edge46",
          "className": "edge"
        }}>
          <title parentName="g">{`b2->a13`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M173.3926,-318.3465C199.9826,-288.1069 263.9956,-215.3077 295.803,-179.1346"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "298.462,-181.411 302.437,-171.5901 293.2052,-176.7886 298.462,-181.411"
          }}></polygon>
        </g>
        {
          /* b2&#45;&gt;a23 */
        }
        <g parentName="g" {...{
          "id": "edge47",
          "className": "edge"
        }}>
          <title parentName="g">{`b2->a23`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M171.4257,-316.7778C197.4698,-277.286 267.3543,-171.3174 298.7438,-123.7202"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "301.7276,-125.5532 304.3112,-115.2782 295.8839,-121.6994 301.7276,-125.5532"
          }}></polygon>
        </g>
        {
          /* b2&#45;&gt;a33 */
        }
        <g parentName="g" {...{
          "id": "edge48",
          "className": "edge"
        }}>
          <title parentName="g">{`b2->a33`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M169.8308,-315.9953C195.17,-267.9667 270.6916,-124.8212 301.2925,-66.8194"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "304.3984,-68.433 305.9691,-57.9553 298.2072,-65.1666 304.3984,-68.433"
          }}></polygon>
        </g>
        {
          /* b2&#45;&gt;a43 */
        }
        <g parentName="g" {...{
          "id": "edge49",
          "className": "edge"
        }}>
          <title parentName="g">{`b2->a43`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M178.3452,-325.5714C204.8829,-315.5113 256.438,-295.9676 287.9007,-284.0405"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "289.2427,-287.2749 297.3527,-280.4574 286.7613,-280.7294 289.2427,-287.2749"
          }}></polygon>
        </g>
        {
          /* b2&#45;&gt;a53 */
        }
        <g parentName="g" {...{
          "id": "edge50",
          "className": "edge"
        }}>
          <title parentName="g">{`b2->a53`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M175.7808,-321.087C202.4353,-300.8783 259.6665,-257.4874 291.6231,-233.2589"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "294.0657,-235.7992 299.9198,-226.9685 289.8366,-230.2211 294.0657,-235.7992"
          }}></polygon>
        </g>
        {
          /* a32&#45;&gt;a13 */
        }
        <g parentName="g" {...{
          "id": "edge23",
          "className": "edge"
        }}>
          <title parentName="g">{`a32->a13`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M175.7808,-263.087C202.4353,-242.8783 259.6665,-199.4874 291.6231,-175.2589"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "294.0657,-177.7992 299.9198,-168.9685 289.8366,-172.2211 294.0657,-177.7992"
          }}></polygon>
        </g>
        {
          /* a32&#45;&gt;a23 */
        }
        <g parentName="g" {...{
          "id": "edge28",
          "className": "edge"
        }}>
          <title parentName="g">{`a32->a23`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M173.3926,-260.3465C199.9826,-230.1069 263.9956,-157.3077 295.803,-121.1346"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "298.462,-123.411 302.437,-113.5901 293.2052,-118.7886 298.462,-123.411"
          }}></polygon>
        </g>
        {
          /* a32&#45;&gt;a33 */
        }
        <g parentName="g" {...{
          "id": "edge33",
          "className": "edge"
        }}>
          <title parentName="g">{`a32->a33`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M171.4257,-258.7778C197.4698,-219.286 267.3543,-113.3174 298.7438,-65.7202"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "301.7276,-67.5532 304.3112,-57.2782 295.8839,-63.6994 301.7276,-67.5532"
          }}></polygon>
        </g>
        {
          /* a32&#45;&gt;a43 */
        }
        <g parentName="g" {...{
          "id": "edge38",
          "className": "edge"
        }}>
          <title parentName="g">{`a32->a43`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M179.5388,-274C205.8794,-274 254.9423,-274 286.065,-274"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "286.2586,-277.5001 296.2586,-274 286.2586,-270.5001 286.2586,-277.5001"
          }}></polygon>
        </g>
        {
          /* a32&#45;&gt;a53 */
        }
        <g parentName="g" {...{
          "id": "edge43",
          "className": "edge"
        }}>
          <title parentName="g">{`a32->a53`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M178.3452,-267.5714C204.8829,-257.5113 256.438,-237.9676 287.9007,-226.0405"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "289.2427,-229.2749 297.3527,-222.4574 286.7613,-222.7294 289.2427,-229.2749"
          }}></polygon>
        </g>
        {
          /* a42&#45;&gt;a13 */
        }
        <g parentName="g" {...{
          "id": "edge24",
          "className": "edge"
        }}>
          <title parentName="g">{`a42->a13`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M178.3452,-209.5714C204.8829,-199.5113 256.438,-179.9676 287.9007,-168.0405"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "289.2427,-171.2749 297.3527,-164.4574 286.7613,-164.7294 289.2427,-171.2749"
          }}></polygon>
        </g>
        {
          /* a42&#45;&gt;a23 */
        }
        <g parentName="g" {...{
          "id": "edge29",
          "className": "edge"
        }}>
          <title parentName="g">{`a42->a23`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M175.7808,-205.087C202.4353,-184.8783 259.6665,-141.4874 291.6231,-117.2589"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "294.0657,-119.7992 299.9198,-110.9685 289.8366,-114.2211 294.0657,-119.7992"
          }}></polygon>
        </g>
        {
          /* a42&#45;&gt;a33 */
        }
        <g parentName="g" {...{
          "id": "edge34",
          "className": "edge"
        }}>
          <title parentName="g">{`a42->a33`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M173.3926,-202.3465C199.9826,-172.1069 263.9956,-99.3077 295.803,-63.1346"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "298.462,-65.411 302.437,-55.5901 293.2052,-60.7886 298.462,-65.411"
          }}></polygon>
        </g>
        {
          /* a42&#45;&gt;a43 */
        }
        <g parentName="g" {...{
          "id": "edge39",
          "className": "edge"
        }}>
          <title parentName="g">{`a42->a43`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M178.3452,-222.4286C204.8829,-232.4887 256.438,-252.0324 287.9007,-263.9595"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "286.7613,-267.2706 297.3527,-267.5426 289.2427,-260.7251 286.7613,-267.2706"
          }}></polygon>
        </g>
        {
          /* a42&#45;&gt;a53 */
        }
        <g parentName="g" {...{
          "id": "edge44",
          "className": "edge"
        }}>
          <title parentName="g">{`a42->a53`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M179.5388,-216C205.8794,-216 254.9423,-216 286.065,-216"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "286.2586,-219.5001 296.2586,-216 286.2586,-212.5001 286.2586,-219.5001"
          }}></polygon>
        </g>
        {
          /* a52&#45;&gt;a13 */
        }
        <g parentName="g" {...{
          "id": "edge25",
          "className": "edge"
        }}>
          <title parentName="g">{`a52->a13`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M175.7808,-52.913C202.4353,-73.1217 259.6665,-116.5126 291.6231,-140.7411"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "289.8366,-143.7789 299.9198,-147.0315 294.0657,-138.2008 289.8366,-143.7789"
          }}></polygon>
        </g>
        {
          /* a52&#45;&gt;a23 */
        }
        <g parentName="g" {...{
          "id": "edge30",
          "className": "edge"
        }}>
          <title parentName="g">{`a52->a23`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M178.3452,-48.4286C204.8829,-58.4887 256.438,-78.0324 287.9007,-89.9595"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "286.7613,-93.2706 297.3527,-93.5426 289.2427,-86.7251 286.7613,-93.2706"
          }}></polygon>
        </g>
        {
          /* a52&#45;&gt;a33 */
        }
        <g parentName="g" {...{
          "id": "edge35",
          "className": "edge"
        }}>
          <title parentName="g">{`a52->a33`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M179.5388,-42C205.8794,-42 254.9423,-42 286.065,-42"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "286.2586,-45.5001 296.2586,-42 286.2586,-38.5001 286.2586,-45.5001"
          }}></polygon>
        </g>
        {
          /* a52&#45;&gt;a43 */
        }
        <g parentName="g" {...{
          "id": "edge40",
          "className": "edge"
        }}>
          <title parentName="g">{`a52->a43`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M171.4257,-57.2222C197.4698,-96.714 267.3543,-202.6826 298.7438,-250.2798"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "295.8839,-252.3006 304.3112,-258.7218 301.7276,-248.4468 295.8839,-252.3006"
          }}></polygon>
        </g>
        {
          /* a52&#45;&gt;a53 */
        }
        <g parentName="g" {...{
          "id": "edge45",
          "className": "edge"
        }}>
          <title parentName="g">{`a52->a53`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M173.3926,-55.6535C199.9826,-85.8931 263.9956,-158.6923 295.803,-194.8654"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "293.2052,-197.2114 302.437,-202.4099 298.462,-192.589 293.2052,-197.2114"
          }}></polygon>
        </g>
        {
          /* b3 */
        }
        <g parentName="g" {...{
          "id": "node11",
          "className": "node"
        }}>
          <title parentName="g">{`b3`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "314.3869",
            "cy": "-332",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "314.3869",
            "y": "-327.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* O1 */
        }
        <g parentName="g" {...{
          "id": "node17",
          "className": "node"
        }}>
          <title parentName="g">{`O1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#ff0000",
            "cx": "441.3869",
            "cy": "-187",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
        </g>
        {
          /* b3&#45;&gt;O1 */
        }
        <g parentName="g" {...{
          "id": "edge56",
          "className": "edge"
        }}>
          <title parentName="g">{`b3->O1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M326.3348,-318.3588C348.4598,-293.0979 395.9654,-238.8592 422.4914,-208.5736"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "425.4224,-210.5393 429.3783,-200.7106 420.1566,-205.9271 425.4224,-210.5393"
          }}></polygon>
        </g>
        {
          /* a13&#45;&gt;O1 */
        }
        <g parentName="g" {...{
          "id": "edge51",
          "className": "edge"
        }}>
          <title parentName="g">{`a13->O1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M332.0457,-162.0323C353.1572,-166.8531 388.7436,-174.9791 413.5641,-180.6468"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "412.9404,-184.0944 423.4687,-182.9084 414.4988,-177.27 412.9404,-184.0944"
          }}></polygon>
        </g>
        {
          /* a23&#45;&gt;O1 */
        }
        <g parentName="g" {...{
          "id": "edge52",
          "className": "edge"
        }}>
          <title parentName="g">{`a23->O1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M329.4542,-110.3217C351.3186,-125.2996 392.044,-153.1982 417.8778,-170.8954"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "416.1113,-173.9277 426.3392,-176.6917 420.0674,-168.1527 416.1113,-173.9277"
          }}></polygon>
        </g>
        {
          /* a33&#45;&gt;O1 */
        }
        <g parentName="g" {...{
          "id": "edge53",
          "className": "edge"
        }}>
          <title parentName="g">{`a33->O1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M326.3348,-55.6412C348.4598,-80.9021 395.9654,-135.1408 422.4914,-165.4264"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "420.1566,-168.0729 429.3783,-173.2894 425.4224,-163.4607 420.1566,-168.0729"
          }}></polygon>
        </g>
        {
          /* a43&#45;&gt;O1 */
        }
        <g parentName="g" {...{
          "id": "edge54",
          "className": "edge"
        }}>
          <title parentName="g">{`a43->O1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M329.4542,-263.6783C351.3186,-248.7004 392.044,-220.8018 417.8778,-203.1046"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "420.0674,-205.8473 426.3392,-197.3083 416.1113,-200.0723 420.0674,-205.8473"
          }}></polygon>
        </g>
        {
          /* a53&#45;&gt;O1 */
        }
        <g parentName="g" {...{
          "id": "edge55",
          "className": "edge"
        }}>
          <title parentName="g">{`a53->O1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M332.0457,-211.9677C353.1572,-207.1469 388.7436,-199.0209 413.5641,-193.3532"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "414.4988,-196.73 423.4687,-191.0916 412.9404,-189.9056 414.4988,-196.73"
          }}></polygon>
        </g>
      </g>
    </svg>
    <p>{`DL covers just (an important!) subset of machine learning.  Let's put it into perspective:`}</p>
    <p><img parentName="p" {...{
        "src": "/9948edae9f19dc0d6c773466c99eeade/ai-and-dl.gif",
        "alt": "\"DL is a subset of ML.\"",
        "title": "Deep Learning is a subset of Machine Learning which in turn is a subset of Arificial Intelligence."
      }}></img></p>
    <h3 {...{
      "id": "what-this-course-wont-cover",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-this-course-wont-cover",
        "aria-label": "what this course wont cover permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What this course `}<strong parentName="h3">{`won't`}</strong>{` cover`}</h3>
    <ul>
      <li parentName="ul">{`This is not a calculus course.  To progress into the far reaches of DL, you'll first need to make your way through a strange, beautiful, and potentially treacherous landscape of math. We'll be taking a balloon ride over this world.  `}</li>
    </ul>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "590px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/7a96ccc1bf249a707d44a1539cda6985/e5bc7/balloon-ride.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFBAb/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAAB2N55iWyMR//EABkQAAMAAwAAAAAAAAAAAAAAAAABAhETQf/aAAgBAQABBQKLmhowdVNG6z//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEv/aAAgBAwEBPwGUv//EABURAQEAAAAAAAAAAAAAAAAAAAAS/9oACAECAQE/AaU//8QAGhABAAEFAAAAAAAAAAAAAAAAAQACEiAhMv/aAAgBAQAGPwItcNVM6Z//xAAaEAADAQEBAQAAAAAAAAAAAAAAAREhgTGR/9oACAEBAAE/IW6IdT4Ukcj+j2ZHprIdGB//2gAMAwEAAgADAAAAEMM//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxAP/8QAFhEBAQEAAAAAAAAAAAAAAAAAABFB/9oACAECAQE/EJ1L/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFRgUH/2gAIAQEAAT8QtYIThpWJ7FbUWIfHUKNbhOmPtCYc6ntT/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "\"This course is a balloon ride and I'm your pilot.\"",
              "title": "This course is a balloon ride and I'm your guide.",
              "src": "/static/7a96ccc1bf249a707d44a1539cda6985/1c72d/balloon-ride.jpg",
              "srcSet": ["/static/7a96ccc1bf249a707d44a1539cda6985/a80bd/balloon-ride.jpg 148w", "/static/7a96ccc1bf249a707d44a1539cda6985/1c91a/balloon-ride.jpg 295w", "/static/7a96ccc1bf249a707d44a1539cda6985/1c72d/balloon-ride.jpg 590w", "/static/7a96ccc1bf249a707d44a1539cda6985/a8a14/balloon-ride.jpg 885w", "/static/7a96ccc1bf249a707d44a1539cda6985/fbd2c/balloon-ride.jpg 1180w", "/static/7a96ccc1bf249a707d44a1539cda6985/e5bc7/balloon-ride.jpg 5184w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`This course is a balloon ride and I'm your guide.`}</figcaption>{`
  `}</figure></p>
    <p>{`At times we'll drop down toward the surface to get a closer look, but for the most part we'll observe from a comfortable distance.`}</p>
    <p>{`When you're ready to delve deeper into the math, I can recommend a route.`}</p>
    <h3 {...{
      "id": "whats-so-great-about-deep-learning",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#whats-so-great-about-deep-learning",
        "aria-label": "whats so great about deep learning permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What's so great about deep learning?`}</h3>
    <p>{`Deep learning (DL) approaches have advanced the state of the art in natural language processing and computer vision.`}</p>
    <p>{`There are a few expanations for its success.  `}</p>
    <h4 {...{
      "id": "nonlinear-classifiers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#nonlinear-classifiers",
        "aria-label": "nonlinear classifiers permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Nonlinear classifiers`}</h4>
    <p>{`DL allows us to solve problems that are not linearly separable (i.e., we can deal with more complicated problems/data).`}</p>
    <p>{`Wait, can't decision trees already do that?`}</p>
    <p>{`Yes, absolutely! Decision trees are also interpretable! But they require `}<strong parentName="p">{`feature engineering`}</strong>{`.`}</p>
    <h4 {...{
      "id": "learning-representations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#learning-representations",
        "aria-label": "learning representations permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Learning representations`}</h4>
    <p>{`Rather than relying on hand-crafted features, deep learning can help us `}<strong parentName="p">{`learn representations`}</strong><undefined parentName="p">{` suitable to our task ("multiple layers of non-linear features" `}<span {...{
          "id": "citation-0",
          "data-hover": ""
        }}><span parentName="span" {...{
            "className": "citation-number"
          }}>{`[1]`}</span></span>{`).`}</undefined></p>
    <p>{`As we train a network, it learns to strengthen or weaken connections between neurons according to some objective. The stronger a connection, the more the output of the prior neuron contributes to the output of the next. With deep neural networks, we end up learning hierarchies of such combinations.`}</p>
    {
      /* Most neural networks in NLP leverage some form of **representation learning** (as opposed to providing the input in the form of explicit features). */
    }
    <h4 {...{
      "id": "self-supervised-learning",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#self-supervised-learning",
        "aria-label": "self supervised learning permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Self-supervised learning`}</h4>
    <p>{`With models with millions of learnable parameters being commonplace, deep learning is often criticized as being data hungry.  However, this only a problem if you need `}<strong parentName="p">{`labeled`}</strong>{` data.  Researchers have devised a number of foundational tasks to help large neural networks learn general things about human language (the same sort of trick has been used for computer vision).  Most of these tasks amount to feeding in many snippets of text (ex. terabytes), hiding pieces of each snippet, and training the network to fill in the blanks.  By learning to do this well, the network learns some useful information about how words relate to one other.  This network can serve as s useful starting point for training something more task specific (ex. classifying emails).  `}</p>
    <p>{`While training a large neural network to fill in the blanks well requires time, hardware, and data, the "labels" for this task are "free" (i.e., no human labor to produce) and the resulting network can be reused for many different task.`}</p>
    <h2 {...{
      "id": "do-deep-neural-networks-learn-like-humans",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#do-deep-neural-networks-learn-like-humans",
        "aria-label": "do deep neural networks learn like humans permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Do deep neural networks learn like humans?`}</h2>
    <p><undefined parentName="p">{`No, not really (see `}<span {...{
          "id": "citation-0",
          "data-hover": ""
        }}><span parentName="span" {...{
            "className": "citation-number"
          }}>{`[2]`}</span></span>{`).  `}</undefined></p>
    {
      /* https://www.psychologytoday.com/us/blog/thinking-in-black-white-and-gray/202110/the-core-discovery-neuroscience-the-mind-is-modular */
    }
    <h2 {...{
      "id": "how-does-a-neural-network-learn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-does-a-neural-network-learn",
        "aria-label": "how does a neural network learn permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How does a neural network learn?`}</h2>
    <p>{`Just like logistic regression, neural networks are trained using some form of backpropagation and gradient descent.`}</p>
    <p>{`In essence, backpropogation tells us how much each parameter's current value contributes to some prediction.`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup></p>
    <p>{`While an area of active research, no alternative training algorithm`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{` has yet been shown to be consistently competitive to backpropagation.`}</p>
    <h2 {...{
      "id": "what-makes-neural-networks-hard-to-train",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-makes-neural-networks-hard-to-train",
        "aria-label": "what makes neural networks hard to train permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What makes neural networks hard to train?`}</h2>
    <p>{`Unlike logistic regression`}<sup parentName="p" {...{
        "id": "fnref-3"
      }}><a parentName="sup" {...{
          "href": "#fn-3",
          "className": "footnote-ref"
        }}>{`3`}</a></sup>{`, there is no guarantee we'll find a global minimum when training a deep neural network.`}</p>
    <p>{`It turns out, though, that this isn't such a big problem in practice.`}</p>
    <p>{`There are bunch of tricks we can use to find pretty good solutions.`}</p>
    <h2 {...{
      "id": "is-deep-learning-the-solution-to-all-my-problems",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#is-deep-learning-the-solution-to-all-my-problems",
        "aria-label": "is deep learning the solution to all my problems permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Is deep learning the solution to all my problems?`}</h2>
    <p>{`While deep learning approaches now dominate nearly all task benchmarks in natural language processing and computer vision, they are not without limitations. `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=llGG62fNN64&t=1383s",
        "target": "_self",
        "rel": "nofollow"
      }}>{`In his 2019 Turing Lecture, Yoshua Bengio, a prominent researcher in deep learning, makes a distinction between System 1 (intuitive/unconscious) and System 2 tasks (symbolic reasoning)`}</a>{`.  He believes deep neural networks currently excel at System 1 tasks. `}</p>
    <h2 {...{
      "id": "are-there-any-downsides-to-using-neural-networks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#are-there-any-downsides-to-using-neural-networks",
        "aria-label": "are there any downsides to using neural networks permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Are there any downsides to using neural networks?`}</h2>
    <h3 {...{
      "id": "low-interpretability",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#low-interpretability",
        "aria-label": "low interpretability permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Low interpretability`}</h3>
    <p><strong parentName="p">{`Deep`}</strong>{` neural networks are composed of `}<strong parentName="p">{`many hidden layers`}</strong>{`. The first hidden layer (the first one immediately after the input) learns a useful composition of features.  Subsequent layers learn compositions of compositions ((of compositions)*) of features.  While there are techniques to probe what information each layer of a network encodes, often much of what is learned (at least with language-based tasks) is quite opaque and has a low degree of interpretability.  While this isn't always a problem, it can be depending on how you're using the model.`}<sup parentName="p" {...{
        "id": "fnref-4"
      }}><a parentName="sup" {...{
          "href": "#fn-4",
          "className": "footnote-ref"
        }}>{`4`}</a></sup>{`  For instance, imagine you wanted to train a network to help make a decision about whether or not someone should be granted parole or approved for a mortgage.  The people involved deserve to understand the decision. `}</p>
    <h3 {...{
      "id": "difficult-to-make-targeted-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#difficult-to-make-targeted-changes",
        "aria-label": "difficult to make targeted changes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Difficult to make targeted changes`}</h3>
    <p>{`It can be difficult to make targeted changes to a neural network (ex. if "`}<strong parentName="p">{`a`}</strong>{` and `}<strong parentName="p">{`b`}</strong>{` are present, then `}<strong parentName="p">{`always`}</strong>{` do `}<strong parentName="p">{`c`}</strong>{`").  If you are faced with such a scenario, you're better off taking a hybrid approach (symbolic + statistical).`}</p>
    <h3 {...{
      "id": "prone-to-overfitting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#prone-to-overfitting",
        "aria-label": "prone to overfitting permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prone to overfitting`}</h3>
    <p>{`Because they have so many parameters, neural networks are prone to overfitting.`}</p>
    <h2 {...{
      "id": "who-came-up-with-this-stuff",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#who-came-up-with-this-stuff",
        "aria-label": "who came up with this stuff permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Who came up with this stuff?`}</h2>
    <p>{`Neural networks are not a new idea (nor is the fascination and hype around them). `}<a parentName="p" {...{
        "href": "https://news.cornell.edu/stories/2019/09/professors-perceptron-paved-way-ai-60-years-too-soon",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Frank Rosenblatt is credited with inventing the first form a neural network (the Perceptron) in 1957`}</a>{`:`}</p>
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": 800,
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/cNxadbrN_aI?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    <p>{`Most of the research into and progress in neural methods for AI has only happened in the last 30 years.  They largely fell out of favor (run out of town) in 1969 for roughly a decade, following the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Perceptrons_(book)",
        "target": "_self",
        "rel": "nofollow"
      }}>{`publication of a scathing criticism of the limitations of the Perceptron`}</a>{` from a pair of highly influential AI researchers, `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Marvin_Minsky",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Marvin Minsky`}</a>{` and `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Seymour_Papert",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Seymour Papert`}</a>{`.`}</p>
    <p>{`There were a number of figures`}<sup parentName="p" {...{
        "id": "fnref-5"
      }}><a parentName="sup" {...{
          "href": "#fn-5",
          "className": "footnote-ref"
        }}>{`5`}</a></sup>{` responsible for the reemergence of neural networks.  One key figure is `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Geoffrey_Hinton",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Geoffery Hinton`}</a>{`, who co-invented `}<a parentName="p" {...{
        "href": "http://www.cs.toronto.edu/~hinton/absps/fastnc.pdf",
        "target": "_self",
        "rel": "nofollow"
      }}>{`a method for efficient backpropagation which made deep learning tractable`}</a>{`. `}</p>
    <p>{`Hinton and his students had a number of high profile successes (ex. `}<a parentName="p" {...{
        "href": "https://proceedings.neurips.cc/paper/2012/file/c399862d3b9d6b76c8436e924a68c45b-Paper.pdf",
        "target": "_self",
        "rel": "nofollow"
      }}>{`ImageNet`}</a>{`) using deep neural networks.  `}</p>
    <p>{`If you're curious to learn more about the rise of deep learning, you may enjoy this video where Andrew Ng interviews Geoffery Hinton:`}</p>
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": 800,
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/-eyhCTvrEtE?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    {
      /* 1986, when he co-authored a paper that elaborated on a deep learning technique called [backpropagation](http://www.cs.toronto.edu/~hinton/absps/naturebp.pdf) */
    }
    <h3 {...{
      "id": "popular-approaches",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#popular-approaches",
        "aria-label": "popular approaches permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Popular approaches`}</h3>
    <p>{`In recent years, a few common patterns have emerged in NLP:`}</p>
    <ul>
      <li parentName="ul">{`Large-scale pre-trained neural language models (LLMs) `}<sup parentName="li" {...{
          "id": "fnref-6"
        }}><a parentName="sup" {...{
            "href": "#fn-6",
            "className": "footnote-ref"
          }}>{`6`}</a></sup></li>
      <li parentName="ul">{`Pre-train then fine-tune`}</li>
      <li parentName="ul">{`Framing tasks as text generation (i.e., prompt-based learning)`}</li>
    </ul>
    <h3 {...{
      "id": "exciting-successes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#exciting-successes",
        "aria-label": "exciting successes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Exciting successes`}</h3>
    <p>{`Neural networks have come to dominate natural language processing.  If you `}<a parentName="p" {...{
        "href": "http://nlpprogress.com/",
        "target": "_self",
        "rel": "nofollow"
      }}>{`randomly pick any task`}</a>{` and examine one of the public leaderboards, it's very likely that the top-performing approach (or even the top five systems) is some form of a deep neural network.`}<sup parentName="p" {...{
        "id": "fnref-7"
      }}><a parentName="sup" {...{
          "href": "#fn-7",
          "className": "footnote-ref"
        }}>{`7`}</a></sup></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`natural language generation (NLG)`}</strong>{`: `}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.eduref.net/features/what-grades-can-ai-get-in-college/",
              "target": "_self",
              "rel": "nofollow"
            }}>{`GPT-3 essays score a C average across four college subjects`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://copilot.github.com/",
              "target": "_self",
              "rel": "nofollow"
            }}>{`GPT-3 can help you write programs`}</a></li>
          <li parentName="ul">{`...`}</li>
        </ul>
      </li>
    </ul>
    {
      /* ### Important papers */
    }
    {
      /* - \cite{SCHMIDHUBER201585} */
    }
    {
      /* - [Learning multiple layers of
      representation ()](https://www.cs.toronto.edu/~hinton/absps/tics.pdf) */
    }
    <h1 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next steps`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/courses/snlp-2/deep-and-simple"
        }}>{`Learn about the simplest form of deep neural networks`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/courses/snlp-2/pytorch-intro"
        }}>{`Implement, train, and evaluate a neural network using Pytorch`}</a></li>
    </ul>
    <h1 {...{
      "id": "references",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#references",
        "aria-label": "references permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`References`}</h1>
    <div><ol parentName="div"><li parentName="ol"><b parentName="li">{`Where Do Features Come From?`}</b>{` `}<br parentName="li"></br>{`Hinton, G., 2013. Cognitive Science, Vol 38(6), pp. 1078--1101. Wiley. `}<a parentName="li" {...{
            "href": "https://doi.org/10.1111/cogs.12049",
            "style": {
              "textDecoration": "inherit"
            }
          }}>{`DOI: 10.1111/cogs.12049`}</a></li><li parentName="ol"><b parentName="li">{`Backpropagation and the brain`}</b>{` `}<br parentName="li"></br>{`Lillicrap, T.P., Santoro, A., Marris, L., Akerman, C.J. and Hinton, G., 2020. Nature Reviews Neuroscience, Vol 21(6), pp. 335--346. Springer Science and Business Media {LLC}. `}<a parentName="li" {...{
            "href": "https://doi.org/10.1038/s41583-020-0277-3",
            "style": {
              "textDecoration": "inherit"
            }
          }}>{`DOI: 10.1038/s41583-020-0277-3`}</a></li><li parentName="ol"><b parentName="li">{`Training Neural Networks with Local Error Signals`}</b>{` `}<br parentName="li"></br>{`Nøkland, A. and Eidnes, L.H., 2019. arXiv. `}<a parentName="li" {...{
            "href": "https://doi.org/10.48550/ARXIV.1901.06656",
            "style": {
              "textDecoration": "inherit"
            }
          }}>{`DOI: 10.48550/ARXIV.1901.06656`}</a></li></ol></div>
    <h2 {...{
      "id": "footnotes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#footnotes",
        "aria-label": "footnotes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Footnotes`}</h2>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`The key trick to backpropagation being the chain rule.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}><undefined parentName="li">{`target propagation, local error signals`}<span {...{
              "id": "citation-0",
              "data-hover": ""
            }}><span parentName="span" {...{
                "className": "citation-number"
              }}>{`[3]`}</span></span>{`, `}</undefined><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Extreme_learning_machine",
            "target": "_self",
            "rel": "nofollow"
          }}>{`extreme machine learning`}</a>{`, etc.`}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-3"
        }}>{`Logistic regression is convex.  If our data is linearly separable and we have a sufficiently small learning rate, we're guaranteed to find the global minimum.  In contrast, deep learning is non-convex (i.e., we have no guarantee that we'll find the global minimum).  In a high-dimensional space, we're very likely to end up in a local minimum.`}<a parentName="li" {...{
            "href": "#fnref-3",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-4"
        }}><strong parentName="li">{`model`}</strong>{` here refers to a neural network architecture with parameters that have been "fit" (learned) according to some task.`}<a parentName="li" {...{
            "href": "#fnref-4",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-5"
        }}><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/John_Hopfield",
            "target": "_self",
            "rel": "nofollow"
          }}>{`John Hopfield`}</a>{`, `}<a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Jeffrey_Elman",
            "target": "_self",
            "rel": "nofollow"
          }}>{`Jeffrey Elman`}</a>{`, `}<a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Paul_Smolensky",
            "target": "_self",
            "rel": "nofollow"
          }}>{`Paul Smolensky`}</a>{`, `}<a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/David_Rumelhart",
            "target": "_self",
            "rel": "nofollow"
          }}>{`David Rumelhart`}</a>{`, etc.`}<a parentName="li" {...{
            "href": "#fnref-5",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-6"
        }}>{`An LLM is a neural language model trained to generate text given some input text.  "Large" has come to mean a models with `}<strong parentName="li">{`millions to trillions of parameters`}</strong>{`. Some have taken to calling such models as a kind of `}<a parentName="li" {...{
            "href": "https://fsi.stanford.edu/publication/opportunities-and-risks-foundation-models",
            "target": "_self",
            "rel": "nofollow"
          }}><strong parentName="a">{`foundation model`}</strong></a>{`, as they can serve as a starting point for a range of NLP tasks. We'll learn more about LLMs later in the course.`}<a parentName="li" {...{
            "href": "#fnref-6",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-7"
        }}>{`Serously. Give it a try! `}<a parentName="li" {...{
            "href": "#fnref-7",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      