import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div {...{
      "className": "gatsby-highlight",
      "data-language": "markdown"
    }}><pre parentName="div" {...{
        "className": "language-markdown"
      }}><code parentName="pre" {...{
          "className": "language-markdown"
        }}><span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`##`}</span>{` Introduction`}</span>{`
`}<span parentName="code" {...{
            "className": "token list punctuation"
          }}>{`-`}</span>{` [ ] Introduction of interviewee provided?

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`##`}</span>{` Justification`}</span>{`
`}<span parentName="code" {...{
            "className": "token list punctuation"
          }}>{`-`}</span>{` [ ] Justification of choice of interviewee provided?
  `}<span parentName="code" {...{
            "className": "token list punctuation"
          }}>{`-`}</span>{` [ ] Interview goals identified?

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`##`}</span>{` Interview`}</span>{`
`}<span parentName="code" {...{
            "className": "token list punctuation"
          }}>{`-`}</span>{` [ ] Were there at least six open-ended questions provided along with responses?`}</code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      