import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import { HTMLTable } from '@blueprintjs/core';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`In this unit, we'll examine a variety of methods for representing words and documents as vectors, as well as learn how to compare and evaluate these representations. `}</p>
    <h1 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h1>
    <ul>
      <li parentName="ul">{`describe the distributional hypothesis`}</li>
      <li parentName="ul">{`represent words using multiple dimensions`}</li>
      <li parentName="ul">{`identify semantic relations`}</li>
      <li parentName="ul">{`calculate TF-IDF`}</li>
      <li parentName="ul">{`calculate PPMI`}</li>
      <li parentName="ul">{`identity (term, context) pairs using `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`k`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`k`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03148em"
                  }
                }}>{`k`}</span></span></span></span></span>{`-windows`}</li>
      <li parentName="ul">{`implement cosine similarity in Python`}</li>
      <li parentName="ul">{`implement Euclidean distance in Python`}</li>
      <li parentName="ul">{`describe the skip-gram algorithm`}</li>
      <li parentName="ul">{`describe the CBOW algorithm`}</li>
      <li parentName="ul">{`describe the GloVe algorithm`}</li>
      <li parentName="ul">{`compare and contrast skip-gram, CBOW, and GloVe`}</li>
    </ul>
    <h1 {...{
      "id": "resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#resources",
        "aria-label": "resources permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Resources`}</h1>
    <h2 {...{
      "id": "videos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#videos",
        "aria-label": "videos permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Videos`}</h2>
    <p>{`Be sure to watch the videos in the playlist linked below:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/playlist?list=PL2acInhHkjsagiHAn3yYLsFICgsOg73Y-",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Unit playlist`}</a></li>
    </ul>
    <h2 {...{
      "id": "slides",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#slides",
        "aria-label": "slides permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Slides`}</h2>
    <p>{`These slides correspond to the videos for the unit:`}</p>
    <ul>
      <li parentName="ul">
        <a href="https://public.parsertongue.org/courses/snlp-1/slides/4-1-words-as-vectors-slides.pdf" target="_blank">Words as vectors (slides)</a>
      </li>
      <li parentName="ul">
        <mark><a href="https://public.parsertongue.org/courses/snlp-1/slides/4-2a-word2vec-slides.pdf" target="_blank">word2vec (slides)</a></mark>
      </li>
      <li parentName="ul">
        <a href="https://public.parsertongue.org/courses/snlp-1/slides/4-2b-glove-slides.pdf" target="_blank">GloVe (slides)</a>
      </li>
      <li parentName="ul">
        <a href="https://public.parsertongue.org/courses/snlp-1/slides/4-2c-evaluating-embeddings-slides.pdf" target="_blank">Evaluating embeddings (slides)</a>
      </li>
    </ul>
    {
      /* - <span style="color:grey;">word2vec (coming 4/12/2021)</span> */
    }
    <h2 {...{
      "id": "readings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#readings",
        "aria-label": "readings permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Readings`}</h2>
    <p>{`The excerpts linked below provide a nice overview of the tasks we preview in this unit:`}</p>
    <HTMLTable condensed striped mdxType="HTMLTable">
  <tbody>
    <tr>
        <td>
          <p>Word and Phrase Representations</p>
        </td>
        <td>
          <ul>
            <li>words as vectors</li>
            <li>TF-IDF</li>
            <li>PPMI</li>
            <li>lexical semantics and vector semantics</li>
            <li>word2vec (Skip-gram and CBOW)</li>
            <li>GloVe</li>
            <li>evaluating embeddings</li>
          </ul>
        </td>
        <td>
          <p><a href="https://public.parsertongue.org/readings/slp3/6.pdf">Speech and Language Processing Ch 6</a></p>
          <p><mark><a href="https://arxiv.org/pdf/1301.3781.pdf">Efficient Estimation of Word Representations in Vector Space</a></mark></p>
          <p><mark><a href="https://papers.nips.cc/paper/2013/file/9aa42b31882ec039965f3c4923ce901b-Paper.pdf">Distributed Representations of Words and Phrases and their Compositionality</a></mark></p>
          <p><a href="https://www.aclweb.org/anthology/D14-1162.pdf">Glove: Global Vectors for Word Representation</a></p>
        </td>
    </tr>
  </tbody>
    </HTMLTable>
    {
      /* <p><a href="https://doi.org/10.3115/v1/D14-1162">Glove: Global Vectors for Word Representation</a></p> */
    }
    {
      /* <li>introduction to contextualized embeddings</li> */
    }
    {
      /* <p><a href="https://jalammar.github.io/illustrated-bert/">The Illustrated BERT, ELMo, and co.</a></p> */
    }
    <h1 {...{
      "id": "learn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#learn",
        "aria-label": "learn permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Learn`}</h1>
    <div {...{
      "className": "admonition admonition-note alert alert--secondary"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M6.3 5.69a.942.942 0 0 1-.28-.7c0-.28.09-.52.28-.7.19-.18.42-.28.7-.28.28 0 .52.09.7.28.18.19.28.42.28.7 0 .28-.09.52-.28.7a1 1 0 0 1-.7.3c-.28 0-.52-.11-.7-.3zM8 7.99c-.02-.25-.11-.48-.31-.69-.2-.19-.42-.3-.69-.31H6c-.27.02-.48.13-.69.31-.2.2-.3.44-.31.69h1v3c.02.27.11.5.31.69.2.2.42.31.69.31h1c.27 0 .48-.11.69-.31.2-.19.3-.42.31-.69H8V7.98v.01zM7 2.3c-3.14 0-5.7 2.54-5.7 5.68 0 3.14 2.56 5.7 5.7 5.7s5.7-2.55 5.7-5.7c0-3.15-2.56-5.69-5.7-5.69v.01zM7 .98c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.12-7-7 3.14-7 7-7z"
              }}></path></svg></span>{`NOTE `}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div"><em parentName="p">{`Complete these tutorials in the order listed.`}</em></p></div></div>
    <h2 {...{
      "id": "basic-vector-operations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#basic-vector-operations",
        "aria-label": "basic vector operations permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Basic vector operations`}</h2>
    <p>{`We need to be comfortable using `}<a parentName="p" {...{
        "href": "/tutorials/vector-basics"
      }}>{`basic vector operations`}</a>{` in order to compute `}<a parentName="p" {...{
        "href": "/tutorials/distance-and-similarity"
      }}>{`measures of distance and similarity`}</a>{`.`}</p>
    {
      /* ## Word embeddings
      We can perform analogical reasoning with word embeddings using simple arithmetic operations. [Here's a demo using spaCy to help you get started exploring a vector space](https://public.parsertongue.org/courses/snlp-1/demos/word-embeddings-demo.html).
      If you'd like to experiment in a notebook of your own, you can use the following docker command:
      ```bash
      docker run -it \
       --pull "always" \
       -p 7777:9999 \
       -v "${PWD}:/app/notebooks" \
       uazhlt/spacy-demo:latest
      ```
      Open your browser to localhost:7777 and create a new notebook. */
    }
    <h1 {...{
      "id": "practice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#practice",
        "aria-label": "practice permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Practice`}</h1>
    <p>{`Review what you've learned ...`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://arizona.openclass.ai/resource/assignment-604fa4d3a004715293afc501/",
            "target": "_self",
            "rel": "nofollow"
          }}>{`Words and documents as vectors`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://arizona.openclass.ai/resource/assignment-606178dcf88ee68b25f56dff/",
            "target": "_self",
            "rel": "nofollow"
          }}>{`Count-based methods for representing words and documents`}</a></p>
      </li>
      <li parentName="ul">
        <mark><a href="https://arizona.openclass.ai/resource/assignment-6062acabe0fcc3afea5da7dc/">Word embeddings</a></mark>
      </li>
    </ul>
    {
      /* - <span style="color:grey;">Word embeddings (coming 4/12/2021)</span> */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      