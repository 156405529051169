import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "what-math-related-skills-would-be-useful-coming-into-the-program",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-math-related-skills-would-be-useful-coming-into-the-program",
        "aria-label": "what math related skills would be useful coming into the program permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What math-related skills would be useful coming into the program?`}</h3>
    <p>{`First, I would say not to worry.  Many students enter the program with little to no background in programming or advanced math.  We're dealing with an onion here. The HLT program will guide you through what you need to understand things at a high level and then grow progressively more detailed as you continue. I've listed a bunch of resources in the section below that will allow you to delve as far as you want.  As far as getting started, I recommend working through `}<a parentName="p" {...{
        "href": "/hlt/resources"
      }}>{`the textbook I've listed under the `}<strong parentName="a">{`Getting started`}</strong>{` section of this page`}</a>{`.`}</p>
    <p>{`It's important that you find projects that interest you to help you develop and refine your programming skills.`}</p>
    <h3 {...{
      "id": "im-applying-to-the-amp-should-i-complete-my-honors-thesis-or-dedicate-more-time-to-hlt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#im-applying-to-the-amp-should-i-complete-my-honors-thesis-or-dedicate-more-time-to-hlt",
        "aria-label": "im applying to the amp should i complete my honors thesis or dedicate more time to hlt permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`I'm applying to the AMP. Should I complete my Honors thesis or dedicate more time to HLT?`}</h3>
    <p>{`I can't answer that for you, but my personal opinion is there is great and lasting value in rounding out your education while an undergraduate. In my mind, undergrad days are for breadth. Grad school is for depth. As a prospective AMP student, it can be tricky to balance both of these things. Enjoy the opportunity to explore broadly while an undergrad. `}</p>
    <h3 {...{
      "id": "how-can-i-get-involved-in-labsinternships-within-hlt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-can-i-get-involved-in-labsinternships-within-hlt",
        "aria-label": "how can i get involved in labsinternships within hlt permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How can I get involved in labs/internships within HLT?`}</h3>
    <p>{`As far as joining labs, I would suggest that you first take a look at faculty and lab profiles and then send out a cold email:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://nlp.arizona.edu/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`http://nlp.arizona.edu/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://linguistics.arizona.edu/faculty-0",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://linguistics.arizona.edu/faculty-0`}</a></li>
    </ul>
    <p>{`Most faculty will be very welcoming.`}</p>
    <h3 {...{
      "id": "what-is-the-best-way-to-gain-hands-on-experience-early-on-in-hlt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-is-the-best-way-to-gain-hands-on-experience-early-on-in-hlt",
        "aria-label": "what is the best way to gain hands on experience early on in hlt permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is the best way to gain hands-on experience early on in HLT?`}</h3>
    <p>{`Join a research lab or take on an independent study with one of the faculty as early as possible.  You can either propose your own topic or take on one that the supervising faculty proposes.  Often that can lead to a paid position in grand-funded work that will allow you to develop the skills that will make you competitive for internships.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      