import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import { HTMLTable } from '@blueprintjs/core';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`In this unit, we'll learn about binomial and multinomial logistic regression classifers. `}</p>
    <p>{`After reviewing, you'll apply this information to complete and submit a programming assignment where you'll write a `}<strong parentName="p">{`binomial logistic regression`}</strong>{` classifier using  bag-of-`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span></span></span></span></span>{`-gram features (you may include other features!).`}</p>
    <h1 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h1>
    <ul>
      <li parentName="ul">{`calculate the decision function for binomial logistic regression`}</li>
      <li parentName="ul">{`calculate the cross entropy cost for binomial logistic regression`}</li>
      <li parentName="ul">{`calculate the softmax for multinomial logistic regression`}</li>
      <li parentName="ul">{`summarize the process of gradient descent`}</li>
      <li parentName="ul">{`explain the role of the learning rate in gradient descent`}</li>
      <li parentName="ul">{`explain the difference between batch, minibatch, and stochastic gradient descent`}</li>
      <li parentName="ul">{`write a classifier using binomial logistic regression with bag-of-n-gram features`}</li>
    </ul>
    <h1 {...{
      "id": "resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#resources",
        "aria-label": "resources permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Resources`}</h1>
    <h2 {...{
      "id": "videos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#videos",
        "aria-label": "videos permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Videos`}</h2>
    <p>{`Be sure to watch the videos in the playlist linked below:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/playlist?list=PL2acInhHkjsbAGNlbG6BOOAhwXamu3lvw",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Unit playlist`}</a></li>
    </ul>
    <h2 {...{
      "id": "slides",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#slides",
        "aria-label": "slides permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Slides`}</h2>
    <p>{`These slides correspond to the videos for the unit:`}</p>
    <ul>
      <li parentName="ul">
        <a href="https://public.parsertongue.org/courses/snlp-1/slides/3-1-logistic-regression-slides.pdf" target="_blank">Logistic regression (slides)</a>
      </li>
    </ul>
    <h2 {...{
      "id": "readings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#readings",
        "aria-label": "readings permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Readings`}</h2>
    <p>{`The excerpts linked below provide a nice overview of the topics covered in this unit:`}</p>
    <HTMLTable condensed striped mdxType="HTMLTable">
  <tbody>
    <tr>
        <td>
          <p>Logistic Regression</p>
        </td>
        <td>
          <ul>
            <li>logistic regression (overview)</li>
            <li>cross entropy</li>
            <li>MLE</li>
            <li>gradient descent</li>
            <li>regularization</li>
            {
                /* <li><del>regularization</del></li> */
              }
            <li>multinomial LR</li>
          </ul>
        </td>
        <td>
          <p><a href="https://public.parsertongue.org/readings/slp3/5.pdf">Speech and Language Processing 5.0 - 5.7</a></p>
          <p><a href="https://public.parsertongue.org/readings/slp3/5.pdf#page=17">OPTIONAL: Speech and Language Processing 5.8</a></p>
        </td>
    </tr>
  </tbody>
    </HTMLTable>
    <h1 {...{
      "id": "learn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#learn",
        "aria-label": "learn permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Learn`}</h1>
    <div {...{
      "className": "admonition admonition-note alert alert--secondary"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M6.3 5.69a.942.942 0 0 1-.28-.7c0-.28.09-.52.28-.7.19-.18.42-.28.7-.28.28 0 .52.09.7.28.18.19.28.42.28.7 0 .28-.09.52-.28.7a1 1 0 0 1-.7.3c-.28 0-.52-.11-.7-.3zM8 7.99c-.02-.25-.11-.48-.31-.69-.2-.19-.42-.3-.69-.31H6c-.27.02-.48.13-.69.31-.2.2-.3.44-.31.69h1v3c.02.27.11.5.31.69.2.2.42.31.69.31h1c.27 0 .48-.11.69-.31.2-.19.3-.42.31-.69H8V7.98v.01zM7 2.3c-3.14 0-5.7 2.54-5.7 5.68 0 3.14 2.56 5.7 5.7 5.7s5.7-2.55 5.7-5.7c0-3.15-2.56-5.69-5.7-5.69v.01zM7 .98c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.12-7-7 3.14-7 7-7z"
              }}></path></svg></span>{`NOTE `}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div"><em parentName="p">{`Complete these tutorials in the order listed.`}</em></p></div></div>
    <h2 {...{
      "id": "basic-vector-operations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#basic-vector-operations",
        "aria-label": "basic vector operations permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Basic vector operations`}</h2>
    <p>{`Calculating the logistic regression decision function and cross-entropy loss involves `}<a parentName="p" {...{
        "href": "/tutorials/vector-basics#operations"
      }}>{`basic vector operations`}</a>{` and use of the `}<a parentName="p" {...{
        "href": "/tutorials/distance-and-similarity#dot-product"
      }}>{`dot product`}</a>{`.`}</p>
    <h2 {...{
      "id": "binomial-logistic-regression",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#binomial-logistic-regression",
        "aria-label": "binomial logistic regression permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Binomial logistic regression`}</h2>
    <p>{`The decision function for binomial logistic regression is defined as ...`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`σ`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`z`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mn parentName="mfrac">{`1`}</mn><mrow parentName="mfrac"><mn parentName="mrow">{`1`}</mn><mo parentName="mrow">{`+`}</mo><msup parentName="mrow"><mi parentName="msup">{`e`}</mi><mrow parentName="msup"><mo parentName="mrow">{`−`}</mo><mi parentName="mrow">{`z`}</mi></mrow></msup></mrow></mfrac><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mn parentName="mfrac">{`1`}</mn><mrow parentName="mfrac"><mn parentName="mrow">{`1`}</mn><mo parentName="mrow">{`+`}</mo><msup parentName="mrow"><mi parentName="msup">{`e`}</mi><mrow parentName="msup"><mo parentName="mrow">{`−`}</mo><mo parentName="mrow" {...{
                            "stretchy": "false"
                          }}>{`(`}</mo><mi parentName="mrow">{`θ`}</mi><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`+`}</mo><mi parentName="mrow">{`b`}</mi><mo parentName="mrow" {...{
                            "stretchy": "false"
                          }}>{`)`}</mo></mrow></msup></mrow></mfrac></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\sigma(z) = \\frac{1}{1+e^{-z}} = \\frac{1}{1+e^{-(\\theta x + b)}}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`σ`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.04398em"
                }
              }}>{`z`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.0908em",
                  "verticalAlign": "-0.7693em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.3214em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`1`}</span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.2222em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mbin"
                            }}>{`+`}</span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.2222em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`e`}</span><span parentName="span" {...{
                                "className": "msupsub"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.6973em"
                                      }
                                    }}><span parentName="span" {...{
                                        "style": {
                                          "top": "-2.989em",
                                          "marginRight": "0.05em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "2.7em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "sizing reset-size6 size3 mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}>{`−`}</span><span parentName="span" {...{
                                              "className": "mord mathnormal mtight",
                                              "style": {
                                                "marginRight": "0.04398em"
                                              }
                                            }}>{`z`}</span></span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`1`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.7693em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.1088em",
                  "verticalAlign": "-0.7873em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.3214em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.296em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`1`}</span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.2222em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mbin"
                            }}>{`+`}</span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.2222em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`e`}</span><span parentName="span" {...{
                                "className": "msupsub"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.814em"
                                      }
                                    }}><span parentName="span" {...{
                                        "style": {
                                          "top": "-2.989em",
                                          "marginRight": "0.05em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "2.7em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "sizing reset-size6 size3 mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}>{`−`}</span><span parentName="span" {...{
                                              "className": "mopen mtight"
                                            }}>{`(`}</span><span parentName="span" {...{
                                              "className": "mord mathnormal mtight",
                                              "style": {
                                                "marginRight": "0.02778em"
                                              }
                                            }}>{`θ`}</span><span parentName="span" {...{
                                              "className": "mord mathnormal mtight"
                                            }}>{`x`}</span><span parentName="span" {...{
                                              "className": "mbin mtight"
                                            }}>{`+`}</span><span parentName="span" {...{
                                              "className": "mord mathnormal mtight"
                                            }}>{`b`}</span><span parentName="span" {...{
                                              "className": "mclose mtight"
                                            }}>{`)`}</span></span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`1`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.7873em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span></span></span></span></div>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mtext parentName="mrow">{`where`}</mtext><mtext parentName="mrow">{`  `}</mtext><mi parentName="mrow">{`z`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`θ`}</mi><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`+`}</mo><mi parentName="mrow">{`b`}</mi><mtext parentName="mrow">{`  `}</mtext><mtext parentName="mrow">{`and`}</mtext><mtext parentName="mrow">{`  `}</mtext><mi parentName="mrow">{`θ`}</mi><mtext parentName="mrow">{`  `}</mtext><mtext parentName="mrow">{`and`}</mtext><mtext parentName="mrow">{`  `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{`  `}</mtext><mtext parentName="mrow">{`are both vectors`}</mtext></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\text{where}  \\; z = \\theta x + b \\; \\text{and} \\; \\theta \\; \\text{and} \\; x \\; \\text{are both vectors}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`where`}</span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.04398em"
                }
              }}>{`z`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.7778em",
                  "verticalAlign": "-0.0833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.02778em"
                }
              }}>{`θ`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`+`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`and`}</span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.02778em"
                }
              }}>{`θ`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`and`}</span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`are both vectors`}</span></span></span></span></span></span></div>
    <p>{`How is the output of this function affected by changes to `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`θ`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\theta`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.02778em"
                }
              }}>{`θ`}</span></span></span></span></span>{` and `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{`?`}</p>
    <h3 {...{
      "id": "parameters",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#parameters",
        "aria-label": "parameters permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Parameters`}</h3>
    <p>{`In logistic regression, the parameters `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`θ`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\theta`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.02778em"
                }
              }}>{`θ`}</span></span></span></span></span>{` and `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{` are learned by minimizing the `}<strong parentName="p">{`cross entropy`}</strong>{` error via gradient descent.`}</p>
    <p>{`How is the output of the sigmoid function affected by changes to `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`θ`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\theta`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.02778em"
                }
              }}>{`θ`}</span></span></span></span></span>{` and `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{`?`}</p>
    <h4 {...{
      "id": "weights",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#weights",
        "aria-label": "weights permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Weights`}</h4>
    <p>{`By altering the weights, we can change how quickly input values move toward 0 or 1.  As weights approach zero, the output of the sigmoid function flattens.  As positive weights grow larger, nearly all input values result in a value very close to 0 (for negative input values) or 1 (for positive input values).`}</p>
    <p><img parentName="p" {...{
        "src": "/2d0c1772600e4ebf1e13901b440c2c61/sigmoid-lr-weight-anim.gif",
        "alt": "\"Changing the weights.\"",
        "title": "Effect on shape of sigmoid when altering only the weights."
      }}></img></p>
    <h4 {...{
      "id": "bias-term",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#bias-term",
        "aria-label": "bias term permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bias term`}</h4>
    <p>{`By altering the bias term `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{`, one can lower or increase the threshold needed to output a value close to 1 or 0.`}</p>
    <p><img parentName="p" {...{
        "src": "/78d8ac63eda6a6574cbc7b038881fa34/sigmoid-lr-bias-anim.gif",
        "alt": "\"Changing the bias term.\"",
        "title": "Effect on shape of sigmoid when altering only the bias term."
      }}></img></p>
    <h2 {...{
      "id": "using-scikit-learn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-scikit-learn",
        "aria-label": "using scikit learn permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using scikit-learn`}</h2>
    <p>{`Rather than coding everything from scratch, we'll be using `}<a parentName="p" {...{
        "href": "https://scikit-learn.org/",
        "target": "_self",
        "rel": "nofollow"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`scikit-learn`}</code></a>{`'s implementation of binomial logistic regression to complete the programming assignment for this unit. `}<a parentName="p" {...{
        "href": "/tutorials/sklearn-intro"
      }}>{`Follow this tutorial to ensure you understand the basics of this library`}</a>{`.`}</p>
    <h1 {...{
      "id": "practice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#practice",
        "aria-label": "practice permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Practice`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://arizona.openclass.ai",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Review what you've learned`}</a></li>
    </ul>
    <p>{`Before you begin the programming assignment for this unit, ensure you've ...`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/courses/snlp-1/configuring-your-development-environment"
        }}>{`configured your development environment`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/sklearn-intro"
        }}>{`reviewed the basics of scikit-learn`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      