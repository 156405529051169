import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`In this unit, you will configure a development environment which you will use to learn the basics of the Linux command line, version control with Git, and containerization using Docker. `}</p>
    <p>{`After reviewing, you'll apply this information to complete and submit your first programming assignment which serves as a practical introduction to `}<a parentName="p" {...{
        "href": "https://mybinder.org/v2/gh/ipython/ipython-in-depth/master?filepath=binder/Index.ipynb",
        "target": "_self",
        "rel": "nofollow"
      }}>{`jupyter notebooks`}</a>{`.`}</p>
    <h1 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h1>
    <ul>
      <li parentName="ul">{`create a working UNIX/Linux-based development environment`}</li>
      <li parentName="ul">{`demonstrate competancy with the basics of the Linux command line (navigation, creating directories, etc.)`}</li>
      <li parentName="ul">{`demonstrate competency with Docker containers as a means of managing dependencies `}</li>
      <li parentName="ul">{`demonstrate how to use Git with GitHub to download (clone), complete (commit), and submit (push) programming assignments`}</li>
    </ul>
    <h1 {...{
      "id": "learn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#learn",
        "aria-label": "learn permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Learn`}</h1>
    <div {...{
      "className": "admonition admonition-note alert alert--secondary"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M6.3 5.69a.942.942 0 0 1-.28-.7c0-.28.09-.52.28-.7.19-.18.42-.28.7-.28.28 0 .52.09.7.28.18.19.28.42.28.7 0 .28-.09.52-.28.7a1 1 0 0 1-.7.3c-.28 0-.52-.11-.7-.3zM8 7.99c-.02-.25-.11-.48-.31-.69-.2-.19-.42-.3-.69-.31H6c-.27.02-.48.13-.69.31-.2.2-.3.44-.31.69h1v3c.02.27.11.5.31.69.2.2.42.31.69.31h1c.27 0 .48-.11.69-.31.2-.19.3-.42.31-.69H8V7.98v.01zM7 2.3c-3.14 0-5.7 2.54-5.7 5.68 0 3.14 2.56 5.7 5.7 5.7s5.7-2.55 5.7-5.7c0-3.15-2.56-5.69-5.7-5.69v.01zM7 .98c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.12-7-7 3.14-7 7-7z"
              }}></path></svg></span>{`NOTE `}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div"><em parentName="p">{`Complete these tutorials in the order listed.`}</em></p></div></div>
    <h2 {...{
      "id": "configuring-your-development-environment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configuring-your-development-environment",
        "aria-label": "configuring your development environment permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring your development environment`}</h2>
    <p>{`The examples and assignments used in this course are all designed to run on a UNIX-like operating system (ex. Linux, Darwin/MacOS).  One free(!) option is to use a `}<a parentName="p" {...{
        "href": "https://simple.wikipedia.org/wiki/Linux",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Linux-based operating system`}</a>{`.  In order to ensure you can follow along, you'll need to set up a compatible development environment.  We'll look at one way of doing so using a virtual machine.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/virtualbox-install-ubuntu"
        }}>{`Installing Ubuntu 20.04 LTS using VirtualBox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/ubuntu-install-vscode"
        }}>{`Installing VS Code (Optional)`}</a></li>
    </ul>
    <h3 {...{
      "id": "important-note",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#important-note",
        "aria-label": "important note permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Important note`}</h3>
    <div {...{
      "className": "admonition admonition-note alert alert--secondary"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M6.3 5.69a.942.942 0 0 1-.28-.7c0-.28.09-.52.28-.7.19-.18.42-.28.7-.28.28 0 .52.09.7.28.18.19.28.42.28.7 0 .28-.09.52-.28.7a1 1 0 0 1-.7.3c-.28 0-.52-.11-.7-.3zM8 7.99c-.02-.25-.11-.48-.31-.69-.2-.19-.42-.3-.69-.31H6c-.27.02-.48.13-.69.31-.2.2-.3.44-.31.69h1v3c.02.27.11.5.31.69.2.2.42.31.69.31h1c.27 0 .48-.11.69-.31.2-.19.3-.42.31-.69H8V7.98v.01zM7 2.3c-3.14 0-5.7 2.54-5.7 5.68 0 3.14 2.56 5.7 5.7 5.7s5.7-2.55 5.7-5.7c0-3.15-2.56-5.69-5.7-5.69v.01zM7 .98c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.12-7-7 3.14-7 7-7z"
              }}></path></svg></span>{`A note to ARM64 users `}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`If you're using a machine with an ARM64 CPU (ex. a Mac M1), you won't be able to run VirtualBox. `}<strong parentName="p">{`If you're on a Mac, you can install Docker Desktop directly and execute the provided commands in the terminal.`}</strong>{`  `}<a parentName="p" {...{
            "href": "https://support.apple.com/en-us/HT211814",
            "target": "_self",
            "rel": "nofollow"
          }}>{`If you're unsure if your Mac has an ARM64 CPU, see this article from Apple`}</a>{`.  Note the CLI commands used throughout the course tutorials will not work as-is in Windows PowerShell. `}<strong parentName="p">{`Windows users should install Ubuntu`}</strong>{` (either via VirtualBox or natively `}<a parentName="p" {...{
            "href": "https://ubuntu.com/tutorials/install-ubuntu-desktop#6-type-of-installation",
            "target": "_self",
            "rel": "nofollow"
          }}>{`on a separate partition`}</a>{`). `}</p></div></div>
    <h2 {...{
      "id": "linux-command-line-basics",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#linux-command-line-basics",
        "aria-label": "linux command line basics permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Linux command line basics`}</h2>
    <p>{`Nearly all of the tools we'll use in this course must be run from the command line.  While intitially intimidating for some, knowing your way around a terminal will save you a great deal of time in the long run.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/linux-command-line-basics"
        }}>{`Commonly used shell commands`}</a>
        <ul parentName="li">
          <li parentName="ul">{`navigation`}</li>
          <li parentName="ul">{`manipulating files and folders`}</li>
          <li parentName="ul">{`...`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "version-control",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#version-control",
        "aria-label": "version control permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Version control`}</h2>
    <p>{`Version control is an essential tool for software developers.  It makes it easy to safely develop and test features, track progress, and revert to a working state if breaking changes are inadvertently introduced to some code base.  You'll be developing and submitting all programming assignments in this course using Git and GitHub.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/git-basics"
        }}>{`Introduction to Git`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/github-intro"
        }}>{`Introduction to GitHub`}</a></li>
    </ul>
    <h2 {...{
      "id": "containerization-and-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#containerization-and-docker",
        "aria-label": "containerization and docker permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Containerization and Docker`}</h2>
    <p>{`Containerization is a reliable way to manage dependencies as well as create and share uniform development environments.  All of your programming assignments in this course will leverage docker for developing and testing your solutions.  After you make it over this initial hurdle, you shouldn't have any trouble running future assignments.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/containers-intro"
        }}>{`Introduction to containers`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Containers can be used as portable development environments.  This is the how we'll be using them in this course.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/ubuntu-install-docker"
        }}>{`Installing Docker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/docker-basics"
        }}>{`Introduction to Docker`}</a></li>
    </ul>
    <h2 {...{
      "id": "unfamiliar-with-python",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#unfamiliar-with-python",
        "aria-label": "unfamiliar with python permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Unfamiliar with Python?`}</h2>
    <p>{`This course assumes you have some experience programming.  We'll be using Python 3 for all programming assignments.  If you're unfamiliar with Python 3, you may want to review the following resources:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.python.org/3/tutorial",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://docs.python.org/3/tutorial`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ehmatthes.github.io/pcc/cheatsheets/README.html",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://ehmatthes.github.io/pcc/cheatsheets/README.html`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.python.org/3/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://docs.python.org/3/`}</a></li>
    </ul>
    <p><img parentName="p" {...{
        "src": "https://imgs.xkcd.com/comics/python.png",
        "alt": "XKCD 353"
      }}></img><sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup></p>
    <h1 {...{
      "id": "practice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#practice",
        "aria-label": "practice permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Practice`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://arizona.openclass.ai/resource/assignment-5f2d0560f9163c2754bcaf62",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Review what you've learned`}</a></li>
    </ul>
    <p>{`Before you begin your first programming assignment, ensure you've...`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/virtualbox-install-ubuntu"
        }}>{`prepared a development environment`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/linux-command-line-basics"
        }}>{`become comfortable using the `}<code parentName="a" {...{
            "className": "language-text"
          }}>{`cd`}</code>{` command`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/ubuntu-install-docker"
        }}>{`installed `}<code parentName="a" {...{
            "className": "language-text"
          }}>{`docker`}</code></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/docker-basics"
        }}>{`familiarized yourself with basic `}<code parentName="a" {...{
            "className": "language-text"
          }}>{`docker`}</code>{` commands`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/git-basics"
        }}>{`installed and configured `}<code parentName="a" {...{
            "className": "language-text"
          }}>{`git`}</code>{` to use your preferred code editor`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/git-basics"
        }}>{`become familiar with `}<code parentName="a" {...{
            "className": "language-text"
          }}>{`git clone <rep-url>`}</code>{`, `}<code parentName="a" {...{
            "className": "language-text"
          }}>{`git add <filename>`}</code>{`, `}<code parentName="a" {...{
            "className": "language-text"
          }}>{`git commit`}</code>{`, and `}<code parentName="a" {...{
            "className": "language-text"
          }}>{`git push`}</code></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/github-intro"
        }}>{`created an account on GitHub`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/ubuntu-install-vscode"
        }}>{`installed VS Code`}</a>{` (or your favorite code editor)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://mybinder.org/v2/gh/ipython/ipython-in-depth/master?filepath=binder/Index.ipynb",
          "target": "_self",
          "rel": "nofollow"
        }}>{`familiarized yourself with Jupyter notebooks`}</a></li>
    </ul>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}><a parentName="li" {...{
            "href": "https://xkcd.com/353",
            "target": "_self",
            "rel": "nofollow"
          }}>{`https://xkcd.com/353`}</a><a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      