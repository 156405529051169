import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`This tutorial introduces you to using GitHub.`}</p>
    <h2 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h2>
    <p>{`After reading this tutorial, you should be comfortable...`}</p>
    <ul>
      <li parentName="ul">{`cloning repositories`}</li>
      <li parentName="ul">{`committing changes`}</li>
      <li parentName="ul">{`pushing changes to GitHub`}</li>
      <li parentName="ul">{`using `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`git push`}</code></li>
    </ul>
    <h2 {...{
      "id": "prerequisites",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#prerequisites",
        "aria-label": "prerequisites permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prerequisites`}</h2>
    <p>{`This tutorial assumes that ...`}</p>
    <ul>
      <li parentName="ul">{`You have a working `}<a parentName="li" {...{
          "href": "/tutorials/virtualbox-install-ubuntu"
        }}>{`Ubuntu 20.04 LTS installation`}</a></li>
      <li parentName="ul">{`You are `}<a parentName="li" {...{
          "href": "/tutorials/linux-command-line-basics"
        }}>{`comfortable with the basics of the Linux command line`}</a></li>
      <li parentName="ul">{`You have `}<a parentName="li" {...{
          "href": "/tutorials/git-basics"
        }}>{`installed and configured `}<code parentName="a" {...{
            "className": "language-text"
          }}>{`git`}</code>{` and are familiar with the basics`}</a></li>
    </ul>
    <h1 {...{
      "id": "background",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#background",
        "aria-label": "background permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background`}</h1>
    <h2 {...{
      "id": "what-is-github",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-is-github",
        "aria-label": "what is github permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h2">{`What is GitHub?`}</em></h2>
    <p>{`GitHub is a service for hosting and managing `}<strong parentName="p">{`remote`}</strong>{` Git repositories.`}</p>
    <h2 {...{
      "id": "remotes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#remotes",
        "aria-label": "remotes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Remotes`}</h2>
    <p>{`One strategy to prevent data loss is to have at least one copy of the data (preferably on anothe machine).  Git refers to each of these external copies of a repository as a `}<strong parentName="p">{`remote`}</strong>{`.  Each remotes includes the history of changes for the repository.`}</p>
    <p>{`While it's most common for a repository to have a single remote, it's certainly possible to have multiple (or even zero).  For example, you might have a remote called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`company-a`}</code>{` and another for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`customer-a`}</code>{`.  By convention, the `}<strong parentName="p">{`authorative`}</strong>{` remote is typically called `}<em parentName="p">{`origin`}</em>{`.  `}</p>
    <p>{`Each remote is associated with an address (URL) which tells Git where the remote can be reached to send and receive information about the repository.`}</p>
    <h3 {...{
      "id": "listing-remotes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#listing-remotes",
        "aria-label": "listing remotes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Listing remotes`}</h3>
    <p>{`You can list all existing remotes using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`git remote -v`}</code>{`.  If you haven't yet configured a remote, the list will be empty.`}</p>
    <h3 {...{
      "id": "removing-a-remote",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#removing-a-remote",
        "aria-label": "removing a remote permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Removing a remote`}</h3>
    <p>{`You can remove a remote using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`git remote remove <origin-name>`}</code>{`.  For example ... `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` remote remove origin`}</code></pre></div>
    <p>{`... will remove a remote named `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`origin`}</code>{`.`}</p>
    <h3 {...{
      "id": "adding-a-remote",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adding-a-remote",
        "aria-label": "adding a remote permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding a remote`}</h3>
    <p>{`You can add a remote using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`git remote add <origin-name> <origin-url>`}</code>{`.  For example ...`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` remote `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` origin https://address/for/origin.git`}</code></pre></div>
    <p>{`... will add a remote named `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`origin`}</code>{` that points to https://address/for/origin.git.`}</p>
    <h1 {...{
      "id": "using-github",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#using-github",
        "aria-label": "using github permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using GitHub`}</h1>
    <p>{`If you haven't already, `}<a parentName="p" {...{
        "href": "https://github.com/join",
        "target": "_self",
        "rel": "nofollow"
      }}>{`create a GitHub account`}</a>{`.`}</p>
    <h2 {...{
      "id": "caching-your-git-credentials-for-use-with-github",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#caching-your-git-credentials-for-use-with-github",
        "aria-label": "caching your git credentials for use with github permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Caching your Git credentials for use with GitHub`}</h2>
    <p>{`To avoid repeatedly entering your password, you may want to cache your credentials with GitHub.  One option is to `}<a parentName="p" {...{
        "href": "https://docs.github.com/en/authentication/connecting-to-github-with-ssh/adding-a-new-ssh-key-to-your-github-account",
        "target": "_self",
        "rel": "nofollow"
      }}>{`use an SSH key`}</a>{`.  Another option is to use the HTTPS protocol and `}<a parentName="p" {...{
        "href": "https://docs.github.com/en/get-started/getting-started-with-git/caching-your-github-credentials-in-git",
        "target": "_self",
        "rel": "nofollow"
      }}>{`cache your credentials using the GitHub CLI`}</a>{`.  We'll walk through this second option.`}</p>
    <h3 {...{
      "id": "1-install-homebrew",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#1-install-homebrew",
        "aria-label": "1 install homebrew permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Install `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`homebrew`}</code></h3>
    <p>{`Perhaps the easiest method for installing the GitHub CLI is to use `}<a parentName="p" {...{
        "href": "https://brew.sh/",
        "target": "_self",
        "rel": "nofollow"
      }}>{`the `}<code parentName="a" {...{
          "className": "language-text"
        }}>{`homebrew`}</code>{` package manager`}</a>{` (available on MacOS and Linux).  `}</p>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`Prerequisites for Ubuntu VM users`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div"><a parentName="p" {...{
            "href": "https://docs.brew.sh/Homebrew-on-Linux#requirements",
            "target": "_self",
            "rel": "nofollow"
          }}>{`Before installing `}<code parentName="a" {...{
              "className": "language-text"
            }}>{`homebrew`}</code>{` on Ubuntu`}</a>{`, you'll need to install a few dependencies. Run the following command in your terminal:`}</p><div parentName="div" {...{
          "className": "gatsby-highlight",
          "data-language": "text"
        }}><pre parentName="div" {...{
            "className": "language-text"
          }}><code parentName="pre" {...{
              "className": "language-text"
            }}>{`sudo apt-get install build-essential procps curl`}</code></pre></div></div></div>
    <p>{`To install `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`homebrew`}</code>{`, run the following command in your terminal:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`/bin/bash -c `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}><span parentName="span" {...{
                "className": "token variable"
              }}>{`$(`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`curl`}</span>{` -fsSL https://raw.githubusercontent.com/Homebrew/install/HEAD/install.sh`}<span parentName="span" {...{
                "className": "token variable"
              }}>{`)`}</span></span>{`"`}</span></code></pre></div>
    <p>{`Select the default options.  At the end of the installation, you may be prompted to enter two commands in your terminal.  Do so.`}</p>
    <h3 {...{
      "id": "2-install-the-github-cli",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2-install-the-github-cli",
        "aria-label": "2 install the github cli permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Install the GitHub CLI`}</h3>
    <p>{`Next, we'll install the GitHub CLI using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`homebrew`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`brew `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` gh`}</code></pre></div>
    <h3 {...{
      "id": "3-cache-your-credentials-using-the-github-cli",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#3-cache-your-credentials-using-the-github-cli",
        "aria-label": "3 cache your credentials using the github cli permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Cache your credentials using the GitHub CLI`}</h3>
    <p>{`Now that we've installed the GitHub CLI, we're ready to cache our credentials for using the HTTPS protocol to clone and push to GitHub.`}</p>
    <p>{`In your terminal, run the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`gh auth login -h `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"github.com"`}</span>{` -p `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https"`}</span></code></pre></div>
    <p>{`You'll be prompted to answer a series of questions:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`? You're already logged into github.com. Do you want to re-authenticate? Yes
? Authenticate Git with your GitHub credentials? Yes
? How would you like to authenticate GitHub CLI? Login with a web browser

! First copy your one-time code: ???-???
Press Enter to open github.com in your browser...`}</code></pre></div>
    <p>{`Take note of the one-time code displayed in the terminal and hit `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ENTER`}</code>{`. A browser window should launch.  Enter your one-time code.  After doing so, you may be prompted to authorize GitHub and to enter your GitHub password.  After completing these steps, you can close your browser tab and return to the terminal.`}</p>
    <h2 {...{
      "id": "cloning-a-remote-repository",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#cloning-a-remote-repository",
        "aria-label": "cloning a remote repository permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Cloning a remote repository`}</h2>
    <p>{`Let's start by creating an empty repository on GitHub.`}</p>
    <p>{`From your GitHub profile page, click the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`+`}</code>{` icon in the upper righthand corner and select the `}<strong parentName="p">{`New repository`}</strong>{` option from the dropdown menu:`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "590px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d02f76fa5d5f8e64464ecf3bdfe99f15/39148/github-new-repo-1.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.08108108108109%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAACI0lEQVQoz42Sv2/TQBTH/d8gVeIPYGJBMDGwM8DAigStSkrapjRKqqhUagcGJMTCH8GEkOjCTKGxSWO7qlI1jR07vthnn+18kK9Nq2w86Un3ftznnr73jM7eAa3OHvVGk62dXVrv99nYbrO53abR3GH1bYPl2jortQ3ty2/WeV3bZLVWZ62+wWq9wfq7Fs32HmtrWxi27eC4Lq7j8mX3gM+tDv3eCaZlYnaPOe526ZompmnRNS0sy8J1HPq2w0nfpt+3MS0L23FxT08xuLav7Q+8uLvE/uMHfKt1dK4s+C+LhCDLMn02cqX04eDhU5aX7vDx0X0+3XtCCUxEQp4XlGWpvShygjDCCwQqz5nNZje1uRtlUWrg0eFPVp4/Y/fVS359P9S5cSRJVVWf3VyOZUYgMpJUkec5eVHo2twMHcwgnAp6ts355aUuJDImmkrdPPcKWOXiJEWpa2BeLPQYc7rneQwGA6IooiwKklgSikRrU5RlNaMGBlFMnEjSLNNxZXPGAjBOEvxxgO+PyTJFKiUykahUQXn7AbPqo65U0K5UvgC+AQZhyGjkIYTQ01TQkfBxozOGscckFQwTj0Ey5EKOGEqPSSwI4ylZkd8C5y8HQYjn+3pSvTIl9MU5v6M+vekpw9inJ1z+THqYkc2R+MtZdEGovIUVMiqNKoHH40Dvk5Sp1i0SU4JQIBOlp61yuje7ilWWIRLJD/MS+yKmLJTW9R+3ECPDMi1wZwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Create a new GitHub repo",
              "title": "Create a new GitHub repo",
              "src": "/static/d02f76fa5d5f8e64464ecf3bdfe99f15/fcda8/github-new-repo-1.png",
              "srcSet": ["/static/d02f76fa5d5f8e64464ecf3bdfe99f15/12f09/github-new-repo-1.png 148w", "/static/d02f76fa5d5f8e64464ecf3bdfe99f15/e4a3f/github-new-repo-1.png 295w", "/static/d02f76fa5d5f8e64464ecf3bdfe99f15/fcda8/github-new-repo-1.png 590w", "/static/d02f76fa5d5f8e64464ecf3bdfe99f15/efc66/github-new-repo-1.png 885w", "/static/d02f76fa5d5f8e64464ecf3bdfe99f15/c83ae/github-new-repo-1.png 1180w", "/static/d02f76fa5d5f8e64464ecf3bdfe99f15/39148/github-new-repo-1.png 2876w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Create a new GitHub repo`}</figcaption>{`
  `}</figure></p>
    <p>{`Alternatively, you can just navigate to the following URL: `}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/new",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://github.com/new`}</a></li>
    </ul>
    <p>{`On the next screen, you'll be prompted to name your new repository.  For this example, I've elected to call it `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`git-demo`}</code>{`:`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "590px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/278eaa41aa95f5b19218e2c98f72bd01/b8765/github-new-repo-2.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.75675675675676%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABZElEQVQoz5WS207CQBRF+8uCUksloHhJ9MHEn/AH/BDD7QlCoa2EgtDL9DbT6TYzDdgWHvQkK2fazN7ZPT3Ky+sb7h6foXf7UPWu7J3ePTq3D+j1n3Ct99BotdFUdYk4X7Ru0LzSoGo6Wu0OGmobl1oXmtqFYloWlqYJy7ZhWbbs09kMg+EIn4MhRuMJ5sYC87nxi2FIzWJpYikwC0zThIIzxTmH6xEEEUPGc/ynFCEuIyqOE6ydDaI4BmUMlFLkeV6hrjtQSSguSsMogu/5oJRht9sjjuNCkPOKaVlzTHjOMCQEq68VXNeD5/sIAoKAEIRRhDAmiJKwYlg2PWuY0gw+CcFYBp7nyDgvyApYloHzfxgK0d515QyTJC16moIxVhHXP1ca1octE6YJNputZO04co6FGU5+Tp2za5NSCud7K1MKMz8gMvWf1uYwl8NsRBmujffJB8abqXwWq1O+d4SfvvsBydM+asRHsS0AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Name your GitHub repo",
              "title": "Name your new repo",
              "src": "/static/278eaa41aa95f5b19218e2c98f72bd01/fcda8/github-new-repo-2.png",
              "srcSet": ["/static/278eaa41aa95f5b19218e2c98f72bd01/12f09/github-new-repo-2.png 148w", "/static/278eaa41aa95f5b19218e2c98f72bd01/e4a3f/github-new-repo-2.png 295w", "/static/278eaa41aa95f5b19218e2c98f72bd01/fcda8/github-new-repo-2.png 590w", "/static/278eaa41aa95f5b19218e2c98f72bd01/efc66/github-new-repo-2.png 885w", "/static/278eaa41aa95f5b19218e2c98f72bd01/c83ae/github-new-repo-2.png 1180w", "/static/278eaa41aa95f5b19218e2c98f72bd01/b8765/github-new-repo-2.png 2868w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Name your new repo`}</figcaption>{`
  `}</figure></p>
    <p>{`Click the `}<strong parentName="p">{`Create repository`}</strong>{` button.`}</p>
    <p>{`On the next page, you'll be presented with instructions for cloning this repository or using it as a remote for an existing repository.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "590px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/1b324687b2f52980d6c78880886c5150/0b569/github-new-repo-3.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.08108108108109%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABzklEQVQoz4WSa2/TMBSG+5vHWLt17VCRhkDaB34V4tM0tLW5gWBd26VNk+bmxJfkQXE3QNAJS48sHduP3nPk3vurj1x+uGI4njA4f8PpaMJw/Jbx5B3jySUnZxe86p9zPBhxPDjnqD/kqD/idf+MwXDEydmY49MRg+GEQf+C3vWty6frGZ9vPG7uPDw/wPV8HNfH9QMc12PmuJap49ozPwhwvIAvt1OmM5e7qWPPHM+jR9vSti26hVoZlGmQ2iCVQeoGaVpaoHnif6vXybq1TnKc7wu+LhP8+Rr/IcKfhwT3K5ZRyiYVbEtDVGiiQrGrDFndEJeaQhq6YE3T/BaWtWKT5GzSijAuCJOSMM7Z5YJcSEvaSZ6odEutWyrVoMze0bl+CfO8wAu+cT9/4GG5YpdmbJMdhajtCLpxSKWpn6jkHlFLm+xfYSn4sVgxX4Zs4pRdIdjlJXFWkJUVRSXJK2n3P+lq5pBQa01elGR5QZplpFlOlu9R2thHBzENTXugZSEqFqtH5osly8c16ygm3GwJN5Fta9+2/gtDJdXhhFJKom1MHCfESUK03VKWAm2/kLKXmxdoDyWs69rKuqRCCMqytDWllB3H84OXeBb+BA9kNU5s/TuHAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Instructions for cloning and pushing to your new repo",
              "title": "Instructions for cloning and pushing to your new repo",
              "src": "/static/1b324687b2f52980d6c78880886c5150/fcda8/github-new-repo-3.png",
              "srcSet": ["/static/1b324687b2f52980d6c78880886c5150/12f09/github-new-repo-3.png 148w", "/static/1b324687b2f52980d6c78880886c5150/e4a3f/github-new-repo-3.png 295w", "/static/1b324687b2f52980d6c78880886c5150/fcda8/github-new-repo-3.png 590w", "/static/1b324687b2f52980d6c78880886c5150/efc66/github-new-repo-3.png 885w", "/static/1b324687b2f52980d6c78880886c5150/c83ae/github-new-repo-3.png 1180w", "/static/1b324687b2f52980d6c78880886c5150/0b569/github-new-repo-3.png 2878w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Instructions for cloning and pushing to your new repo`}</figcaption>{`
  `}</figure></p>
    <p>{`Let's assume you don't have a local repository already.  `}<a parentName="p" {...{
        "href": "/tutorials/linux-command-line-basics/"
      }}>{`Open your terminal, create the directory you wish to use for your repo (ex. `}<code parentName="a" {...{
          "className": "language-text"
        }}>{`~/repos/git-demo`}</code>{`), and navigate to the directory`}</a>{`.  Then enter the commands provided by GitHub:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"# git-demo"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>>`}</span>{` README.md
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` init
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` README.md
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` commit -m `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"first commit"`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` remote `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` origin https://github.com:myedibleenso/git-demo.git
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` push -u origin main`}</code></pre></div>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "exclamation mark"
        }}>{`❗`}</span>{` You'll want to change `}</undefined><code parentName="p" {...{
        "className": "language-text"
      }}>{`myedibleenso`}</code>{` in the snippet above to your GitHub username.`}</p>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "exclamation mark"
        }}>{`❗`}</span>{` If you run into an authentication error, ensure you've followed the steps in the `}</undefined><a parentName="p" {...{
        "href": "#caching-your-git-credentials-for-use-with-github"
      }}><strong parentName="a">{`Caching your Git credentials for use with GitHub`}</strong>{` section of the tutorial`}</a>{`.`}</p>
    <p>{`Next, refresh the page on GitHub.  Your pushed changes should have appeared on GitHub:`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "590px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/64e24cf089971b33d5c6f1702f7b7967/39148/github-new-repo-4.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "40.54054054054054%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABS0lEQVQoz42QTUvDQBCG89fVfqS1jRQRQRTBuyC0tsbU9KZ3r568aFGbJv3YzabZfSTbGipScODhHXZm3t1Z5/zyitOzC9ymR9VtUW14uK0O7c6JpdY84qDeouK2qbgt9uuH7NU83HqDtufRaHeoNj3c5jEN9xinexdy3Xuw9PyQYTjifhjiD0OrAz+g7welBsGIYDiy+U23T69/Z7kd+Az8exwArUHzvxCk5Jiddcfo3CaTr4jxZ8QknpMsU+RKIzJtdS4zPqKESbyg//LI0+szcZSwFAIpJUoptNYWx5j1bXEyZxLPkFlOWpgIhdjkBTLTCJWTpZApzWK5IM9zcq0pPAqbQktDIQTT6RSj18sbU6jZYh3aaFSmiOI5abba9JqS0lCmKW/vY4SQf5p+AfZlUTxDbRn+qLN98BM7zTZ9xZpRsrTfshkoa996b0v9L7JS3gAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Committed changes appear on GitHub after pushing",
              "title": "Committed changes appear on GitHub after pushing",
              "src": "/static/64e24cf089971b33d5c6f1702f7b7967/fcda8/github-new-repo-4.png",
              "srcSet": ["/static/64e24cf089971b33d5c6f1702f7b7967/12f09/github-new-repo-4.png 148w", "/static/64e24cf089971b33d5c6f1702f7b7967/e4a3f/github-new-repo-4.png 295w", "/static/64e24cf089971b33d5c6f1702f7b7967/fcda8/github-new-repo-4.png 590w", "/static/64e24cf089971b33d5c6f1702f7b7967/efc66/github-new-repo-4.png 885w", "/static/64e24cf089971b33d5c6f1702f7b7967/c83ae/github-new-repo-4.png 1180w", "/static/64e24cf089971b33d5c6f1702f7b7967/39148/github-new-repo-4.png 2876w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Committed changes appear on GitHub after pushing`}</figcaption>{`
  `}</figure></p>
    <p>{`You've successfully pushed to your remote!`}</p>
    <h1 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next steps`}</h1>
    <p><undefined parentName="p">{`You now know enough about Git and GitHub to be dangerous.  `}<span {...{
          "role": "img",
          "aria-label": "smiling face with horns"
        }}>{`😈`}</span></undefined></p>
    <p>{`Time to practice what you've learned!`}</p>
    <h2 {...{
      "id": "practice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#practice",
        "aria-label": "practice permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Practice`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/new?template_owner=skills&template_name=introduction-to-github&owner=%40me&name=uazhlt-intro-to-github&description=Intro+to+GitHub&visibility=public",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Complete this official tutorial`}</a>
        <ul parentName="li">
          <li parentName="ul">{`The tutorial uses a template repository. After creating the repository from the linked template, follow the steps outlined in your new repo's README.`}
            {
              /* - you can find additional (official) tutorials here: https://skills.github.com/ */
            }
          </li>
        </ul>
      </li>
    </ul>
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": 800,
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/YYDYDtzAB-Q?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    <h1 {...{
      "id": "additional-resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#additional-resources",
        "aria-label": "additional resources permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Additional resources`}</h1>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Download a `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`git`}</code>{` cheatsheet (quick reference) in your preferred language: `}<a parentName="p" {...{
            "href": "https://github.github.com/training-kit/",
            "target": "_self",
            "rel": "nofollow"
          }}>{`https://github.github.com/training-kit/`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In many of the examples used in these tutorials, you may have seen a file name with the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`.md`}</code>{` extension (ex. `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`README.md`}</code>{`).  This extension denotes a Markdown file.  Markdown provides a simple syntax for formatting documents.  It also supports inclusion of HTML.  For an optional introduction to Markdown, `}<a parentName="p" {...{
            "href": "https://github.com/skills/communicate-using-markdown",
            "target": "_self",
            "rel": "nofollow"
          }}>{`see this tutorial`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When working collaboratively on a Git-managed repository, you're likely to eventually run into a `}<strong parentName="p">{`merge conflict`}</strong>{`.  For some `}<a parentName="p" {...{
            "href": "https://github.com/skills/resolve-merge-conflicts",
            "target": "_self",
            "rel": "nofollow"
          }}>{`targeted practice in resolving such conflicts, see this tutorial`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`As you grow proficient in using GitHub, you may find youself wanting to create and manage remote GitHub repositories from the comforting embrace of the Linux command line.  GitHub developed a command line utility called `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`hub`}</code>{` to support CLI workflows.  `}<a parentName="p" {...{
            "href": "https://hub.github.com/",
            "target": "_self",
            "rel": "nofollow"
          }}>{`For usage and installation instructions, see the official documention`}</a>{`.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      