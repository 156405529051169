import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import { HTMLTable } from '@blueprintjs/core';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`In this unit, you'll learn about a few common statistical approaches to sequence tagging (a form of `}<strong parentName="p">{`structured prediction`}</strong>{`), including a method that uses logistic regression. You'll apply this information to implement a maximum entropy Markov model (MEMM) part of speech tagger.`}</p>
    <h1 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h1>
    <ul>
      <li parentName="ul">{`Define `}<strong parentName="li">{`sequence tagging`}</strong></li>
      <li parentName="ul">{`Explain how a `}<strong parentName="li">{`most frequent tag baseline`}</strong>{` works`}</li>
      <li parentName="ul">{`Define a `}<strong parentName="li">{`Markov chain`}</strong>{` in terms of `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`Q`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`Q`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.8778em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`Q`}</span></span></span></span></span>{`, `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`π`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`\\pi`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.4306em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`π`}</span></span></span></span></span>{`, and `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`A`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`A`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`A`}</span></span></span></span></span></li>
      <li parentName="ul">{`Explain the relationship between `}<strong parentName="li">{`MEMMs`}</strong>{` and logistic regression`}</li>
      <li parentName="ul">{`Compare and contrast `}<strong parentName="li">{`greedy`}</strong>{`, `}<strong parentName="li">{`Viterbi`}</strong>{`, and `}<strong parentName="li">{`beam`}</strong>{` decoding`}</li>
      <li parentName="ul">{`Implement a maximum-entropy model (MEMM) part of speech tagger for English`}</li>
    </ul>
    <h1 {...{
      "id": "resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#resources",
        "aria-label": "resources permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Resources`}</h1>
    <h2 {...{
      "id": "videos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#videos",
        "aria-label": "videos permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Videos`}</h2>
    <p>{`Be sure to watch the videos in the playlist linked below:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/playlist?list=PL2acInhHkjsZm_c-eZVO6iNmdHvwVzYpZ",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Unit playlist`}</a></li>
    </ul>
    <h2 {...{
      "id": "slides",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#slides",
        "aria-label": "slides permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Slides`}</h2>
    <p>{`These slides correspond to the videos for the unit:`}</p>
    <ul>
      <li parentName="ul">
        <a href="https://public.parsertongue.org/courses/snlp-1/slides/5-1-sequence-tagging-slides.pdf" target="_blank">sequence tagging (slides)</a>
      </li>
      <li parentName="ul">
        <a href="https://public.parsertongue.org/courses/snlp-1/slides/5-2-hmm-slides.pdf" target="_blank">HMMs (slides)</a>
      </li>
      <li parentName="ul">
        <a href="https://public.parsertongue.org/courses/snlp-1/slides/5-3-memm-slides.pdf" target="_blank">MEMMs (slides)</a>
      </li>
    </ul>
    <h2 {...{
      "id": "readings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#readings",
        "aria-label": "readings permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Readings`}</h2>
    <p>{`The excerpts linked below provide a nice overview of the tasks we preview in this unit:`}</p>
    <HTMLTable condensed striped mdxType="HTMLTable">
  <tbody>
    <tr>
        <td>
          <p>Sequence Tagging</p>
        </td>
        <td>
          <ul>
            <li>part-of-speech tagging</li>
            <li>HMMs</li>
            <li>MEMMs</li>
            <li>decoding</li>
          </ul>
        </td>
        <td>
          <p><a href="https://public.parsertongue.org/readings/slp3/8.pdf">Speech and Language Processing 8.0 - 8.7</a></p>
        </td>
    </tr>
  </tbody>
    </HTMLTable>
    {
      /* # Learn
      :::note NOTE 
      _Complete these tutorials in the order listed._
      ::: */
    }
    <h1 {...{
      "id": "practice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#practice",
        "aria-label": "practice permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Practice`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://arizona.openclass.ai/resource/assignment-6017d824ff512a21f731a2b7",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Review what you've learned`}</a></li>
    </ul>
    <p>{`Before you begin the programming assignment for this unit, ensure you've ...`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/courses/snlp-1/configuring-your-development-environment"
        }}>{`configured your development environment`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/sklearn-intro"
        }}>{`reviewed the basics of scikit-learn`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      