import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "welcome",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#welcome",
        "aria-label": "welcome permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Welcome`}</h1>
    <p>{`Welcome to the University of Arizona's online MS program in Human Language Technology. We're thrilled to have you join us!`}</p>
    <h1 {...{
      "id": "getting-started",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#getting-started",
        "aria-label": "getting started permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Getting started`}</h1>
    <p>{`Having trouble signing up for courses in your first semester? Contact `}<strong parentName="p">{`Josh Carlin`}</strong>{` (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`jcarlin117 AT arizona DOT edu`}</code>{`) and ask to be enrolled in LING 529 and LING 531.`}</p>
    <h2 {...{
      "id": "your-first-month-in-the-program",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#your-first-month-in-the-program",
        "aria-label": "your first month in the program permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Your first month in the program`}</h2>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Submit the `}<a parentName="li" {...{
          "href": "https://studentcenter.arizona.edu/app/ui/ps-pages/gradpath",
          "target": "_self",
          "rel": "nofollow"
        }}><strong parentName="a">{`Responsible Conduct of Research Statement (HLTMS) in GradPath`}</strong></a>{` (click `}<strong parentName="li">{`GradPath Forms`}</strong>{`)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Get to know other students in the program`}
        <ul parentName="li">
          <li parentName="ul">{`Consider forming a study group`}</li>
        </ul>
      </li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Schedule a meeting with your mentor`}</li>
    </ul>
    <h2 {...{
      "id": "your-first-semester-in-the-program",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#your-first-semester-in-the-program",
        "aria-label": "your first semester in the program permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Your first semester in the program`}</h2>
    <ul>
      <li parentName="ul">{`Complete `}<a parentName="li" {...{
          "href": "https://parsertongue.org/courses/hlt-1",
          "target": "_self",
          "rel": "nofollow"
        }}>{`LING 529`}</a>{` & LING 531`}</li>
    </ul>
    <h2 {...{
      "id": "your-second-semester-in-the-program",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#your-second-semester-in-the-program",
        "aria-label": "your second semester in the program permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Your second semester in the program`}</h2>
    <ul>
      <li parentName="ul">{`Complete LING 538 & LING 539`}</li>
      <li parentName="ul">{`Consider starting a portfolio project`}
        <ul parentName="li">
          <li parentName="ul">{`Solo or team?`}</li>
          <li parentName="ul">{`Maybe get involved in an academic research project?`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "frequently-asked-questions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#frequently-asked-questions",
        "aria-label": "frequently asked questions permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Frequently Asked Questions`}</h2>
    <h3 {...{
      "id": "how-long-does-it-take-to-complete-the-program",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-long-does-it-take-to-complete-the-program",
        "aria-label": "how long does it take to complete the program permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How long does it take to complete the program?`}</h3>
    <p>{`The average time to completion is about two years (5-6 terms). We offer courses during fall, spring, and summer terms.`}</p>
    <h3 {...{
      "id": "what-courses-can-i-take",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-courses-can-i-take",
        "aria-label": "what courses can i take permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What courses can I take?`}</h3>
    <p>{`Please `}<a parentName="p" {...{
        "href": "https://uazhlt.github.io/hlt-online/courses",
        "target": "_self",
        "rel": "nofollow"
      }}>{`see this page for a description of core courses and recommended electives`}</a>{`. Students in the online program may take electives in other departments with the approval of the program director. While the number of offerings continues to grow, `}<a parentName="p" {...{
        "href": "https://studentcenter.arizona.edu/app/ui/public/search",
        "target": "_self",
        "rel": "nofollow"
      }}>{`students in the online program are currently limited to fully online (i.e., asynchronous) offerings available through the `}<strong parentName="a">{`Arizona Online`}</strong>{` campus`}</a>{`.`}</p>
    <h3 {...{
      "id": "how-many-classes-should-i-take-per-term",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-many-classes-should-i-take-per-term",
        "aria-label": "how many classes should i take per term permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How many classes should I take per term?`}</h3>
    <p>{`We recommend that students enroll in 2 courses per 15-week term, but tackle the courses one at a time in a back-to-back fashion.  If you need to take the program slower and enroll in just one course per term, that's certainly possible, but keep in mind that not all of the program courses are offered each semester.`}</p>
    <h3 {...{
      "id": "how-do-i-find-an-internship",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-do-i-find-an-internship",
        "aria-label": "how do i find an internship permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I find an internship?`}</h3>
    <p>{`We don't place students in internships, but the HLT faculty are available to advise you as you evaluate prospects you identify yourself.`}</p>
    <p>{`The program forum is a place where faculty, alumni, and current students can discuss the program and share internship opportunities.  Many of our students have had success finding internships using the following sites:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://linkedin.com",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://linkedin.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wellfound.com",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://wellfound.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nlppeople.com",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://nlppeople.com`}</a></li>
    </ul>
    <p>{`Finding the right internship often takes time, and the form that the process takes can vary from country to country.`}</p>
    <p>{`You can find more information on the process in `}<a parentName="p" {...{
        "href": "https://linguistics.arizona.edu/graduate-overview",
        "target": "_self",
        "rel": "nofollow"
      }}>{`the Graduate Handbook`}</a>{`.`}</p>
    <h3 {...{
      "id": "i-want-to-build-up-my-skills-on-topic-x--what-should-i-do",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#i-want-to-build-up-my-skills-on-topic-x--what-should-i-do",
        "aria-label": "i want to build up my skills on topic x  what should i do permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`I want to build up my skills on topic X.  What should I do?`}</h3>
    <p><a parentName="p" {...{
        "href": "/hlt/resources"
      }}>{`I've collected some free resources you can use to help you grow your skills on different foundational topics in HLT`}</a>{`.  Whatever you're wanting to study, I consider supplementing your study by applying the concepts to a small portfolio project.`}</p>
    <h3 {...{
      "id": "where-all-the-gpus",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#where-all-the-gpus",
        "aria-label": "where all the gpus permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Where all the GPUs?`}</h3>
    <p>{`Take a look at the `}<a parentName="p" {...{
        "href": "https://hpc.arizona.edu",
        "target": "_self",
        "rel": "nofollow"
      }}>{`University of Arizona's High Performance Computing (HPC) clusters`}</a>{`.  We'll walk through how to use this resource during the program, but the UA HPC has very good documentation to help you get started as soon as you're ready. Any HLT student without an account should contact one of the HLT faculty to be sponsored.`}</p>
    <h2 {...{
      "id": "questions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#questions",
        "aria-label": "questions permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Questions?`}</h2>
    <ul>
      <li parentName="ul">{`Schedule a meeting with Gus Hahn-Powell: `}<a parentName="li" {...{
          "href": "https://parsertongue.org/availability/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://parsertongue.org/availability/`}</a></li>
      <li parentName="ul">{`Post to the program forum: `}<a parentName="li" {...{
          "href": "https://forum.hlt.arizona.edu",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://forum.hlt.arizona.edu`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      