import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`In this lesson, you'll be introduced to regular expressions (regexes) and practice using this pattern language to identify spans of text.`}</p>
    <h1 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h1>
    <p>{`After completing this lesson, you'll be able to ...`}</p>
    <ul>
      <li parentName="ul">{`describe patterns using regular expressions`}</li>
      <li parentName="ul">{`specify repetitions in regular expressions using quantifiers`}</li>
      <li parentName="ul">{`specify contextual constraints in regular expressions using lookaround assertions`}</li>
    </ul>
    <h1 {...{
      "id": "background",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#background",
        "aria-label": "background permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background`}</h1>
    <p>{`You've been tasked with identifying all mentions of occupations in a collection of documents.`}</p>
    <p>{`You've been given a few examples to get started:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`tokens `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"teacher"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"farmer"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"doctor"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"professor"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"lawyer"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`You notice all of these terms have the er/or suffix and recognize these as surface forms of the same `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Agent_noun",
        "target": "_self",
        "rel": "nofollow"
      }}>{`agent morpheme`}</a>{`.  You decide to write some custom code to recognize these: `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`agent_er`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`words`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`w `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` w `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` words `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` w`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`endswith`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"er"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`or`}</span>{` w`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`endswith`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"or"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` valid `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` agent_er`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`tokens`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`valid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p><undefined parentName="p">{`Problem solved!  Time to head to the beach! `}<span {...{
          "role": "img",
          "aria-label": "beach with umbrella"
        }}>{`🏖️`}</span>{` But before you can so much as reach for the sunscreen, your boss sends you some new test cases:`}</undefined></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`tokens `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"teacher"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"doctor"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"professor"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"lawyer"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"geologist"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"mathematician"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`Before adding another conditional to that function, you realize things could quickly get messy. `}</p>
    <p>{`It's clear that we want to match a few specific suffixes (`}<em parentName="p">{`er`}</em>{`, `}<em parentName="p">{`or`}</em>{`, `}<em parentName="p">{`ist`}</em>{`, `}<em parentName="p">{`cian`}</em>{`, etc.).`}</p>
    <h1 {...{
      "id": "regular-expressions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#regular-expressions",
        "aria-label": "regular expressions permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Regular expressions`}</h1>
    <p><strong parentName="p">{`Regular expressions`}</strong>{` are a way of formalizing such patterns.  Rooted in formal language theory as a means of describing `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Regular_language",
        "target": "_self",
        "rel": "nofollow"
      }}>{`regular languages`}</a>{`, regular expressions have been widely adopted by most text editors and programming languages as means of general text search.`}</p>
    <p>{`Most popular implementations (ex. the `}<a parentName="p" {...{
        "href": "https://docs.python.org/3/library/re.html",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Python `}<code parentName="a" {...{
          "className": "language-text"
        }}>{`re`}</code>{` module`}</a>{`, `}<a parentName="p" {...{
        "href": "https://perldoc.perl.org/perlre.html",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Perl`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.regular-expressions.info/grep.html",
        "target": "_self",
        "rel": "nofollow"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`grep`}</code></a>{`, etc.) include extensions such as backreferences and named captures.  These memory mechanisms `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Regular_expression#Patterns_for_non-regular_languages",
        "target": "_self",
        "rel": "nofollow"
      }}>{`greatly increase the expressive power of regular expressions`}</a>{`, enabling them to describe languages more complex than regular languages (beyond type-3).  To draw a distinction, we'll adopt the convention of using `}<strong parentName="p">{`regexes`}</strong>{` to refer to this extended form.  `}</p>
    <h2 {...{
      "id": "language",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#language",
        "aria-label": "language permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Language`}</h2>
    <p>{`You may be surprised to learn that you already know some regexes.  The simplest examples are those expressions which match literal strings:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "language-text"
          }}>{`a`}</code>{` is a regex that will match exactly one string: `}<em parentName="p">{`a`}</em>{`.   `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "language-text"
          }}>{`cat`}</code>{` is a regex that will match exactly one string: `}<em parentName="p">{`cat`}</em>{`.  `}</p>
      </li>
    </ul>
    <p>{`Regexes are far more expressive that that, though.  Here are some of the core features of the language: `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Language feature`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disjunction`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`a`}</code>{`|`}<code parentName="td" {...{
              "className": "language-text"
            }}>{`b`}</code></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`matches `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`a`}</code>{` OR `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`b`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`character class`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`[abc]`}</code></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`matches `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`a`}</code>{` OR `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`b`}</code>{` or `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`c`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`negative character class`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`[^a]`}</code></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`matches any character `}<em parentName="td">{`except`}</em>{` `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`a`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`character range`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`[a-z]`}</code></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`character range matching any lowercase letter from a to z`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`[a-z0-9]`}</code></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`character range matching any lowercase letter from a to z or any digit from 0-9`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`wildcard`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`.`}</code></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`matches a single occurence of any symbol. `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`a.`}</code>{` will match `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`aa`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`ab`}</code>{`, `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`ac`}</code>{`, etc.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`left-side anchor`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`^My`}</code></td>
          <td parentName="tr" {...{
            "align": "right"
          }}><strong parentName="td">{`My`}</strong>{` name is Inigo Montoya .`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`right-side anchor`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`ed$`}</code></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`matches a string that ends with `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`ed$`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`optionality`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`colou?r`}</code></td>
          <td parentName="tr" {...{
            "align": "right"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`?`}</code>{` makes the preceding character `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`u`}</code>{` optional; expression will match either `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`color`}</code>{` or `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`colour`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pattern scope`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`walk(ed)?`}</code></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`matches either `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`walk`}</code>{` or `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`walked`}</code>{`.  Use of pathentheses changes the scope of `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`?`}</code>{` such that `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`ed`}</code>{` is made optional.`}</td>
        </tr>
      </tbody>
    </table>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "eyes"
        }}>{`👀`}</span>{` Based on the table above, write a regex to match `}</undefined><em parentName="p">{`them`}</em>{` and `}<em parentName="p">{`they`}</em>{`.`}</p>
    <p>{`In addition to the features in the previous table, there are also a few convenient shorthand symbols: `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Language feature`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`any "word character"`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`\\w`}</code></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Matches any "word character"; equivalent to the character class `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`[A-Za-z0-9_]`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`any digit`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`\\d`}</code></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Any digit 0-9`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`any whitespace`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`\\s`}</code></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Matches any whitespace character including tabs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`word boundary`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`\\b`}</code></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`This is a zero-width assertion (i.e., it doesn't capture any character) denoting word boundaries`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`All of these shorthand symbols have negated version indicated by capital letters (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`\\W`}</code>{` matches anything but a "word character").`}</p>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "eyes"
        }}>{`👀`}</span>{` Since `}</undefined><code parentName="p" {...{
        "className": "language-text"
      }}>{`.`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`(`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`)`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`?`}</code>{`, and other symbols have special meaning in regexes, we need to escape them by prepending a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`\\`}</code>{` when we wish to match the symbol itself.  For example, if we want to match a literal question mark, we'd write the following:`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`\\?`}</code></p>
    <p>{`Let's apply what we've learned so far to do some field work and document `}<em parentName="p">{`Bovino`}</em>{`, an invented cow language...`}</p>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "cow face"
        }}>{`🐮`}</span>{` `}<span {...{
          "role": "img",
          "aria-label": "speech balloon"
        }}>{`💬`}</span>{` `}</undefined><em parentName="p">{`moo`}</em><br parentName="p"></br>{`
`}<undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "cow face"
        }}>{`🐮`}</span>{` `}<span {...{
          "role": "img",
          "aria-label": "speech balloon"
        }}>{`💬`}</span>{` `}</undefined><em parentName="p">{`moomoo`}</em></p>
    <p>{`Based on what we've seen so far, it seems `}<em parentName="p">{`Bovino`}</em>{` consists of only the utterances `}<strong parentName="p">{`moo`}</strong>{` and `}<strong parentName="p">{`moomoo`}</strong>{`.`}</p>
    <p>{`One possible expression is the following:`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`moo|moomoo`}</code></p>
    <p>{`Can you come up with an alternative?`}</p>
    <p>{`After many months in the field and countless hours of analysis, we come to realize that in `}<em parentName="p">{`Bovino`}</em>{`, `}<strong parentName="p">{`moo`}</strong>{` can be repeated a seemingly infinite number of times.`}</p>
    <p>{`How can we modify our regex to capture this observation?  We need some way of specifying repetitions.`}</p>
    <h2 {...{
      "id": "quantifiers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#quantifiers",
        "aria-label": "quantifiers permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Quantifiers`}</h2>
    <p>{`Regexes support quantification (repetitions).  We've already introduced one such quantifier, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`?`}</code>{`, to mark optional patterns, but there are others:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`?`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`The preceding pattern is optional.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`*`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Repeat the quantified pattern zero or more times.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`+`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Repeat the quantified pattern one or more times.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`{n}`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Exact repetition. Repeat the quantified pattern n times.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`{n,m}`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Ranged repetition. Repeat the quantified pattern between `}<em parentName="td">{`n`}</em>{` and `}<em parentName="td">{`m`}</em>{` times, where `}<em parentName="td">{`n`}</em>{` < `}<em parentName="td">{`m`}</em>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`{,m}`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Open start ranged repetition. Repeat the quantified pattern between 0 and m times, where `}<em parentName="td">{`m`}</em>{` > 0.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`{n,}`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Open end ranged repetition. Repeat the quantified pattern at least `}<em parentName="td">{`n`}</em>{` times, where `}<em parentName="td">{`n`}</em>{` > 0.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`By default, these quantifiers are considered greedy.  They will match as many times as they can:  `}</p>
    <p>{`$ mooooo \\rightarrow  mo* \\rightarrow mooooo$`}</p>
    <p>{`They can be made lazy by appending a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`?`}</code>{`.  In such cases, the quantifier will stop matching after matching the absolute minimum:`}</p>
    <p>{`$ mooooo \\rightarrow  mo*? \\rightarrow m$`}</p>
    <p>{`Let's use quantifiers to describe the `}<em parentName="p">{`Bovino`}</em>{` language:`}</p>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "cow face"
        }}>{`🐮`}</span>{` `}<span {...{
          "role": "img",
          "aria-label": "speech balloon"
        }}>{`💬`}</span>{` `}</undefined><em parentName="p">{`moo`}</em><br parentName="p"></br>{`
`}<undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "cow face"
        }}>{`🐮`}</span>{` `}<span {...{
          "role": "img",
          "aria-label": "speech balloon"
        }}>{`💬`}</span>{` `}</undefined><em parentName="p">{`moomoo`}</em><br parentName="p"></br>{`
`}<undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "cow face"
        }}>{`🐮`}</span>{` `}<span {...{
          "role": "img",
          "aria-label": "speech balloon"
        }}>{`💬`}</span>{` `}</undefined><em parentName="p">{`moomoomoomoomoomoomoomoomoo...`}</em></p>
    <p>{`We want to say that `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`moo`}</code>{` can repeat an `}<em parentName="p">{`infinite`}</em>{` number of times.  Which quantifier is appropriate?  `}</p>
    <p>{`You can test your answer in Python using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`match`}</code><sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` function defined in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`re`}</code>{` module:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`samples `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"moo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"moo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"moomoomoomoomoomoomoomoomoo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"meow"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# replace <your-pattern-here> with your solution`}</span>{`
pattern `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` re`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`compile`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"<your-pattern-here>"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# for every "udderance" ...`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` tok `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` samples`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  is_match `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`True`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` re`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`match`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`pattern`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` tok`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`False`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-interpolation"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`f"'`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`tok`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`' is Bovino?\\t`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`is_match`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`What if `}<em parentName="p">{`Bovino`}</em>{` allowed at most 3 repetitions of `}<strong parentName="p">{`moo`}</strong>{`?  How would your pattern change?`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`B`}</mi><mo parentName="mrow">{`→`}</mo><mi parentName="mrow">{`a`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`B \\rightarrow a`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.05017em"
                }
              }}>{`B`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span></span></span></span></span><br parentName="p"></br>{`
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`B`}</mi><mo parentName="mrow">{`→`}</mo><mi parentName="mrow">{`a`}</mi><mi parentName="mrow">{`C`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`B \\rightarrow aC`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.05017em"
                }
              }}>{`B`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07153em"
                }
              }}>{`C`}</span></span></span></span></span><br parentName="p"></br>{`
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`B`}</mi><mo parentName="mrow">{`→`}</mo><mi parentName="mrow">{`ϵ`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`B \\rightarrow \\epsilon`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.05017em"
                }
              }}>{`B`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`ϵ`}</span></span></span></span></span>{`  `}</p>
    <h2 {...{
      "id": "lookaround-expressions-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#lookaround-expressions-",
        "aria-label": "lookaround expressions  permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><undefined parentName="h2">{`Lookaround expressions `}<span {...{
          "role": "img",
          "aria-label": "eyes"
        }}>{`👀`}</span></undefined></h2>
    <p>{`Lookaround expressions can be used to specify contextual constraints that you don't want to end up in your result (ex. "only match B if it's preceded by A").  These are all "zero-width assertions" meaning they don't capture or return the portion of the string they match, but rather only check that the assertion is true.  Lookarounds can be both `}<em parentName="p">{`positive`}</em>{` (ex. "match `}<strong parentName="p">{`X`}</strong>{` only when it is preceded by `}<strong parentName="p">{`Y`}</strong>{`") or `}<em parentName="p">{`negative`}</em>{` (ex. "match `}<strong parentName="p">{`X`}</strong>{` but only when it is not preceded by `}<strong parentName="p">{`Y`}</strong>{`").`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Example Pattern`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Match (in bold)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`(?=...)`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`postive lookahead`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`Inigo(?= Montoya)`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`My name is `}<strong parentName="td">{`Inigo`}</strong>{` Montoya .`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`(?!...)`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`negative lookahead`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`Inigo(?! Arocena)`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`My name is `}<strong parentName="td">{`Inigo`}</strong>{` Montoya .`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`(?<=...)`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`positive lookbehind`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`(?<=Inigo )Montoya`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`My name is Inigo `}<strong parentName="td">{`Montoya`}</strong>{` .`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`(?<!...)`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`negative lookbehind`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`(?<!Carlos )Montoya`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`My name is Inigo `}<strong parentName="td">{`Montoya`}</strong>{` .`}</td>
        </tr>
      </tbody>
    </table>
    {
      /* ## Named captures
      One useful extension now commonplace in most implementations of regexes is that of named captures.  This allows you to name a subpattern and store its match for later reference. */
    }
    <h1 {...{
      "id": "using-regexes-with-python",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#using-regexes-with-python",
        "aria-label": "using regexes with python permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using regexes with Python`}</h1>
    <p><img parentName="p" {...{
        "src": "https://imgs.xkcd.com/comics/regular_expressions.png",
        "alt": "The power of regexes according to XKCD"
      }}></img><sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup></p>
    <p>{`Let's look at some examples of `}<em parentName="p">{`recognizing`}</em>{` and `}<em parentName="p">{`replacing`}</em>{` strings using regexes in Python.`}</p>
    <h2 {...{
      "id": "recognizing-strings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#recognizing-strings",
        "aria-label": "recognizing strings permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Recognizing strings`}</h2>
    <p>{`Let's imagine we want to analyze words containing the phone /ð/ (`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Voiced_dental_fricative",
        "target": "_self",
        "rel": "nofollow"
      }}>{`voiced interdental fricative`}</a>{`):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`tokens `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"the"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"think"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"then"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"father"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"wither"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"other"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"mother"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ninth"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`From these examples, it looks like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`th`}</code>{` is pronounced /ð/ when followed by an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`e`}</code>{`.  In such cases, it doesn't appear to matter what precedes `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`th`}</code>{` or follows `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`e`}</code>{`.`}</p>
    <p>{`In terms of regular expresions, that would mean something like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.*the.*`}</code></p>
    <p>{`zero or more characters
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.*`}</code>{`
... followed by
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`th`}</code>{`
... followed by e
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`e`}</code>{`
... followed by zero or more characters`}</p>
    <p>{`Let's use Python's `}<a parentName="p" {...{
        "href": "https://docs.python.org/3.11/library/re.html",
        "target": "_self",
        "rel": "nofollow"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`re.match`}</code>{` function`}</a>{` to apply that expression to check each term in a list:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` re

tokens `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"the"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"think"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"then"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"father"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"wither"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"other"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"mother"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ninth"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
pattern `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`".*the.*"`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` tok `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` tokens`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  is_match `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`True`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` re`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`match`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`pattern`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` tok`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`False`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-interpolation"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`f"'`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`tok`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`' contains /ð/?\\t`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`is_match`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h2 {...{
      "id": "replacing-strings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#replacing-strings",
        "aria-label": "replacing strings permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Replacing strings`}</h2>
    <p>{`Earlier, we looked at recognizing words that contain /ð/ using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`re.match`}</code>{` function.  Now let's imagine we wanted to generate a phonetic transcription of those text by replacing such instances of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`th`}</code>{` with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ð`}</code>{`.`}</p>
    <p>{`To make things simple, let's introduce the idea of a `}<em parentName="p">{`lookahead expression`}</em>{` which allows us to peek ahead to see if what follows matches some specified pattern.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`(?=<pattern>)`}</code></p>
    <p>{`So if we want to match `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`th`}</code>{` only when it is followed by `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`e`}</code>{`, we could write that in the following way:`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`th(?=e)`}</code></p>
    <p>{`Using the `}<a parentName="p" {...{
        "href": "https://docs.python.org/3.11/library/re.html",
        "target": "_self",
        "rel": "nofollow"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`re.sub`}</code>{` function`}</a>{`, we can apply this pattern to replace all such occurrences of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`th`}</code>{` with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ð`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` re
tokens `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"the"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"think"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"then"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"father"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"wither"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"other"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"mother"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ninth"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` tok `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` tokens`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  res `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` re`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`sub`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"th(?=e)"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'ð'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` tok`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`res`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`When coupled with `}<a parentName="p" {...{
        "href": "https://www.regular-expressions.info/backref.html",
        "target": "_self",
        "rel": "nofollow"
      }}>{`backreferences`}</a>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`re.sub()`}</code>{` is capable of very complex replacements.  We'll save that discussion for another time.`}</p>
    <h1 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next steps`}</h1>
    <p>{`You now know the basics of writing and using regexes.  Let's practice...`}</p>
    <h1 {...{
      "id": "practice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#practice",
        "aria-label": "practice permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Practice`}</h1>
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": 800,
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/0INHdD3Hdls?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    <ul>
      <li parentName="ul">{`Write a regex to recognize URLs.  Refine your regex using the following test cases:`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Match?`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`miscommunication`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://example.com",
              "target": "_self",
              "rel": "nofollow"
            }}>{`https://example.com`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "http://example.com/some/link",
              "target": "_self",
              "rel": "nofollow"
            }}>{`http://example.com/some/link`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`example.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`example.org`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "http://www.example.com",
              "target": "_self",
              "rel": "nofollow"
            }}>{`www.example.com`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`inorganic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul">{`Write a regex to recognize phone numbers.  Your regex should handle formats such as `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`1-(111)-111-1111`}</code>{`,  `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`(111)-111-1111`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`111-1111`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`1 (111) 111-1111`}</code>{`, and `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`1.111.111.1111`}</code>{`.`}</li>
    </ul>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}><a parentName="li" {...{
            "href": "https://docs.python.org/3/howto/regex.html#match-versus-search",
            "target": "_self",
            "rel": "nofollow"
          }}>{`As described in the docs`}</a>{`, `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`re.match()`}</code>{` will only match a regex from the start of a string.  To match a pattern any point in a string, use `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`re.search()`}</code>{`.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}><a parentName="li" {...{
            "href": "https://xkcd.com/208/",
            "target": "_self",
            "rel": "nofollow"
          }}>{`https://xkcd.com/208/`}</a><a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      