import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const HTMLTable = makeShortcode("HTMLTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "current-grants",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#current-grants",
        "aria-label": "current grants permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Current grants`}</h2>
    <HTMLTable condensed striped mdxType="HTMLTable">
    <tbody>
        <tr>
            <td><strong>Project</strong></td>
            <td><strong>Agency</strong></td>
        </tr>
        <tr>
            <td><a href="https://mr4all.parsertongue.org">Democratizing Machine Reading</a></td>
            <td>NSF</td>
        </tr>
        <tr>
            <td><a href="/projects/logx">LogX</a></td>
            <td>DARPA</td>
        </tr>
    </tbody>
    </HTMLTable>
    <div>
    </div>
    <h2 {...{
      "id": "unfunded-research",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#unfunded-research",
        "aria-label": "unfunded research permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Unfunded research`}</h2>
    <HTMLTable condensed striped mdxType="HTMLTable">
    <tbody>
        <tr>
            <td><strong>Project</strong></td>
        </tr>
        <tr>
            <td>Hypothesis chains linking non-interacting research communities</td>
        </tr>
        <tr>
            <td>Understanding the intent behind citations</td>
        </tr>
    </tbody>
    </HTMLTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      