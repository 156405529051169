import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`This tutorial provides instructions for installing Docker on Ubuntu. While the steps outlined here may work for other versions of Ubuntu (and some non-Ubuntu Linux distributions), these instructions were tested against `}<strong parentName="p">{`Ubuntu 20.04 LTS`}</strong>{`.`}</p>
    <h2 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h2>
    <ul>
      <li parentName="ul">{`A working `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`docker`}</code>{` installation`}</li>
    </ul>
    <h2 {...{
      "id": "prerequisites",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#prerequisites",
        "aria-label": "prerequisites permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prerequisites`}</h2>
    <p>{`Before starting this tutorial, ensure that ...`}</p>
    <ul>
      <li parentName="ul">{`You have a working `}<a parentName="li" {...{
          "href": "/tutorials/virtualbox-install-ubuntu"
        }}>{`Ubuntu 20.04 LTS installation`}</a></li>
      <li parentName="ul">{`You are `}<a parentName="li" {...{
          "href": "/tutorials/linux-command-line-basics"
        }}>{`comfortable with the basics of the Linux command line`}</a></li>
      <li parentName="ul">{`are `}<a parentName="li" {...{
          "href": "/tutorials/containers-intro"
        }}>{`familiar with containerization`}</a></li>
    </ul>
    <h1 {...{
      "id": "background",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#background",
        "aria-label": "background permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background`}</h1>
    <p>{`Docker is both a `}<a parentName="p" {...{
        "href": "https://www.docker.com/company",
        "target": "_self",
        "rel": "nofollow"
      }}>{`software company`}</a>{` and a popular software implementation of `}<a parentName="p" {...{
        "href": "/tutorials/containers-intro"
      }}>{`containerization`}</a>{`.  We'll be using it throughout this course to run examples and assignments.`}</p>
    <h1 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h1>
    <p>{`The Docker engine can be installed on MacOS, Linux, and even Windows* (with some caveats).  This tutorial provides instructions for installing Docker `}<a parentName="p" {...{
        "href": "/tutorials/virtualbox-install-ubuntu"
      }}>{`on Ubuntu 20.04 LTS`}</a>{` using the command line. `}</p>
    <h2 {...{
      "id": "macos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#macos",
        "aria-label": "macos permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MacOS`}</h2>
    <p>{`If you would like to install on MacOS (Docker Desktop), follow the official installation instructions:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.docker.com/engine/install/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://docs.docker.com/engine/install/`}</a></li>
    </ul>
    <p>{`Note, however, that `}<a parentName="p" {...{
        "href": "https://docs.docker.com/docker-for-mac/#preferences",
        "target": "_self",
        "rel": "nofollow"
      }}>{`there are numerous things you will need to configure`}</a>{`.`}</p>
    <h2 {...{
      "id": "windows-not-recommended",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows-not-recommended",
        "aria-label": "windows not recommended permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows (not recommended)`}</h2>
    <p>{`At the time of this writing, `}<strong parentName="p">{`I do not recommend installing Docker Desktop on Windows`}</strong>{`.  I recommend that Windows users instead install docker `}<a parentName="p" {...{
        "href": "/tutorials/virtualbox-install-ubuntu"
      }}>{`in a Linux virtual machine`}</a>{`.`}</p>
    <h2 {...{
      "id": "ubuntu-recommended",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ubuntu-recommended",
        "aria-label": "ubuntu recommended permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Ubuntu (recommended)`}</h2>
    <p>{`We'll use the linux CLI to install Docker on Ubuntu.  From your terminal, ...`}</p>
    <h3 {...{
      "id": "step-1-update-your-system",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-1-update-your-system",
        "aria-label": "step 1 update your system permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1: Update your system`}</h3>
    <p>{`Before install docker, let's update our package index:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` -y update`}</code></pre></div>
    <h3 {...{
      "id": "step-3-install-docker-and-docker-compose",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-3-install-docker-and-docker-compose",
        "aria-label": "step 3 install docker and docker compose permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3: Install `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`docker`}</code>{` and `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`docker-compose`}</code></h3>
    <p>{`We'll install the community edition of Docker (the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-ce`}</code>{` package) along with a tool for orchestrating several containerized services:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` -y docker.io
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` -y `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span></code></pre></div>
    <h3 {...{
      "id": "step-4-optional-configure-docker-to-run-without-sudo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-4-optional-configure-docker-to-run-without-sudo",
        "aria-label": "step 4 optional configure docker to run without sudo permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 4 (Optional): Configure Docker to run without `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`sudo`}</code></h3>
    <p>{`Running docker requires adminstrative priveleges.  Theses priveleges are invoked by using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sudo`}</code>{` commmand.  To avoid needing to type this  `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`#sudo groupadd docker`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`usermod`}</span>{` -aG `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# avoid the need to restart to apply changes`}</span>{`
newgrp `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span></code></pre></div>
    <p><a parentName="p" {...{
        "href": "https://docs.docker.com/engine/install/linux-postinstall/",
        "target": "_self",
        "rel": "nofollow"
      }}>{`You can learn more about this step here.`}</a></p>
    <h1 {...{
      "id": "testing-your-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#testing-your-installation",
        "aria-label": "testing your installation permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testing your installation`}</h1>
    <p>{`To test our installation, we'll pull (download) the `}<a parentName="p" {...{
        "href": "https://hub.docker.com/_/hello-world",
        "target": "_self",
        "rel": "nofollow"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`hello-world`}</code>{` image`}</a>{` from `}<a parentName="p" {...{
        "href": "https://hub.docker.com",
        "target": "_self",
        "rel": "nofollow"
      }}>{`DockerHub`}</a>{` and launch a container using that image:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "docker"
    }}><pre parentName="div" {...{
        "className": "language-docker"
      }}><code parentName="pre" {...{
          "className": "language-docker"
        }}>{`docker run hello-world:latest`}</code></pre></div>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "eyes"
        }}>{`👀`}</span>{` If you get a permission error, run the command with `}</undefined><code parentName="p" {...{
        "className": "language-text"
      }}>{`sudo`}</code>{` (i.e., `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sudo docker run hello-world:latest`}</code>{`).`}</p>
    <p>{`By convention, the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`:latest`}</code>{` tag should reference the most recent version of the image.`}</p>
    <p>{`You should see output like the following:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`Hello from Docker`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`
This message shows that your installation appears to be working correctly.

To generate this message, Docker took the following steps:
 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`. The Docker client contacted the Docker daemon.
 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`. The Docker daemon pulled the `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"hello-world"`}</span>{` image from the Docker Hub.
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`amd64`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{`. The Docker daemon created a new container from that image `}<span parentName="code" {...{
            "className": "token function"
          }}>{`which`}</span>{` runs the
    executable that produces the output you are currently reading.
 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{`. The Docker daemon streamed that output to the Docker client, `}<span parentName="code" {...{
            "className": "token function"
          }}>{`which`}</span>{` sent it
    to your terminal.

To try something `}<span parentName="code" {...{
            "className": "token function"
          }}>{`more`}</span>{` ambitious, you can run an Ubuntu container with:
 $ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run -it ubuntu `}<span parentName="code" {...{
            "className": "token function"
          }}>{`bash`}</span>{`

Share images, automate workflows, and `}<span parentName="code" {...{
            "className": "token function"
          }}>{`more`}</span>{` with a `}<span parentName="code" {...{
            "className": "token function"
          }}>{`free`}</span>{` Docker ID:
 https://hub.docker.com/

For `}<span parentName="code" {...{
            "className": "token function"
          }}>{`more`}</span>{` examples and ideas, visit:
 https://docs.docker.com/get-started/`}</code></pre></div>
    <p>{`Congrats!  You're ready to run containerized apps!  `}</p>
    <h1 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next steps`}</h1>
    <p><a parentName="p" {...{
        "href": "/tutorials/docker-basics"
      }}>{`For an introduction to using Docker, see this tutorial`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      