import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import BasicLayout from 'src/components/BasicLayout';
import PdfComponent from 'src/components/PdfComponent';
import pdfData from 'assets/posters/2014-asa-testing-at.pdf';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PdfComponent pdfData={pdfData} mdxType="PdfComponent" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      