import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import { HTMLTable } from '@blueprintjs/core';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    {
      /* <iframe src="https://calendar.google.com/calendar/b/3/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FPhoenix&amp;src=cGFyc2VydG9uZ3VlLmNvbV9oMXJuc3N2bzNuNjlrMXVyOWhnbXV0bGkwa0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23F09300&amp;showPrint=1" style="border:solid 1px #777" width="800" height="600" frameborder="0" scrolling="no"></iframe> */
    }
    <p><a parentName="p" {...{
        "href": "https://d2l.arizona.edu",
        "target": "_self",
        "rel": "nofollow"
      }}>{`See the course D2L page for a complete class schedule`}</a>{`.`}</p>
    <h1 {...{
      "id": "class-preparation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#class-preparation",
        "aria-label": "class preparation permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Class Preparation`}</h1>
    <p>{`To prepare for each unit, complete the readings and activities noted in the table below.`}</p>
    <HTMLTable condensed striped mdxType="HTMLTable">
      <tbody>
        <tr>
            <td><strong>Unit</strong></td>
            <td><strong>Topic</strong></td>
            <td><strong>Readings &amp; Resources</strong></td>
        </tr>
        <tr>
            <td>
              <p>Configuring your development environment</p>
            </td>
            <td colSpan="1">
              <ul>
                <li>Using Virtualbox to install a Linux VM</li>
                <li>version control with Git and GitHub</li>
                <li>Linux command line basics</li>
                <li>Containers with Docker</li>
                <li>Jupyter</li>
              </ul>
            </td>
            <td colSpan="1">
              <p>
              <a href="https://parsertongue.org/courses/snlp-1/configuring-your-development-environment">Unit tutorials</a>
              </p>
            </td>
        </tr>
        <tr>
            <td>
              <p>NLP Intro</p>
            </td>
            <td>
              <p>Syllabus, class overview, etc.</p>
              <p>Intro to ...
              <ul>
                <li>tokenization</li>
                <li>normalization</li>
                <li>POS Tagging</li>
                <li>NER</li>
                <li>syntactic representations</li>
                <li>coreference resolution</li>
              </ul>
              </p>
            </td>
            <td>
              <p>Complete <a href="https://spacy.io/usage/spacy-101">https://spacy.io/usage/spacy-101</a></p>
              <p><a href="https://public.parsertongue.org/readings/slp3/2.pdf#page=12">Speech and Language Processing 2.4</a></p>
              <p><a href="https://public.parsertongue.org/readings/slp3/8.pdf">Speech and Language Processing 8.0 - 8.2</a></p>
              <p><a href="https://public.parsertongue.org/readings/slp3/18.pdf#page=2">Speech and Language Processing 18.1.0 - 18.1.1</a></p>
              <p><a href="https://public.parsertongue.org/readings/slp3/22.pdf#page=4">Speech and Language Processing 22.1</a></p>
              <p><a href="https://public.parsertongue.org/readings/slp3/12.pdf">Speech and Language Processing 12.0 - 12.1</a></p>
              <p><a href="https://public.parsertongue.org/readings/slp3/15.pdf">Speech and Language Processing 15.0 - 15.1</a></p>
            </td>
        </tr>
        <tr>
            <td>
              <p>ML Intro</p>
            </td>
            <td>
              <ul>
                <li>probability review</li>
                <li>classification using Naïve Bayes</li>
                <li>evaluating classifiers</li>
              </ul>
            </td>
            <td>
              <p><a href="https://public.parsertongue.org/readings/slp3/3.pdf#page=13">Speech and Language Processing 3.4.0 - 3.4.1</a></p>
              <p><a href="https://public.parsertongue.org/readings/slp3/4.pdf">Speech and Language Processing 4.0 - 4.9</a></p>
            </td>
        </tr>
        <tr>
            <td>
              <p>Logistic Regression</p>
            </td>
            <td>
              <ul>
                <li>logistic regression (overview)</li>
                <li>cross entropy</li>
                <li>MLE</li>
                <li>gradient descent</li>
                <li>regularization</li>
                <li>multinomial LR</li>
              </ul>
            </td>
            <td>
              <p><a href="https://public.parsertongue.org/readings/slp3/5.pdf">Speech and Language Processing 5.0 - 5.7</a></p>
              <p><a href="https://public.parsertongue.org/readings/slp3/5.pdf#page=17">OPTIONAL: Speech and Language Processing 5.8</a></p>
            </td>
        </tr>
        <tr>
            <td>
              <p>Word and Phrase Representations</p>
            </td>
            <td>
              <ul>
                <li>words as vectors</li>
                <li>TF-IDF</li>
                <li>PPMI</li>
                <li>lexical semantics and vector semantics</li>
                <li>word2vec (Skip-gram and CBOW)</li>
                <li>GloVe</li>
                <li>evaluating embeddings</li>
                {
                /* <li>introduction to contextualized embeddings</li> */
              }
              </ul>
            </td>
            <td>
              <p><a href="https://public.parsertongue.org/readings/slp3/6.pdf">Speech and Language Processing Ch 6</a></p>
              <p><a href="https://doi.org/10.3115/v1/D14-1162">Glove: Global Vectors for Word Representation</a></p>
              <p><a href="https://jalammar.github.io/illustrated-bert/">The Illustrated BERT, ELMo, and co.</a></p>
              <p><a href="https://public.parsertongue.org/courses/snlp-1/demos/word-embeddings-demo.html">Word embeddings demo</a></p>
            </td>
        </tr>
        <tr>
            <td>
              <p>Sequence Tagging</p>
            </td>
            <td>
              <ul>
                <li>part-of-speech tagging</li>
                <li>HMMs</li>
                <li>MEMMs</li>
                <li>decoding</li>
              </ul>
            </td>
            <td>
              <p><a href="https://public.parsertongue.org/readings/slp3/8.pdf">Speech and Language Processing 8.0 - 8.7</a></p>
            </td>
        </tr>
        <tr>
            <td>
              <p>Dependency Parsing</p>
            </td>
            <td>
              <ul>
                <li>dependency trees</li>
                <li>transition-based parsing</li>
                <li>graph-based parsing</li>
              </ul>
            </td>
            <td>
              <p><a href="https://public.parsertongue.org/readings/slp3/15.pdf">Speech and Language Processing Ch 15</a></p>
            </td>
        </tr>
    </tbody>
    </HTMLTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      