import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import { HTMLTable } from '@blueprintjs/core';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`In this unit, we'll look at recurrent neural networks (RNNs) and encoder-decoder architectures. `}</p>
    <h1 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h1>
    <p>{`By the end of this unit, you'll be able to ...`}</p>
    <ul>
      <li parentName="ul">{`identify types of recurrent neural networks`}</li>
      <li parentName="ul">{`describe uses of encoder-decoder architectures`}</li>
    </ul>
    <h1 {...{
      "id": "resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#resources",
        "aria-label": "resources permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Resources`}</h1>
    <p><undefined parentName="p">{`The resources linked below `}<span {...{
          "id": "citation-0",
          "data-hover": ""
        }}><span parentName="span" {...{
            "className": "citation-number"
          }}>{`[?]`}</span></span>{` are useful references for the topics covered in this unit:`}</undefined></p>
    <HTMLTable condensed striped mdxType="HTMLTable">
  <tbody>
    <tr>
        <td>
          <p>Readings</p>
        </td>
        <td>
        </td>
        <td>
          <p>
            <a href="https://public.parsertongue.com/readings/slp3/9-dl.pdf">9.0 - 9.6 of Jurafsky and Martin's <i>Speech and Language Processing</i></a>
          </p>
          <p>
            <a href="https://colah.github.io/posts/2015-08-Understanding-LSTMs/">Understanding LSTM Networks (Olah, 2015)</a>
          </p>
           <p>
            <a href="https://karpathy.github.io/2015/05/21/rnn-effectiveness/">The Unreasonable Effectiveness of Recurrent Neural Networks (Karpathy, 2015)</a>
          </p>
          <p>
            <a href="https://www.ruder.io/deep-learning-nlp-best-practices/#lstmtricks">The LSTM Tricks section of Sebastian Ruder's Deep Learning for NLP Best Practices</a>
          </p>
          <p>
            Chapters 14-17 of Yoav Goldberg's <a href="https://arizona-primo.hosted.exlibrisgroup.com/permalink/f/6ljalh/01UA_ALMA51534702160003843"><i>Neural Network Methods for Natural Language Processing</i></a> (highly recommended).
          </p>
        </td>
    </tr>
    <tr>
        <td>
          <p>Academic papers</p>
        </td>
        <td>
          <i>optional readings</i>
        </td>
        <td>
          <p>
            <a href="https://proceedings.neurips.cc/paper/2014/file/a14ac55a4f27472c5d894ec1c3c743d2-Paper.pdf">Sequence to Sequence Learning with Neural Networks (Sutskever et al., 2014)</a>
          </p>
          <p>
            <a href="https://arxiv.org/abs/1506.00019">A critical review of recurrent neural networks for sequence learning (Lipton et al., 2015)</a>
          </p>
          <p>
            <a href="https://arxiv.org/abs/1912.05911">Recurrent Neural Networks (RNNs): A gentle Introduction and Overview</a>
          </p>
        </td>
    </tr>
  </tbody>
    </HTMLTable>
    {
      /* <tr>
           <td>
             <p><code>einops</code></p>
           </td>
           <td>
           </td>
           <td>
             <p>
               <a href="https://github.com/arogozhnikov/einops">Library supporting Einstein notation to define and describe resizing operations</a>
             </p>
           </td>
         </tr>
      */
    }
    <h1 {...{
      "id": "learn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#learn",
        "aria-label": "learn permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Learn`}</h1>
    {
      /* :::note NOTE 
      _Complete these tutorials in the order listed._
      ::: */
    }
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/rnns-for-nlp"
        }}>{`RNNs for NLP`}</a></li>
    </ul>
    <h1 {...{
      "id": "practice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#practice",
        "aria-label": "practice permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Practice`}</h1>
    <p>{`Try to put these ideas into practice for your shared task and class competition submissions.`}</p>
    {
      /* - [Review what you've learned](https://arizona.openclass.ai) */
    }
    {
      /* # References */
    }
    <p>{`@@bibliography@@
@Book{goldberg2017neural,
author = {Goldberg, Yoav},
title = {Neural Network Methods for Deep Learning},
year = {2017},
publisher = {Morgan & Claypool Publishers},
address = {San Rafael, California},
isbn = {1627052984},
url = {\\url{ebookcentral.proquest.com/lib/uaz/reader.action?docID=4843762}},
}
@misc{ruder2017deeplearningnlp,
author = {Ruder, Sebastian},
title = {Deep Learning for NLP Best Practices},
year = {2017},
howpublished = {\\url{`}<a parentName="p" {...{
        "href": "http://www.ruder.io/deep-learning-nlp-best-practices/%7D%7D",
        "target": "_self",
        "rel": "nofollow"
      }}>{`www.ruder.io/deep-learning-nlp-best-practices/}}`}</a>{`,
url = {\\url{`}<a parentName="p" {...{
        "href": "http://www.ruder.io/deep-learning-nlp-best-practices/%7D%7D",
        "target": "_self",
        "rel": "nofollow"
      }}>{`www.ruder.io/deep-learning-nlp-best-practices/}}`}</a>{`,
}
@@bibliography@@`}</p>
    {
      /* 
      Group: ling-582
      */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      