import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import { HTMLTable } from '@blueprintjs/core';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`In this unit, we'll look at some common problems encountered in deep learning for NLP, and introduce some useful strategies for addressing these problems. `}</p>
    <h1 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h1>
    <p>{`By the end of this unit, you'll be able to ...`}</p>
    <ul>
      <li parentName="ul">{`detect vanishing gradients`}</li>
      <li parentName="ul">{`identify potential solutions for addressing vanishing gradients`}</li>
      <li parentName="ul">{`detect exploding gradients`}</li>
      <li parentName="ul">{`identify potential solutions for addressing exploding gradients`}</li>
    </ul>
    <h1 {...{
      "id": "resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#resources",
        "aria-label": "resources permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Resources`}</h1>
    <p><undefined parentName="p">{`The resources linked below `}<span {...{
          "id": "citation-0",
          "data-hover": "<br><br>"
        }}><span parentName="span" {...{
            "className": "citation-number"
          }}>{`[?, ?]`}</span></span>{` are useful references for the topics covered in this unit:`}</undefined></p>
    <HTMLTable condensed striped mdxType="HTMLTable">
  <tbody>
    <tr>
        <td>
          <p>Readings</p>
        </td>
        <td>
        </td>
        <td>
          <p>
            Section 5.2 of Yoav Goldberg's <a href="https://arizona-primo.hosted.exlibrisgroup.com/permalink/f/6ljalh/01UA_ALMA51534702160003843"><i>Neural Network Methods for Natural Language Processing</i></a>.  Highly recommended and a quick ~4-page read.
          </p>
          <p>
            Sebastian Ruder's <a href="https://www.ruder.io/deep-learning-nlp-best-practices/"><i>Deep Learning for NLP Best Practices</i></a>. Contains many task-specific tips, as well as many useful tips relating to RNNs.
          </p>
        </td>
    </tr>
    <tr>
        <td>
          <p>Wrappers for PyTorch</p>
        </td>
        <td>
          <p><code>pytorch-ignite</code></p>
          <p><code>pytorch-lightning</code></p>
          <p><code>fast.ai</code></p>
        </td>
        <td>
          <p>
            Remove boilerplate with this <a href="https://pytorch.org/ignite/index.html">"high-level library to help with training and evaluating neural networks in PyTorch flexibly and transparently"</a>
           </p>
           <p>
            Remove boilerplate with this <a href="https://www.pytorchlightning.ai/"> opinionated, but powerful wrapper for PyTorch</a> that supports distributed training
           </p>
           <p>
             Get up and running quickly with this library offering <a href="https://docs.fast.ai/#About-fastai">"high-level components that can quickly and easily provide state-of-the-art results in standard deep learning domains"</a>
           </p>
        </td>
    </tr>
  </tbody>
    </HTMLTable>
    {
      /* <tr>
           <td>
             <p><code>einops</code></p>
           </td>
           <td>
           </td>
           <td>
             <p>
               <a href="https://github.com/arogozhnikov/einops">Library supporting Einstein notation to define and describe resizing operations</a>
             </p>
           </td>
         </tr>
      */
    }
    <h1 {...{
      "id": "learn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#learn",
        "aria-label": "learn permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Learn`}</h1>
    {
      /* :::note NOTE 
      _Complete these tutorials in the order listed._
      ::: */
    }
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/dl-for-nlp-tips-and-tricks"
        }}>{`Tips and tricks`}</a></li>
    </ul>
    <h1 {...{
      "id": "practice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#practice",
        "aria-label": "practice permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Practice`}</h1>
    <p>{`Try to put these ideas into practice for your shared task and class competition submissions.`}</p>
    {
      /* - [Review what you've learned](https://arizona.openclass.ai) */
    }
    {
      /* # References */
    }
    <p>{`@@bibliography@@
@Book{goldberg2017neural,
author = {Goldberg, Yoav},
title = {Neural Network Methods for Deep Learning},
year = {2017},
publisher = {Morgan & Claypool Publishers},
address = {San Rafael, California},
isbn = {1627052984},
url = {\\url{ebookcentral.proquest.com/lib/uaz/reader.action?docID=4843762}},
}
@misc{ruder2017deeplearningnlp,
author = {Ruder, Sebastian},
title = {Deep Learning for NLP Best Practices},
year = {2017},
howpublished = {\\url{`}<a parentName="p" {...{
        "href": "http://www.ruder.io/deep-learning-nlp-best-practices/%7D%7D",
        "target": "_self",
        "rel": "nofollow"
      }}>{`www.ruder.io/deep-learning-nlp-best-practices/}}`}</a>{`,
url = {\\url{`}<a parentName="p" {...{
        "href": "http://www.ruder.io/deep-learning-nlp-best-practices/%7D%7D",
        "target": "_self",
        "rel": "nofollow"
      }}>{`www.ruder.io/deep-learning-nlp-best-practices/}}`}</a>{`,
}
@@bibliography@@`}</p>
    {
      /* 
      Group: ling-582
      */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      