import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`This lesson introduces you to the concept of containerization.`}</p>
    <h2 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h2>
    <p>{`After reading this lesson, you should be comfortable...`}</p>
    <ul>
      <li parentName="ul">{`explaining how containerization is used`}</li>
    </ul>
    <h2 {...{
      "id": "prerequisites",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#prerequisites",
        "aria-label": "prerequisites permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prerequisites`}</h2>
    <p>{`This tutorial assumes that ...`}</p>
    <ul>
      <li parentName="ul">{`you're already familiar with the concept of software dependencies`}</li>
    </ul>
    <h1 {...{
      "id": "background",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#background",
        "aria-label": "background permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background`}</h1>
    <h2 {...{
      "id": "what-is-a-container",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-is-a-container",
        "aria-label": "what is a container permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h2">{`What is a container?`}</em></h2>
    <blockquote>
      <p parentName="blockquote">{`It works on my machine!`}</p>
    </blockquote>
    <p>{`It can be frustrating and time-consuming to identify system-specific differences that may interfere with how an application behaves on various machines and operating systems.`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` `}</p>
    <p>{`Conceptually, `}<strong parentName="p"><em parentName="strong">{`containers provide a way of packaging an application and all of its dependencies (code, companion libraries, configuration files, etc.) so that it may be run uniformly/predictably on different machines and operating systems.`}</em></strong></p>
    <p>{`Containers achieve this by sharing the underlying operating system of the host.  In this way, several containers can be run on a single machine running a single operating system. `}</p>
    <p>{`For more information, `}<a parentName="p" {...{
        "href": "https://www.docker.com/resources/what-container",
        "target": "_self",
        "rel": "nofollow"
      }}>{`see this article`}</a>{`.`}</p>
    <h2 {...{
      "id": "how-is-a-container-different-from-a-vm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-is-a-container-different-from-a-vm",
        "aria-label": "how is a container different from a vm permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h2">{`How is a container different from a VM?`}</em></h2>
    <p>{`A container is much more lightweight than a VM (smaller size and requires fewer resources), because it doesn't package a complete operating system.  `}</p>
    <h3 {...{
      "id": "containers-vs-vms",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#containers-vs-vms",
        "aria-label": "containers vs vms permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Containers vs. VMs`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Containers`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Virtual Machines`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Boot time`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`milliseconds`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`minutes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operating System`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Shared by containers running on same host`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`One OS per VM`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Relative size`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`small (typically measured in MB)`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`large (typically measured in GB)`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Containers share the underlying operating system of the host.  In this way, several containers can be run on a single machine with low overhead. `}</p>
    {
      /* ![architecture of containers](images/container-architecture.png)*/
    }
    {
      /* ![architecture of vms](images/vm-architecture.png) */
    }
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "590px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/138b74bf2dcc500fcf0a6f9957e75412/b6c94/containers-vs-vms.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "31.08108108108108%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABcSAAAXEgFnn9JSAAABi0lEQVQY01XM20vbYBiA8fylu9GLrTAR1ImbIvOmMhyeHYqiMoebUtgU21p7WCyzto09rBrTJq2OzlNaNtSm1SblGU124wsf7+97Lx4h81NnfeOUHX8Jz9cCW96is3c0vnjy1GpN2mNZLSzLxDKbmGbTsWU+catlIaxvKjzr9NH7WuRFd5Du/giu/7vDFeD6xrCD9cYjefWSk9MypXPdsVLm7FcFpXDBsVzmz98awoYnT4drl4HhKC97QvS9EenqDfNqUOR51x43uhM06g+ks0XikkJBuyCV1ThMKqjFtlX7XqneIhwmrpiay7D6SWZlTWbts8LyR5ml1RMWV465vXu0g5VqnakPKcanjxiflpiZTzO3kGFiRmJyNsXo+wTx5CWCWqqyvSsTiKj4QzLfvEcERRXvnkI4qmEYTlCv3DMyFmDQ7WXI7cM9GWRsNsLQqI/hd3763m4jHmgI5791IrEc0aRMLJVHyhVt7yecf+PBCd4bDcKxLMEfafu1/T2esx06yODflzgrX/MPLeiQxh9DLPsAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "architectural comparison of containers and vms",
              "title": "Architectural comparision of containers and VMs",
              "src": "/static/138b74bf2dcc500fcf0a6f9957e75412/fcda8/containers-vs-vms.png",
              "srcSet": ["/static/138b74bf2dcc500fcf0a6f9957e75412/12f09/containers-vs-vms.png 148w", "/static/138b74bf2dcc500fcf0a6f9957e75412/e4a3f/containers-vs-vms.png 295w", "/static/138b74bf2dcc500fcf0a6f9957e75412/fcda8/containers-vs-vms.png 590w", "/static/138b74bf2dcc500fcf0a6f9957e75412/efc66/containers-vs-vms.png 885w", "/static/138b74bf2dcc500fcf0a6f9957e75412/c83ae/containers-vs-vms.png 1180w", "/static/138b74bf2dcc500fcf0a6f9957e75412/b6c94/containers-vs-vms.png 1429w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Architectural comparision of containers and VMs`}</figcaption>{`
  `}</figure><sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup></p>
    <p>{`Unlike a virtual machine which might house many different applications, commonly a single container is used for each application to better isolate dependencies and minimize the potential for conflicts.`}</p>
    <h2 {...{
      "id": "why-bother-using-containers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why-bother-using-containers",
        "aria-label": "why bother using containers permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h2">{`Why bother using containers?`}</em></h2>
    <p>{`Containers can be a great solution to many kinds of problems in software development both in industry and academia... `}</p>
    <p><strong parentName="p">{`Scenario 1`}</strong>{`: You're developing a large software project that requires custom versions of several libraries.  The installation steps are complicated and differ depending on the OS (ex. Windows vs Linux vs MacOS). `}</p>
    <p><strong parentName="p">{`Scenario 2`}</strong>{`: You published a groundbreaking paper and need to release your code and data.  You want to ensure researchers can replicate your results far into the future and make it easy for them to experiment with alterations.`}</p>
    <p><strong parentName="p">{`Scenario 3`}</strong>{`: You need to run several very compute-intensive experiments on a cluster.  You don't have adminstrative privileges on the cluster to install dependencies and your code was developed on a different OS.`}</p>
    <p><strong parentName="p">{`Scenario 4`}</strong>{`: You have complex code that needs to run periodically when a web request is received.  The server hosting your app doesn't have the resources to run a VM continuously and you're waiting for the VM to boot to answer each request and then shutdown will introduce too much latency.`}</p>
    <p><strong parentName="p">{`Scenario 5`}</strong>{`: You need a portable development environment that is uniform across several machines.`}</p>
    <h1 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next steps`}</h1>
    <p>{`Now that you're familiar with the concept of containerization, `}<a parentName="p" {...{
        "href": "/tutorials/docker-basics"
      }}>{`take a look at the introductory Docker tutorial`}</a>{` for a hands-on approach to learning about a popular implementation of containerization.`}</p>
    <h2 {...{
      "id": "further-reading",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#further-reading",
        "aria-label": "further reading permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Further reading`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cyverse-foss-2020.readthedocs-hosted.com/en/latest/Containers/introtocontainers.html",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://cyverse-foss-2020.readthedocs-hosted.com/en/latest/Containers/introtocontainers.html`}</a>{` `}</li>
    </ul>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`See `}<a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Dependency_hell",
            "target": "_self",
            "rel": "nofollow"
          }}>{`https://en.wikipedia.org/wiki/Dependency_hell`}</a><a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`Architectural comparison of containers and VMs.`}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      