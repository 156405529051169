import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Our NSF proposal for technology transfer, `}<em parentName="p">{`Artificial Intelligence System for Enterprise Performance Management that Integrates Causal Analytics and Human Expertise`}</em>{`, a joint effort with collaborators at UNCC was recommended for funding (`}<a parentName="p" {...{
        "href": "https://www.nsf.gov/awardsearch/showAward?AWD_ID=2141124%5D",
        "target": "_self",
        "rel": "nofollow"
      }}>{`NSF award # 2141124`}</a>{`).`}</p>
    <h1 {...{
      "id": "abstract",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#abstract",
        "aria-label": "abstract permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Abstract`}</h1>
    <blockquote>
      <p parentName="blockquote">{`The broader impact/commercial potential of this Partnerships for Innovation - Technology Translation (PFI-TT) project is to respond to the unmet market need for explainable prescriptive (i.e., causal) analytics in enterprise performance management (EPM). If successful, the proposed platform will enable organizations to solve complex, multi-objective management problems, including financial outcomes and well-being for customers, employees, and other stakeholders. The proposed prescriptive analytics solution is expected to optimize decision-making through improvements in enterprise-wide performance. The market for EPM is expected to reach $7.7 billion in sales by 2026, but only 11% of large and medium-sized enterprises currently use some form of prescriptive analytics. Notably, more than 50% of healthcare systems are unsatisfied with currently available EPM software, which leads to decreases in patient satisfaction and operating margins. This project's broader societal impact will help enterprises visually monitor the trade-offs and synergies between economic performance (e.g., return on investment) and well-being outcomes (e.g., customer and employee satisfaction) and identify optimal actions.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`This project seeks to help organizations deal with the complexity of multi-stakeholder, multi-objective performance management. It builds the first commercial use case in healthcare systems, focusing on healthcare organizations' multi-stakeholder performance management. The technology will develop a Knowledge Graph-Based Reasoning Artificial Intelligence Network, which will enable human-machine collaboration to accelerate the synthesis of domain knowledge from expert documents into causal knowledge graphs and ingest such graphs into causal inference and data science tools. The intellectual merit of the proposed project stems from building a high-performance machine reading system to extract and synthesize causal insights from both academic and industry documents. The team also seeks to develop the first knowledge graph-based reasoning system for enterprise management to identify hidden causal relations and make intervention recommendations. Finally, the project will develop a novel approach to improving causal inference in data science. By combining causal knowledge graphs with a large sample of healthcare system data, the product will create a synthetic counterfactual from observatory data for each healthcare system and run causal analysis to identify the most effective actions for a given set of performance objectives.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      