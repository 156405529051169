import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`This lesson introduces you to the basics of Docker.`}</p>
    <h2 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h2>
    <p>{`After reading this lesson, you should be comfortable...`}</p>
    <ul>
      <li parentName="ul">{`pulling images`}</li>
      <li parentName="ul">{`launching containers`}</li>
      <li parentName="ul">{`using bind mounts to share and persist data`}</li>
      <li parentName="ul">{`forwarding ports`}</li>
    </ul>
    <h2 {...{
      "id": "prerequisites",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#prerequisites",
        "aria-label": "prerequisites permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prerequisites`}</h2>
    <p>{`This tutorial assumes that ...`}</p>
    <ul>
      <li parentName="ul">{`You have a working `}<a parentName="li" {...{
          "href": "/tutorials/virtualbox-install-ubuntu"
        }}>{`Ubuntu 20.04 LTS installation`}</a></li>
      <li parentName="ul">{`You are `}<a parentName="li" {...{
          "href": "/tutorials/linux-command-line-basics"
        }}>{`comfortable with the basics of the Linux command line`}</a></li>
      <li parentName="ul">{`You are `}<a parentName="li" {...{
          "href": "/tutorials/containers-intro"
        }}>{`familiar with the concept of containerization`}</a></li>
      <li parentName="ul">{`You have `}<a parentName="li" {...{
          "href": "/tutorials/ubuntu-install-docker"
        }}>{`a working docker installation`}</a></li>
    </ul>
    <h1 {...{
      "id": "background",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#background",
        "aria-label": "background permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background`}</h1>
    <h2 {...{
      "id": "what-is-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-is-docker",
        "aria-label": "what is docker permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h2">{`What is Docker?`}</em></h2>
    <p>{`Docker is both a `}<a parentName="p" {...{
        "href": "https://www.docker.com/company",
        "target": "_self",
        "rel": "nofollow"
      }}>{`software company`}</a>{` and a software implementation of `}<a parentName="p" {...{
        "href": "/tutorials/containers-intro"
      }}>{`containerization`}</a>{`.  The company helped establish the Open Container Initiative (OCI), A Linux Foundation project which defines industry standards for container technlogy.  `}</p>
    <p>{`While docker software isn't the only implementation of containerization used today, at the time of this writing, it is the most ubiquitous container technology. `}</p>
    <h2 {...{
      "id": "images-vs-containers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#images-vs-containers",
        "aria-label": "images vs containers permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Images vs containers`}</h2>
    <p>{`At a conceptual level, there are two important concepts that we'll cover: `}<strong parentName="p">{`images`}</strong>{` and `}<strong parentName="p">{`containers`}</strong>{`.  `}</p>
    <h3 {...{
      "id": "images",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#images",
        "aria-label": "images permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Images`}</h3>
    <p>{`An `}<strong parentName="p">{`image`}</strong>{` can be thought of as a snapshot of a particular configuration which defines and bundles all a) dependencies, b) required data, and c) any default launch behaviors.`}</p>
    <ul>
      <li parentName="ul">{`Images are `}<em parentName="li">{`immutable`}</em>{` (i.e., they do not change).  The only way to alter an image is to create a new image by extending the old one.  `}</li>
      <li parentName="ul">{`Docker image definitions are specified in a special file called a Dockerfile.`}</li>
      <li parentName="ul">{`Images can be shared.  Most commonly, images are exchanged by pushing (publishing) and pulling to and from registries such as `}<a parentName="li" {...{
          "href": "https://hub.docker.com/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`DockerHub`}</a>{`.`}</li>
    </ul>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "exclamation mark"
        }}>{`❗`}</span>{` `}</undefined><em parentName="p">{`In this introductory tutorial, we'll look at how to create `}<strong parentName="em">{`containers`}</strong>{` from images, but we won't explore how to create or extend `}<strong parentName="em">{`images`}</strong>{`.`}</em></p>
    <h2 {...{
      "id": "containers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#containers",
        "aria-label": "containers permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Containers`}</h2>
    <p>{`A `}<strong parentName="p">{`container`}</strong>{` is an `}<em parentName="p">{`instance`}</em><sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` of an image.`}</p>
    <ul>
      <li parentName="ul">{`Containers are `}<em parentName="li">{`mutable`}</em>{`.  They can be altered while running, but these changes don't persist when the container is stopped.`}</li>
    </ul>
    <p>{`Mounted `}<strong parentName="p">{`volumes`}</strong>{` are used to save the output of a container's process or provide the container with data not included in its parent image (ex. database files)`}</p>
    <h1 {...{
      "id": "docker-hands-on",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#docker-hands-on",
        "aria-label": "docker hands on permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Docker hands-on`}</h1>
    <h2 {...{
      "id": "pulling-an-image-from-dockerhub",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#pulling-an-image-from-dockerhub",
        "aria-label": "pulling an image from dockerhub permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pulling an image from DockerHub`}</h2>
    <p>{`To pull an image from the DockerHub registry, you need to specify the `}<strong parentName="p">{`owner`}</strong>{` of the image, the name of the `}<strong parentName="p">{`repository`}</strong>{`, and a `}<strong parentName="p">{`tag`}</strong>{` (version).`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<owner>/<repo>:<tag>`}</code></p>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "exclamation mark"
        }}>{`❗`}</span>{` If no tag is specified, `}</undefined><code parentName="p" {...{
        "className": "language-text"
      }}>{`latest`}</code>{` will be used.  `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`latest`}</code>{` is the tag associated with the most recent build published.  While always attempting to use the latest version may sound appealing, not denoting a specific version can lead to problems.  For example, often major version releases involves breaking changes breaking changes. Using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`latest`}</code>{` may make it harder to notice such releases.  `}</p>
    <p>{`If you want to avoid nasty surprises and emphasize replicability, it's better to use an explicit version.`}</p>
    <p>{`Let's download the following image which  defines a development environment for Python 3.7:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` pull uazhlt/python-playground:latest`}</code></pre></div>
    <p>{`Note that docker retrieves the image in `}<em parentName="p">{`layers`}</em>{`.  Each layer is sort of a miniature snapshot of the changes run up until some point in the image definition.  Future changes to an image (or a related image) may share layers.  In such cases, docker will avoid unecessarily downloading those layers if they're already present locally.`}</p>
    <h2 {...{
      "id": "running-a-container",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#running-a-container",
        "aria-label": "running a container permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Running a container`}</h2>
    <p>{`By default, containers launched using this image start up a jupyter notebook server.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "root",
              "data-host": "localhost"
            }}></span><span parentName="span" {...{
              "data-user": "root",
              "data-host": "localhost"
            }}></span><span parentName="span" {...{
              "data-user": "root",
              "data-host": "localhost"
            }}></span><span parentName="span" {...{
              "data-user": "root",
              "data-host": "localhost"
            }}></span><span parentName="span" {...{
              "data-user": "root",
              "data-host": "localhost"
            }}></span><span parentName="span" {...{
              "data-user": "root",
              "data-host": "localhost"
            }}></span><span parentName="span" {...{
              "data-user": "root",
              "data-host": "localhost"
            }}></span><span parentName="span" {...{
              "data-user": "root",
              "data-host": "localhost"
            }}></span><span parentName="span" {...{
              "data-user": "root",
              "data-host": "localhost"
            }}></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run -it uazhlt/python-playground:latest

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`I 09:52:02.260 NotebookApp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Writing notebook server cookie secret to /root/.local/share/jupyter/runtime/notebook_cookie_secret
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`W 09:52:02.564 NotebookApp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` All authentication is disabled.  Anyone `}<span parentName="code" {...{
            "className": "token function"
          }}>{`who`}</span>{` can connect to this server will be able to run code.
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`I 09:52:02.596 NotebookApp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`jupyter_nbextensions_configurator`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` enabled `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.4`}</span>{`.1
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`I 09:52:02.596 NotebookApp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Serving notebooks from `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`local`}</span>{` directory: /app
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`I 09:52:02.596 NotebookApp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Jupyter Notebook `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6.1`}</span>{`.0 is running at:
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`I 09:52:02.597 NotebookApp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` http://2e7741de1b55:9999/
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`I 09:52:02.598 NotebookApp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Use Control-C to stop this server and shut down all kernels `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`twice to skip confirmation`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`.`}</code></pre></div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-it`}</code>{` option used in the command above allows us to easily interact (ex. kill via `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ctrl+c`}</code>{`) with the container.`}</p>
    <p>{`The container will launch and claim its service is available on port`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{` 9999; however, navigating to localhost:9999 in your web browser will not work.  What's wrong?`}</p>
    <p>{`Let's kill this container using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ctrl-c`}</code>{` and make some adjustments...`}</p>
    <h3 {...{
      "id": "port-forwarding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#port-forwarding",
        "aria-label": "port forwarding permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Port forwarding`}</h3>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "question mark"
        }}>{`❓`}</span>{` A `}</undefined><strong parentName="p">{`port`}</strong>{` is a communication endpoint.  Different ports can be opened and set up to accept certain kinds of traffic (ex. webcam, http, ssh, etc.).`}</p>
    <p>{`The container in our example runs in an isolated network. In order to access the service described, we need to expose its 9999 port and `}<strong parentName="p">{`map`}</strong>{` it to a local port:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run -it -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8881`}</span>{`:9999 uazhlt/python-playground:latest`}</code></pre></div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`-p 8881:9999`}</code>{` defines a port mapping.  localhost:8881 will point to localhost:9999 in the container.  With this change, we're able to access the jupyter notebook server!`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "590px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/95130e2bad30ba81bd5f6c2ec91d4b5d/8711f/notebook-launch-success.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "22.2972972972973%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAlElEQVQY05WPXQuDMAxF/f9/cWPg06pprHXrh616hoKM7WkLHMK9JCG3uXdC27ZcrjdMb+ntQCf6ptdPLYrpBCMDRhxOFe89zjmMWBqAWmZCTOSyMNeFXFbqyt+1rSvNtm1M00TXCyIWVSWEQEqJmNLRvzn9GOMxu++LCOM4nh8WBv9kfERqrZRSfibnjLX2iL0ffwF+jDVSR2/i0AAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Jupyter notebook dashboard within running container",
              "title": "Jupyter notebook dashboard",
              "src": "/static/95130e2bad30ba81bd5f6c2ec91d4b5d/fcda8/notebook-launch-success.png",
              "srcSet": ["/static/95130e2bad30ba81bd5f6c2ec91d4b5d/12f09/notebook-launch-success.png 148w", "/static/95130e2bad30ba81bd5f6c2ec91d4b5d/e4a3f/notebook-launch-success.png 295w", "/static/95130e2bad30ba81bd5f6c2ec91d4b5d/fcda8/notebook-launch-success.png 590w", "/static/95130e2bad30ba81bd5f6c2ec91d4b5d/efc66/notebook-launch-success.png 885w", "/static/95130e2bad30ba81bd5f6c2ec91d4b5d/c83ae/notebook-launch-success.png 1180w", "/static/95130e2bad30ba81bd5f6c2ec91d4b5d/8711f/notebook-launch-success.png 2214w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Jupyter notebook dashboard`}</figcaption>{`
  `}</figure></p>
    <p>{`It looks like there aren't very many files in the container.  What if we wanted to a directory called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dataset`}</code>{`?`}</p>
    <p>{`Let's kill this container using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ctrl-c`}</code>{` and make some adjustments...`}</p>
    <h3 {...{
      "id": "volumes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#volumes",
        "aria-label": "volumes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Volumes`}</h3>
    <p>{`There are several ways to persist data when using containers, but we'll focus on `}<strong parentName="p">{`bind mounts`}</strong>{`.`}</p>
    <p><strong parentName="p">{`Bind mounts`}</strong>{` are a way of persisting and sharing data between host and container by mapping a location on the host file system to a location inside a container.`}</p>
    <p>{`Imagine we have a directory called  `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`~/my-data`}</code>{` containing a file called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dataset.csv`}</code>{` and we want to make the entire directory available inside of the container for reading and writing.  That way we have the output (ex. trained model, normalized data, etc.) `}<em parentName="p">{`locally`}</em>{` once the container has finished processing it.  We can use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-v`}</code>{` or `}<strong parentName="p">{`v`}</strong>{`olume option to achieve this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run -it -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8881`}</span>{`:9999 -v `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token environment constant"
            }}>{`$HOME`}</span>{`/my-data:/app/data"`}</span>{` uazhlt/python-playground:latest`}</code></pre></div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`/app/data`}</code>{` in the command above indicates that `}<em parentName="p">{`inside the container`}</em>{`, mounted `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`my-data`}</code>{` will be called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`data`}</code>{` and be found under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/app`}</code>{`.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "590px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/429846928a79ea4335035c548bbfabc0/8078c/notebook-launch-volume-mount-success-1.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "23.64864864864865%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAArElEQVQY05WOwW7CMBAF/f8fR8WBClVAqGuESGpXSRYnaxMPClTckGCk0Xun3WdWn2sWH0vWX1v29kD17R75zF1l2VSOjT1xqPbUTUN9dPw4h4FCjGfSZWKmwKO/SylgYoz4EGh8oP71/LUdEkdUEznnp6Z0T1Ul54RIT9u2GNXxdujYBPq+5yzCMAzMj15RRPDeY60lhIApZWJQRcZMnsr/9PKyM/PSrutueQWxaoHF0Rxr7AAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Jupyter notebook dashboard within running container showing volume mount",
              "title": "The data directory was successfully mounted",
              "src": "/static/429846928a79ea4335035c548bbfabc0/fcda8/notebook-launch-volume-mount-success-1.png",
              "srcSet": ["/static/429846928a79ea4335035c548bbfabc0/12f09/notebook-launch-volume-mount-success-1.png 148w", "/static/429846928a79ea4335035c548bbfabc0/e4a3f/notebook-launch-volume-mount-success-1.png 295w", "/static/429846928a79ea4335035c548bbfabc0/fcda8/notebook-launch-volume-mount-success-1.png 590w", "/static/429846928a79ea4335035c548bbfabc0/efc66/notebook-launch-volume-mount-success-1.png 885w", "/static/429846928a79ea4335035c548bbfabc0/c83ae/notebook-launch-volume-mount-success-1.png 1180w", "/static/429846928a79ea4335035c548bbfabc0/8078c/notebook-launch-volume-mount-success-1.png 2218w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`The data directory was successfully mounted`}</figcaption>{`
  `}</figure></p>
    <p>{`There's our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`data`}</code>{` directory in the running container.  If we look inside that directory, we can see `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dataset.csv`}</code>{` is present:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/697adaa626dc5d72da0235293dec9eb0/15171/notebook-launch-volume-mount-success-2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "22.972972972972975%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAuUlEQVQY05WP62rCQBCF9/3fzYKhBRVjjFFrahJJ9qZ7+8raIv5paQ98M2dgGM6Ionhl9jKneFtQVjs22+ZB+eSf5/WmZlnuWNUtx23N+2HPx+lI3TQIUkRrRUyJrFxD/PL/VUoJoZSiHy6czj3tuecySqS54ZwnhPAj3n935/Deo7VmmiaEtZZhGDi0PeM4oZXCWoPR+r70K8YgpaTrOqqqut8RMUbs9cbVhUfs/H76I1k5YU6XE38CJtSBXDHYZ/YAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Jupyter notebook dashboard within running container showing data contained in volume mount",
            "title": "Jupyter notebook dashboard within running container showing data contained in volume mount",
            "src": "/static/697adaa626dc5d72da0235293dec9eb0/fcda8/notebook-launch-volume-mount-success-2.png",
            "srcSet": ["/static/697adaa626dc5d72da0235293dec9eb0/12f09/notebook-launch-volume-mount-success-2.png 148w", "/static/697adaa626dc5d72da0235293dec9eb0/e4a3f/notebook-launch-volume-mount-success-2.png 295w", "/static/697adaa626dc5d72da0235293dec9eb0/fcda8/notebook-launch-volume-mount-success-2.png 590w", "/static/697adaa626dc5d72da0235293dec9eb0/efc66/notebook-launch-volume-mount-success-2.png 885w", "/static/697adaa626dc5d72da0235293dec9eb0/c83ae/notebook-launch-volume-mount-success-2.png 1180w", "/static/697adaa626dc5d72da0235293dec9eb0/15171/notebook-launch-volume-mount-success-2.png 2230w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "eyes"
        }}>{`👀`}</span>{` If you need access to additional directories, you can use multiple bind mounts:`}</undefined></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run -it -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8881`}</span>{`:9999 -v `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token environment constant"
            }}>{`$HOME`}</span>{`/my-data:/app/data"`}</span>{` -v `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token environment constant"
            }}>{`$HOME`}</span>{`/another/directory:/app/data-2"`}</span>{` uazhlt/python-playground:latest`}</code></pre></div>
    <h2 {...{
      "id": "viewing-a-list-of-running-containers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#viewing-a-list-of-running-containers",
        "aria-label": "viewing a list of running containers permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Viewing a list of running containers`}</h2>
    <p>{`To view a list of running containers, try `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker ps`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "root",
              "data-host": "localhost"
            }}></span><span parentName="span"></span><span parentName="span"></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span>{`
CONTAINER ID        IMAGE                                 COMMAND                  CREATED             STATUS              PORTS                    NAMES
2e7741de1b55        uazhlt/python-playground:latest   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/bin/bash /usr/loca…"`}</span>{`   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span>{` minutes ago       Up `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span>{` minutes        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.0`}</span>{`.0.0:8881-`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`9999`}</span>{`/tcp   crazy_fermi`}</code></pre></div>
    <p>{`The output shows us that we have one running container launched using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`uazhlt/python-playground:latest`}</code>{` image.  We can also see a summary of port mappings and the command the container executed when it was run.  The ID field is helpful if we need to interact with a running container (ex. killing (destroying) a container, connecting to the container, etc.).`}</p>
    <h2 {...{
      "id": "kill-a-running-container",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#kill-a-running-container",
        "aria-label": "kill a running container permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Kill a running container`}</h2>
    <p>{`If a container becomes unresponsive, you may need to kill it.  To do so, we'll need the ID of the container.  Open a tab in your terminal and run the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "root",
              "data-host": "localhost"
            }}></span><span parentName="span"></span><span parentName="span"></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span>{`
CONTAINER ID        IMAGE                                 COMMAND                  CREATED             STATUS              PORTS                    NAMES
2e7741de1b55        uazhlt/python-playground:latest   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/bin/bash /usr/loca…"`}</span>{`   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span>{` minutes ago       Up `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span>{` minutes        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.0`}</span>{`.0.0:8881-`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`9999`}</span>{`/tcp   crazy_fermi`}</code></pre></div>
    <p>{`In this example, we have only one running container.  Its ID is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`2e7741de1b55`}</code>{`.  To kill this container, you would run the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`kill`}</span>{` 2e7741de1b55`}</code></pre></div>
    <h2 {...{
      "id": "running-containers-with-non-default-commands",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#running-containers-with-non-default-commands",
        "aria-label": "running containers with non default commands permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Running containers with non-default commands`}</h2>
    <p>{`Images define default commands to be executed when a container is launched, but you can override these commands.  For example, let's launch a container running an iPython interpreter using the same docker image as before:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run -it uazhlt/python-playground:latest ipython`}</code></pre></div>
    <p>{`The command that follows the image name denotes what we want to run when launching.`}</p>
    <p>{`To exit, type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`exit`}</code>{`.`}</p>
    <h2 {...{
      "id": "removing-old-images-and-containers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#removing-old-images-and-containers",
        "aria-label": "removing old images and containers permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Removing old images and containers`}</h2>
    <p>{`Periodically, you may wish to remove old docker images to save space on your hard drive.  To do so, run the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` system prune --all`}</code></pre></div>
    <h1 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next steps`}</h1>
    <p>{`If you've folllowed along, hopefully it's become clear how a container can serve as a portable development environment.`}</p>
    <h2 {...{
      "id": "practice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#practice",
        "aria-label": "practice permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Practice`}</h2>
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": 800,
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/_ZS-xImYoQ4?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    <ul>
      <li parentName="ul">{`Practice what you've learned by launching an instance of `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`uazhlt/python-playground:latest`}</code>
        <ul parentName="li">
          <li parentName="ul">{`map your local port 7878 to 9999 in the container.  `}</li>
          <li parentName="ul">{`bind mount a directory of your choice under `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/app`}</code>{` (ex. `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/app/docker-data-practice`}</code>{`) and confirm that you can access it from the container.`}</li>
        </ul>
      </li>
    </ul>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`The image vs container distinction is closely related to that of classes vs instances in Object Oriented Programming.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      