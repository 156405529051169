import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import { HTMLTable } from '@blueprintjs/core';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": 800,
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/Pd9e5cCusK8?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    <p>{`In this unit, we'll learn about ...`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`tokens and selected attributes`}</p>
        <ul parentName="li">
          <li parentName="ul">{`part of speech tags`}</li>
          <li parentName="ul">{`canonical word forms (lemmas)`}</li>
          <li parentName="ul">{`named entity labels`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`cross-linguistic differences in defining tokens and their attributes`}</p>
        <ul parentName="li">
          <li parentName="ul">{`case study: English vs Japanese`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`text normalization`}</p>
      </li>
    </ul>
    <p>{`After reviewing, you'll apply this information to complete and submit a programming assignment.`}</p>
    <h1 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/tokens"
        }}>{`identify patterns for segmenting text into tokens`}</a></li>
      <li parentName="ul">{`identify cross-linguistics differences in defining tokens`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/named-entities"
        }}>{`describe named entities and their labeling formats`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/text-normalization#lemmatization"
        }}>{`identify lemma forms of words`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/parts-of-speech#determining-a-part-of-speech"
        }}>{`label words with part of speech tags`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/text-normalization#background"
        }}>{`explain the purpose of text normalization`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/text-normalization"
        }}>{`describe text normalization strategies`}</a></li>
      <li parentName="ul"><del parentName="li">{`implement a text normalization system`}</del></li>
      <li parentName="ul">{`implement a (proof-of-concept) rule-based part of speech tagger for one or more languages`}</li>
    </ul>
    <h1 {...{
      "id": "resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#resources",
        "aria-label": "resources permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Resources`}</h1>
    <h2 {...{
      "id": "videos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#videos",
        "aria-label": "videos permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Videos`}</h2>
    <p>{`Be sure to watch the videos in the playlist linked below:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/playlist?list=PL2acInhHkjsY_HQSKmz_pP3yVh_tlZQaH",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Unit playlist`}</a></li>
    </ul>
    <h2 {...{
      "id": "readings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#readings",
        "aria-label": "readings permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Readings`}</h2>
    <p>{`The excerpts linked below provide a nice overview of the tasks we preview in this unit:`}</p>
    <HTMLTable condensed striped mdxType="HTMLTable">
      <tbody>
        <tr>
            <td>
              <p>Unit 2</p>
            </td>
            <td>
              <p>Tokens and their attributes
              <ul>
                <li>normalization</li>
                <li>POS Tagging</li>
                <li>NER</li>
              </ul>
              </p>
            </td>
            <td>
              <p><a href="https://public.parsertongue.org/readings/slp3/2.pdf#page=13" target="_blank">Speech and Language Processing (<strong>2.4 only</strong>)</a></p>
              <p><a href="https://public.parsertongue.org/readings/slp3/8.pdf" target="_blank">Speech and Language Processing (<strong>8.0 - 8.2 only</strong>)</a></p>
              <p><a href="https://public.parsertongue.org/readings/slp3/18.pdf#page=2" target="_blank">Speech and Language Processing (<strong>18.1.0 - 18.1.1 only</strong>)</a></p>
              <p>Complete <a href="https://spacy.io/usage/spacy-101" target="_blank">https://spacy.io/usage/spacy-101</a></p>
            </td>
        </tr>
    </tbody>
    </HTMLTable>
    <h1 {...{
      "id": "learn",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#learn",
        "aria-label": "learn permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Learn`}</h1>
    <div {...{
      "className": "admonition admonition-note alert alert--secondary"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M6.3 5.69a.942.942 0 0 1-.28-.7c0-.28.09-.52.28-.7.19-.18.42-.28.7-.28.28 0 .52.09.7.28.18.19.28.42.28.7 0 .28-.09.52-.28.7a1 1 0 0 1-.7.3c-.28 0-.52-.11-.7-.3zM8 7.99c-.02-.25-.11-.48-.31-.69-.2-.19-.42-.3-.69-.31H6c-.27.02-.48.13-.69.31-.2.2-.3.44-.31.69h1v3c.02.27.11.5.31.69.2.2.42.31.69.31h1c.27 0 .48-.11.69-.31.2-.19.3-.42.31-.69H8V7.98v.01zM7 2.3c-3.14 0-5.7 2.54-5.7 5.68 0 3.14 2.56 5.7 5.7 5.7s5.7-2.55 5.7-5.7c0-3.15-2.56-5.69-5.7-5.69v.01zM7 .98c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.12-7-7 3.14-7 7-7z"
              }}></path></svg></span>{`NOTE `}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div"><em parentName="p">{`Complete these tutorials in the order listed.`}</em></p></div></div>
    <h2 {...{
      "id": "tokens-and-their-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#tokens-and-their-attributes",
        "aria-label": "tokens and their attributes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tokens and their attributes`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/tokens"
        }}>{`Introduction to tokens`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/parts-of-speech"
        }}>{`Part of speech (POS) tags`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/named-entities"
        }}>{`Named entity (NE) labels`}</a></li>
    </ul>
    <p>{`Let's explore token attributes using a popular NLP library:`}</p>
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": 800,
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/4mLzU0wSp5s?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    <h3 {...{
      "id": "cross-linguistic-differences",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#cross-linguistic-differences",
        "aria-label": "cross linguistic differences permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Cross-linguistic differences`}</h3>
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": 800,
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/usmBLRJDNQU?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    <p>{`Most of the examples we've looked at so far have related to English. Let's examine how linguistic features can vary across languages.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/lang/jpn"
        }}>{`Japanese`}</a></li>
    </ul>
    <h2 {...{
      "id": "text-normalization",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#text-normalization",
        "aria-label": "text normalization permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Text normalization`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/text-normalization"
        }}>{`Strategies for normalizing text`}</a></li>
    </ul>
    <h1 {...{
      "id": "practice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#practice",
        "aria-label": "practice permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Practice`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://arizona.openclass.ai/resource/assignment-5f2d0560f9163c2754bcaf63",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Review what you've learned`}</a></li>
    </ul>
    <p>{`Before you begin `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=0DZLAunu2kI",
        "target": "_self",
        "rel": "nofollow"
      }}>{`the programming assignment for this Unit`}</a>{`, ensure you can ...`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/parts-of-speech"
        }}>{`identify parts of speech`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/named-entities"
        }}>{`describe named entities`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/text-normalization"
        }}>{`describe text normalization strategies`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      