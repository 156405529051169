import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`Welcome to `}<strong parentName="p">{`Human Language Technology I`}</strong>{`!`}</p>
    <p>{`This fully online offering of the course has a compressed format with a full semester's worth of content delivered asynchronously over 7.5 weeks.`}</p>
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": 800,
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/v50OOsrCRC4?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    <h2 {...{
      "id": "important-links",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#important-links",
        "aria-label": "important links permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Important Links`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://d2l.arizona.edu",
            "target": "_self",
            "rel": "nofollow"
          }}>{`Course page`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://forum.parsertongue.org",
            "target": "_self",
            "rel": "nofollow"
          }}>{`Forum (by invitation)`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`See the `}<strong parentName="li">{`Getting Started`}</strong>{` section of the course D2L page for an invite link`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <a href="https://public.parsertongue.org/courses/hlt-1/syllabus.pdf" target="_blank">Syllabus</a>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "schedule"
          }}>{`Content overview`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://calendar.parsertongue.com",
            "target": "_self",
            "rel": "nofollow"
          }}>{`Appointments`}</a></p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">
        <a href="https://public.parsertongue.org/courses/hlt-1/audit-contract.pdf" target="_blank">Auditing agreement</a>
      </li>
    </ul>
    <h2 {...{
      "id": "description",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#description",
        "aria-label": "description permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Description`}</h2>
    <p>{`From the `}<a parentName="p" {...{
        "href": "https://uaccess.schedule.arizona.edu/psp/pubsaprd/UA_CATALOG/HRMS/h/?tab=DEFAULT",
        "target": "_self",
        "rel": "nofollow"
      }}>{`course catalog`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`This class serves as an introduction to human language technology (HLT), an emerging interdisciplinary field that encompasses most subdisciplines of linguistics, as well as computational linguistics, natural language processing, computer science, artificial intelligence, psychology, philosophy, mathematics, and statistics.
Content includes a combination of theoretical and applied topics such as (but not limited to) tokenization across languages, `}<span parentName="p" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.4306em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`n`}</span></span></span></span></span>{`-grams, word representations, basic probability theory, introductory programming, and version control.`}</p>
    </blockquote>
    <p><strong parentName="p">{`The main programming language used in the course will be Python (3.11).`}</strong></p>
    {
      /* __Please see the [course syllabus](https://public.parsertongue.org/courses/snlp-1/syllabus.pdf) for details.__ */
    }
    <h2 {...{
      "id": "course-objectives",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#course-objectives",
        "aria-label": "course objectives permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Course Objectives`}</h2>
    <p>{`In this course, we will ...`}</p>
    <ul>
      <li parentName="ul">{`cover fundamental concepts related to human language technology, such as ...`}
        <ul parentName="li">
          <li parentName="ul">{`an overview of human language technology applications`}</li>
          <li parentName="ul">{`tokens and their attributes`}</li>
          <li parentName="ul">{`text normalization techniques`}</li>
          <li parentName="ul">{`tokenization and regular expressions`}</li>
          <li parentName="ul">{`character and word `}<span parentName="li" {...{
              "className": "math math-inline"
            }}><span parentName="span" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-mathml"
                }}><math parentName="span" {...{
                    "xmlns": "http://www.w3.org/1998/Math/MathML"
                  }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                        "encoding": "application/x-tex"
                      }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "0.4306em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord mathnormal"
                    }}>{`n`}</span></span></span></span></span>{`-grams`}</li>
          <li parentName="ul">{`basics of probability theory`}</li>
          <li parentName="ul">{`representing words and documents as vectors`}</li>
          <li parentName="ul">{`vector-based comparisons`}</li>
          <li parentName="ul">{`regular expressions`}</li>
        </ul>
      </li>
      <li parentName="ul">{`foster technical skills, such as ...`}
        <ul parentName="li">
          <li parentName="ul">{`linux command line basics`}</li>
          <li parentName="ul">{`virtualization and Containerization technologies`}</li>
          <li parentName="ul">{`version control (ex. `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`Git`}</code>{`) and the feature branch workflow`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "locations-and-times",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#locations-and-times",
        "aria-label": "locations and times permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Locations and Times`}</h2>
    <p>{`This is an `}<strong parentName="p">{`online, asynchronous`}</strong>{` course.  Content is released in a staggered fashion via the course home page.`}</p>
    <ol>
      <li parentName="ol">{`office hours will be held virtually`}</li>
      <li parentName="ol">{`course lectures will be prerecorded and delivered asynchronously`}</li>
    </ol>
    <h2 {...{
      "id": "instructor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instructor",
        "aria-label": "instructor permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Instructor`}</h2>
    <p>{`Hi! My name is `}<a parentName="p" {...{
        "href": "/about"
      }}>{`Gus Hahn-Powell`}</a>{`.  I'll be your instructor for this course. `}</p>
    <p>{`I'm a computational linguist interested in ways we can use natural language processing to accelerate scientific discovery by mining millions of scholarly documents.`}</p>
    <p>{`In my free time, I enjoy climbing (mostly bouldering recently), reading/watching scifi & historical fiction, "fixing" things, communicating with cats, and playing video games (mostly indie stuff).`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}><a parentName="th" {...{
              "href": "https://linguistics.arizona.edu/person/gus-hahn-powell",
              "target": "_self",
              "rel": "nofollow"
            }}>{`Gus Hahn-Powell`}</a></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`hahnpowell AT arizona DOT edu`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Office Hours`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`See our course page on D2L`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Appointments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://calendar.parsertongue.com",
              "target": "_self",
              "rel": "nofollow"
            }}>{`https://calendar.parsertongue.com`}</a></td>
        </tr>
      </tbody>
    </table>
    {
      /* (mailto  :   hahnpowell  @   arizona   .   edu) */
    }
    {
      /* <mark>MW 10:30AM - 11:30AM</mark> and */
    }
    {
      /* | Office       | [Douglass 228](https://www.google.com/maps/place/Douglass+Building,+Tucson,+AZ+85719/@32.2313778,-110.9569038,17z/data=!3m1!4b1!4m5!3m4!1s0x86d67106ccbd3303:0xdad8b41998db7e51!8m2!3d32.2313733!4d-110.9547151)  | */
    }
    {
      /* ## Teaching Assistant (439)
      
      | Name         | [??](https://linguistics.arizona.edu/user/???)  |
      |--------------|---|
      | Email        | `?? AT email DOT arizona DOT edu`
      | Office Hours | _See our course page on D2L_  | */
    }
    <h2 {...{
      "id": "frequently-asked-questions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#frequently-asked-questions",
        "aria-label": "frequently asked questions permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Frequently Asked Questions`}</h2>
    <h3 {...{
      "id": "am-i-eligible-to-take-this-course",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#am-i-eligible-to-take-this-course",
        "aria-label": "am i eligible to take this course permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`Am I eligible to take this course?`}</em></h3>
    <p>{`While helpful, you don't need a background in linguistics and advanced math to take this course.  We'll cover the necessary pieces in class.  `}</p>
    <p>{`In order to take this class, `}<strong parentName="p">{`you must be comfortable programming`}</strong>{` (`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow">{`≥`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\geq`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.7719em",
                  "verticalAlign": "-0.136em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`≥`}</span></span></span></span></span>{` 1 semester's worth of programming coursework or equivalent experience).  Familiarity with Python and using and defining classes is ideal.  If you've never used Python, you'll need to learn the basics to complete the programming assignments (some basic exercises/tutorials will be provided).`}</p>
    {
      /* ### _What courses will help me prepare for this one?_
      
      | Student Level     |  Course                   |
      |-------------------|---------------------------|
      | Undergraduate     | [ISTA 355: Intro to NLP](https://studentcenter.arizona.edu/app/ui/public/ps/course-catalog)    |
      | Graduate          | [LING 529: HLT I](https://studentcenter.arizona.edu/app/ui/public/ps/course-catalog)           | */
    }
    <h3 {...{
      "id": "why-do-we-have-to-use-python",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#why-do-we-have-to-use-python",
        "aria-label": "why do we have to use python permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`Why do we have to use Python?`}</em></h3>
    <p>{`Python is an open-source programming language that is widely used in both academia and industry. It has some very useful and popular libraries for linear algebra and machine learning (Numpy, Tensorflow, PyTorch, MXNet, etc.).  We'll learn how to use some of these in this course. `}</p>
    <h3 {...{
      "id": "why-do-we-have-to-use-linux-and-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#why-do-we-have-to-use-linux-and-docker",
        "aria-label": "why do we have to use linux and docker permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`Why do we have to use Linux and Docker?`}</em></h3>
    <p>{`A great deal of time can be wasted trying to install and configure software. Most of these issues relate to differences in the starting configuration of users (operating systems, existing software installations, etc.).  `}</p>
    <p>{`While the focus of this class is on statistical natural language processing, we elect to take a bit of time at the beginning of the course to walk everyone through setting up a `}<strong parentName="p">{`uniform development environment`}</strong>{` known to be compatible with all assignments and tutorials used in this course. Adopting a uniform development environment helps us provide better technical support and a single set of up-to-date instructions. `}</p>
    <p>{`We feel it is important that the development environment be something freely available to everyone. There are many `}<strong parentName="p">{`free`}</strong>{` and `}<strong parentName="p">{`open source`}</strong>{` distributions of Linux that are lightweight and run on a variety of hardware configurations. We'll be using one of these distributions.`}</p>
    <p>{`As an added bonus, familiarizing yourself with the technologies we'll use in this course for things like version control and reproducibility may make you more productive in the future.`}</p>
    <h3 {...{
      "id": "whats-the-turnaround-time-for-grading-assignments",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#whats-the-turnaround-time-for-grading-assignments",
        "aria-label": "whats the turnaround time for grading assignments permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`What's the turnaround time for grading assignments?`}</em></h3>
    <p>{`Normally, we should have assignments graded and posted within a week.`}</p>
    <h3 {...{
      "id": "what-is-the-policy-on-due-dates-and-late-work",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-is-the-policy-on-due-dates-and-late-work",
        "aria-label": "what is the policy on due dates and late work permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`What is the policy on due dates and late work?`}</em></h3>
    <p>{`Make sure that you don’t wait until the last minute to start your work. `}<strong parentName="p">{`Late work will not be accepted.`}</strong></p>
    <h3 {...{
      "id": "where-should-i-ask-questions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#where-should-i-ask-questions",
        "aria-label": "where should i ask questions permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`Where should I ask questions?`}</em></h3>
    <p>{`If you have questions about the course, I'd prefer you share them through the course forum.  If it's something you don't want others to see, you can sending me a direct message (and consider including our TA(s)).`}</p>
    <p>{`If the forum is ever down and you need to reach me about the course, you can send me an email with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`[LING 529]`}</code>{` in the subject line (but think of email as a last resort).`}</p>
    <p>{`For planning purposes, please note that your instructor responds to posted questions `}<strong parentName="p">{`MWF from 9AM–5PM (MST)`}</strong>{`. Typically, you can expect a response within a day.`}</p>
    <h3 {...{
      "id": "where-can-i-find-information-on-what-assignments-are-due-each-week",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#where-can-i-find-information-on-what-assignments-are-due-each-week",
        "aria-label": "where can i find information on what assignments are due each week permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`Where can I find information on what assignments are due each week?`}</em></h3>
    <p>{`From the `}<strong parentName="p">{`Content`}</strong>{` link from the course D2L page, you will find different units referencing the material that you will complete each week of the course. You'll start with Unit 0 where you'll set up your development environment for the course. Due dates will be listed in each unit and in the course calendar.  To keep things predictable, all units have the same general structure.`}</p>
    <p>{`Each unit has links to lessons, videos, readings, and assignments/activities.
Be sure to check out the `}<strong parentName="p">{`Unit Overview`}</strong>{` link for each new unit. `}</p>
    <p>{`The course calendar (accessible from the nav bar) provides a good overview of all due dates. To help avoid missing important deadlines, I recommend that you enter all due dates in the calendar system (ex. Google Calendar, Microsoft Outlook, etc.) you are most comfortable using and set reminders.`}</p>
    <h3 {...{
      "id": "how-do-i-access-my-grades",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-do-i-access-my-grades",
        "aria-label": "how do i access my grades permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`How do I access my grades?`}</em></h3>
    <p>{`You will be able to access your grades via the D2L `}<strong parentName="p">{`Grades`}</strong>{` tab.`}</p>
    <h3 {...{
      "id": "how-is-attendance-taken",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-is-attendance-taken",
        "aria-label": "how is attendance taken permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`How is attendance taken?`}</em></h3>
    <p>{`We don't take attendance in this course.`}</p>
    <h3 {...{
      "id": "so-this-class-will-be-less-work-than-a-traditional-face-to-face-class",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#so-this-class-will-be-less-work-than-a-traditional-face-to-face-class",
        "aria-label": "so this class will be less work than a traditional face to face class permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`So this class will be less work than a traditional face-to-face class?`}</em></h3>
    <p>{`This is a common misconception that online classes are inherently easier than face-to-face classes.  In actuality, they are quite different in structure, pacing, and the way that you have to manage your time in order to be successful. While you will be meeting the same outcomes and learning the same material as a face-to-face class, the way that you participate changes. You lose the synchronous element (i.e., sitting in a chair in a room on campus with me and your classmates), but you gain a good deal more asynchronous reading, writing, listening, thinking, and responding.`}</p>
    <p>{`Note too that this is a 3-unit 7.5-week class. That means that the expectations for content and workload are the same as a 3-unit 15-week class, but in half the time.`}</p>
    <h3 {...{
      "id": "do-i-need-any-special-equipment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#do-i-need-any-special-equipment",
        "aria-label": "do i need any special equipment permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`Do I need any special equipment?`}</em></h3>
    <p>{`The assignments are designed to be run using docker in a Linux environment (the first unit guides you through configuring your development environment).  While it may be possible to run the assignments on Windows or another OS with some modifications, `}<strong parentName="p">{`technical support will only be provided for the Linux environment introduced in the first unit`}</strong>{`.  If you decide to use a virtual machine (as demonstrated in the first unit), we recommend you run this on a system with a `}<strong parentName="p">{`minimum of 8GB of RAM and 50GB`}</strong>{` of storage.  If you opt to install Linux natively (not required), you can probably get by with half the amount of RAM.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      