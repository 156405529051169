import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`This tutorial introduces you to the basics of the Linux command line.`}</p>
    <h2 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h2>
    <p>{`After reading this tutorial, you should be comfortable...`}</p>
    <ul>
      <li parentName="ul">{`navigating your filesystem from the command line`}</li>
      <li parentName="ul">{`creating directories from the command line`}</li>
      <li parentName="ul">{`moving directories and files`}</li>
      <li parentName="ul">{`copying directories and files`}</li>
      <li parentName="ul">{`removing files`}</li>
      <li parentName="ul">{`inspecting the value of environment variables`}</li>
      <li parentName="ul">{`listing files in a directory`}</li>
      <li parentName="ul">{`navigating documentation`}</li>
    </ul>
    <h2 {...{
      "id": "prerequisites",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#prerequisites",
        "aria-label": "prerequisites permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prerequisites`}</h2>
    <p>{`This tutorials assumes that ...`}</p>
    <ul>
      <li parentName="ul">{`You have a working `}<a parentName="li" {...{
          "href": "/tutorials/virtualbox-install-ubuntu"
        }}>{`Ubuntu 20.04 LTS installation`}</a></li>
    </ul>
    <h1 {...{
      "id": "background",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#background",
        "aria-label": "background permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background`}</h1>
    <h2 {...{
      "id": "what-is-the-command-line-interface--what-is-a-shell",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-is-the-command-line-interface--what-is-a-shell",
        "aria-label": "what is the command line interface  what is a shell permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h2">{`What is the command line interface?  What is a shell?`}</em></h2>
    <p>{`A command line interface (CLI) is a way of interacting with a program using only text-based instructions (no GUI).  Here we're focusing on the command line interace accessed through your terminal that talks to the operating system.  In our case, the program we're interacting with is a `}<em parentName="p">{`shell`}</em>{` (ex. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bash`}</code>{`) which is an intepreter`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` for a scripting language that supports statements such as for loops, conditionals, etc. and allows a user to invoke other languages and execute commands`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{`.`}</p>
    <h2 {...{
      "id": "why-should-i-learn-this-i-already-know-how-to-program-in-x",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why-should-i-learn-this-i-already-know-how-to-program-in-x",
        "aria-label": "why should i learn this i already know how to program in x permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h2">{`Why should I learn this? I already know how to program in x`}</em></h2>
    <p>{`There are a number of tasks that are much shorter (fewer lines of code) and faster (in terms of execution) to write as a shell script than in a language such as Python`}<sup parentName="p" {...{
        "id": "fnref-3"
      }}><a parentName="sup" {...{
          "href": "#fn-3",
          "className": "footnote-ref"
        }}>{`3`}</a></sup>{`.  It's a matter of using the right tool for the job.  You can whack at nails with a wrench, but it's usually more efficient to use a hammer.`}</p>
    <p>{`While it might seem esoteric, Shell scripting is ...`}</p>
    <blockquote>
      <p parentName="blockquote">{`[...]`}{` `}<em parentName="p">{`an elegant weapon for a more civilized age`}</em></p>
    </blockquote>
    <center>
      <a parentName="center" {...{
        "href": "https://en.wikipedia.org/wiki/File:Ben_Kenobi.png"
      }}>
        <img parentName="a" {...{
          "src": "https://upload.wikimedia.org/wikipedia/en/3/32/Ben_Kenobi.png",
          "alt": "Fair use"
        }}></img>
      </a>
    </center>
    <h1 {...{
      "id": "linux-command-line-basics",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#linux-command-line-basics",
        "aria-label": "linux command line basics permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Linux command line basics`}</h1>
    <p>{`Here we'll cover some of the essential commands that you're likely to use again and again.`}</p>
    <h2 {...{
      "id": "commands-and-options",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#commands-and-options",
        "aria-label": "commands and options permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`commands and options`}</h2>
    <p>{`Most commands can be modified with one or more options or flags.  These are usually denoted with a) `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`--<word>`}</code>{` (ex. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`--version`}</code>{` to display the installed version of some command) or b) a single `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-`}</code>{` and a letter (ex. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-r`}</code>{`) which serves as a mnemonic for its purpose.  For example, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-r`}</code>{` typically refers to `}<em parentName="p">{`recurse`}</em>{` or a `}<em parentName="p">{`recursive`}</em>{` operation. Often, you will find it useful to apply more than one option at a time.`}</p>
    <h2 {...{
      "id": "try-it-out",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#try-it-out",
        "aria-label": "try it out permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Try it out`}</h2>
    <p>{`Before reading further, `}<a parentName="p" {...{
        "href": "/tutorials/virtualbox-install-ubuntu/#how-do-i-open-the-terminal"
      }}>{`open your terminal`}</a>{` so that you can follow along by trying the commands yourself.`}</p>
    <h2 {...{
      "id": "listing-files-and-directories",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#listing-files-and-directories",
        "aria-label": "listing files and directories permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Listing files and directories`}</h2>
    <p>{`You can list all files and directories at some location using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ls`}</code>{` command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span><span parentName="span"></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span>{` Downloads
important-doc.pdf data.csv readme-old.md`}</code></pre></div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`ls`}</code>{` by itself will list all files and directories in the current directory.`}</p>
    <p>{`Let's list details (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-l`}</code>{`), reverse sort by time modified (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-tr`}</code>{`), and include file size in a human readable form (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-h`}</code>{`):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span><span parentName="span"></span><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span>{` -ltrh Downloads
rw-rw-r-- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` hahnpowell hahnpowell  3G Feb `}<span parentName="code" {...{
            "className": "token number"
          }}>{`19`}</span>{`  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2020`}</span>{` data.csv
rw-rw-r-- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` hahnpowell hahnpowell  100K Jan `}<span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span>{`  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2019`}</span>{` important-doc.pdf
rw-rw-r-- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` hahnpowell hahnpowell  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{`.8K November `}<span parentName="code" {...{
            "className": "token number"
          }}>{`30`}</span>{`  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2015`}</span>{` readme-old.md`}</code></pre></div>
    <p>{`As written, the command above won't reveal `}<em parentName="p">{`hidden`}</em>{` files (i.e., files with a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.`}</code>{` prefix) such as config files.  We need the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-a`}</code>{` flag for that:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span><span parentName="span"></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span>{` -a ~/
.bashrc`}</code></pre></div>
    <p><em parentName="p">{`NOTE: `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`ls`}</code>{` by itself will list all files and directories in the current directory.`}</em></p>
    <h2 {...{
      "id": "create-a-directory",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#create-a-directory",
        "aria-label": "create a directory permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Create a directory`}</h2>
    <p>{`You can create a directory using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mkdir`}</code>{` command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` ~/repos`}</code></pre></div>
    <p>{`That will create a directory called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`repos`}</code>{` within your home directory.`}</p>
    <h3 {...{
      "id": "create-a-series-of-directories-all-at-once",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#create-a-series-of-directories-all-at-once",
        "aria-label": "create a series of directories all at once permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Create a series of directories all at once`}</h3>
    <p>{`What if you wanted to create series of nested directories (for example, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`repos/tutorials/shell-basics`}</code>{`)?  All we need is the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-p`}</code>{` option:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p ~/repos/tutorials/shell-basics`}</code></pre></div>
    <h2 {...{
      "id": "changing-directories",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#changing-directories",
        "aria-label": "changing directories permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Changing directories`}</h2>
    <p>{`We can move to a new directory using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cd`}</code>{` command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span></span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` /var/log`}</code></pre></div>
    <p>{`If you keep wandering around the file system, you may get lost.  The two commands that follow can help you get back to familiar territory...`}</p>
    <h3 {...{
      "id": "pwd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#pwd",
        "aria-label": "pwd permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><code parentName="h3" {...{
        "className": "language-text"
      }}>{`pwd`}</code></h3>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pwd`}</code>{` stands for `}<strong parentName="p">{`p`}</strong>{`rint `}<strong parentName="p">{`w`}</strong>{`orking `}<strong parentName="p">{`d`}</strong>{`irectory.  Invoking it will print the absolute path to the current directory:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span><span parentName="span"></span></span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`pwd`}</span>{`
/var/log`}</code></pre></div>
    <h3 {...{
      "id": "tilde-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#tilde-",
        "aria-label": "tilde  permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tilde `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`~`}</code></h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`pwd`}</code>{` can help you get your bearings, but what about returning home?  `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`~`}</code>{` is shorthand for the current user's home directory:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span><span parentName="span"></span></span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` ~/
/home/hahnpowell`}</code></pre></div>
    <p><em parentName="p">{`NOTE: Unless your username happens to be `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`hahnpowell`}</code>{`, your output will be different`}</em></p>
    <h2 {...{
      "id": "printing-text",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#printing-text",
        "aria-label": "printing text permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Printing text`}</h2>
    <p>{`We can print text to the terminal using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`echo`}</code>{` command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span><span parentName="span"></span></span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Howdy!"`}</span>{`
Howdy`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span></code></pre></div>
    <h2 {...{
      "id": "using-environment-variables",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-environment-variables",
        "aria-label": "using environment variables permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using environment variables`}</h2>
    <p>{`We can define variables and assign values in the shell.  Let's imagine we have a long  path (ex. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`~/repos/tutorials/shell-basics`}</code>{`) that we need to use again and again.  Let's assign it to the variable `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`LONG_PATH`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span></span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`LONG_PATH`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=~`}</span>{`/repos/tutorials/shell-basics`}</code></pre></div>
    <p>{`if we want to print the `}<em parentName="p">{`value`}</em>{` of that variable, we can use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`echo`}</code>{` and prefix a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`$`}</code>{` to the name of the variable:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span><span parentName="span"></span></span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$LONG_PATH`}</span>{`
/home/hahnpowell/repos/tutorials/shell-basics`}</code></pre></div>
    <p>{`Did you notice that the shell `}<em parentName="p">{`expanded`}</em>{` `}<sup parentName="p" {...{
        "id": "fnref-4"
      }}><a parentName="sup" {...{
          "href": "#fn-4",
          "className": "footnote-ref"
        }}>{`4`}</a></sup>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`~`}</code>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/home/hahnpowell`}</code>{`?  `}</p>
    <p>{`This is a very powerful feature of the shell.  It means we can store arbitrary commands in variables and execute them just by invoking the value of the variable.`}</p>
    <p>{`Applying this idea, we can move to that directory:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span><span parentName="span"></span></span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$LONG_PATH`}</span>{`
/home/hahnpowell/repos/tutorials/shell-basics`}</code></pre></div>
    <h2 {...{
      "id": "creating-files",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#creating-files",
        "aria-label": "creating files permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating files`}</h2>
    <h3 {...{
      "id": "empty-files",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#empty-files",
        "aria-label": "empty files permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Empty files`}</h3>
    <p>{`What if we wanted to create an empty file called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`README.md`}</code>{` in our home directory (maybe as a reminder so that we can later fill it out using using our favorite text editor)?  In such cases, we can use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`touch`}</code>{` command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`touch`}</span>{` ~/README.md`}</code></pre></div>
    <h3 {...{
      "id": "initializing-files-with-text",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#initializing-files-with-text",
        "aria-label": "initializing files with text permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Initializing files with text`}</h3>
    <p>{`We won't introduce CLI text editors like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vim`}</code>{` in this tutorial.  Even so, we can initialize files with some content:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span></span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"I love the shell"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$LONG_PATH`}</span>{`/README.md`}</code></pre></div>
    <ul>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "language-text"
          }}>{`>`}</code>{` means `}<em parentName="p">{`take the output of the thing on the `}<strong parentName="em">{`left`}</strong>{` and write to the thing on the `}<strong parentName="em">{`right`}</strong>{` overwriting whatever is there`}</em>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "language-text"
          }}>{`>>`}</code>{` is used to `}<em parentName="p">{`append`}</em>{`.`}</p>
      </li>
    </ul>
    <p>{`Did you notice we combined a variable with another string to create a file path (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`$LONG_PATH/README.md`}</code>{`)?  The shell really is useful!`}</p>
    <h3 {...{
      "id": "viewing-the-contents-of-files",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#viewing-the-contents-of-files",
        "aria-label": "viewing the contents of files permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Viewing the contents of files`}</h3>
    <p>{`If we want to see the contents of a file all at once, we can use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cat`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span><span parentName="span"></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`cat`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$LONG_PATH`}</span>{`/README.md
I love the shell`}</code></pre></div>
    <p>{`If it's a large file, we might want to use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`less`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`less`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$LONG_PATH`}</span>{`/README.md`}</code></pre></div>
    <p>{`You can use the arrow keys to move up and down.  type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`q`}</code>{` to exit.  You can search for string by typing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/`}</code>{` followed by the search term.  Move forward a match using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`n`}</code>{` (for `}<strong parentName="p">{`n`}</strong>{`ext) and backward using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`p`}</code>{` (for `}<strong parentName="p">{`p`}</strong>{`revious).`}</p>
    <h2 {...{
      "id": "moving-data",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#moving-data",
        "aria-label": "moving data permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Moving data`}</h2>
    <p>{`We can move files using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mv`}</code>{` command:`}</p>
    <p>{`Let's move `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`~/repos/tutorials/shell-basics/README.md`}</code>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`~/repos/tutorials`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`mv`}</span>{` ~/repos/tutorials/shell-basics/README.md ~/repos/tutorials/`}</code></pre></div>
    <h2 {...{
      "id": "renaming-files",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#renaming-files",
        "aria-label": "renaming files permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Renaming files`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`mv`}</code>{` can also be used to rename files.  Let's rename `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`~/repos/tutorials/README.md`}</code>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`~/repos/tutorials/OVERVIEW.md`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`mv`}</span>{` ~/repos/tutorials/README.md ~/repos/tutorials/OVERVIEW.md`}</code></pre></div>
    <h2 {...{
      "id": "copying-data",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#copying-data",
        "aria-label": "copying data permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Copying data`}</h2>
    <p>{`We can copy things use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cp`}</code>{` command.`}</p>
    <h3 {...{
      "id": "files",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#files",
        "aria-label": "files permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Files`}</h3>
    <p>{`Let's `}<em parentName="p">{`copy`}</em>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`~/repos/tutorials/OVERVIEW.md`}</code>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`~/repos/tutorials/OVERVIEW-copy.md`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`cp`}</span>{` ~/repos/tutorials/OVERVIEW.md ~/repos/tutorials/OVERVIEW-copy.md`}</code></pre></div>
    <h3 {...{
      "id": "directories",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#directories",
        "aria-label": "directories permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Directories`}</h3>
    <p>{`If you want to copy a directory, you need to use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-r`}</code>{` flag to `}<strong parentName="p">{`r`}</strong>{`ecursively copy its contents.`}</p>
    <h2 {...{
      "id": "deleting-data",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#deleting-data",
        "aria-label": "deleting data permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deleting data`}</h2>
    <p>{`You can delete data using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`rm`}</code>{` command. `}</p>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "fire"
        }}>{`🔥`}</span>{` `}</undefined><strong parentName="p">{`Be `}<em parentName="strong">{`very`}</em>{` careful with this command.  Deleting with `}<code parentName="strong" {...{
          "className": "language-text"
        }}>{`rm`}</code>{` skips the trash can.  Things are obliterated.`}</strong>{` `}</p>
    <h3 {...{
      "id": "files-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#files-1",
        "aria-label": "files 1 permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Files`}</h3>
    <p>{`Let's remove `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`~/repos/tutorials/OVERVIEW-copy.md`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`rm`}</span>{` ~/repos/tutorials/OVERVIEW-copy.md`}</code></pre></div>
    <h3 {...{
      "id": "directories-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#directories-1",
        "aria-label": "directories 1 permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Directories`}</h3>
    <p>{`To remove a directory with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`rm`}</code>{`, you need to use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-r`}</code>{` option.`}</p>
    <h2 {...{
      "id": "viewing-documentation-man",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#viewing-documentation-man",
        "aria-label": "viewing documentation man permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Viewing documentation (`}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`man`}</code>{`)`}</h2>
    <p>{`There are many options to each command.  It's easy to forget what they all do.  If you're not sure what a command does or what options are available, you can view its manual page using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`man`}</code>{` command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`man`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span></code></pre></div>
    <p>{`The navigation controls are the same as those for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`less`}</code>{`.`}</p>
    <h1 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next steps`}</h1>
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": 800,
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/Vkpdw47YtPE?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Practice what you've learned`}</strong>{` `}
        <ol parentName="li">
          <li parentName="ol">{`create a file called `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`learning-the-shell.txt`}</code>{` in the `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`~/shell-practice/really/long/path`}</code>{`.  `}</li>
          <li parentName="ol">{`Next, move it to `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`~/shell-practice/really/long`}</code>{` and rename the file to `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`l-t-s.txt`}</code>{`.  `}</li>
          <li parentName="ol">{`Finally, delete the `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`~/shell-practice/really/long/path`}</code>{` directory.`}</li>
        </ol>
      </li>
    </ul>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`A program that is able to execute instructions on-the-fly without compiling.  See `}<a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Interpreter_(computing)",
            "target": "_self",
            "rel": "nofollow"
          }}>{`https://en.wikipedia.org/wiki/Interpreter_(computing)`}</a>{`.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/List_of_Unix_commands",
            "target": "_self",
            "rel": "nofollow"
          }}>{`https://en.wikipedia.org/wiki/List_of_Unix_commands`}</a><a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-3"
        }}>{`Compare the succintness of the techniques covered in this tutorial with the amount of code needed when using Python's `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`shutil`}</code>{` or `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`subprocess`}</code>{`.`}<a parentName="li" {...{
            "href": "#fnref-3",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-4"
        }}>{`See `}<a parentName="li" {...{
            "href": "https://www.gnu.org/software/bash/manual/html_node/Shell-Parameter-Expansion.html#Shell-Parameter-Expansion",
            "target": "_self",
            "rel": "nofollow"
          }}>{`https://www.gnu.org/software/bash/manual/html_node/Shell-Parameter-Expansion.html#Shell-Parameter-Expansion`}</a><a parentName="li" {...{
            "href": "#fnref-4",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      