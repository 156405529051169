import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`This tutorial provides instructions for installing Ubuntu 20.04 LTS using VirtualBox.`}</p>
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": 800,
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/r69nbRM28sI?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    <h2 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h2>
    <ul>
      <li parentName="ul">{`Produce a working installation (virtual machine) of Ubuntu 20.04 LTS`}</li>
    </ul>
    <h2 {...{
      "id": "prerequisites",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#prerequisites",
        "aria-label": "prerequisites permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prerequisites`}</h2>
    <ul>
      <li parentName="ul">{`A computer with 6GB+ of RAM `}<a parentName="li" {...{
          "href": "https://www.virtualbox.org/manual/ch01.html#hostossupport",
          "target": "_self",
          "rel": "nofollow"
        }}>{`running an OS that is compatible with VirtualBox`}</a>{` and an AMD64 CPU.`}</li>
    </ul>
    <h3 {...{
      "id": "important-note",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#important-note",
        "aria-label": "important note permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Important note`}</h3>
    <div {...{
      "className": "admonition admonition-note alert alert--secondary"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M6.3 5.69a.942.942 0 0 1-.28-.7c0-.28.09-.52.28-.7.19-.18.42-.28.7-.28.28 0 .52.09.7.28.18.19.28.42.28.7 0 .28-.09.52-.28.7a1 1 0 0 1-.7.3c-.28 0-.52-.11-.7-.3zM8 7.99c-.02-.25-.11-.48-.31-.69-.2-.19-.42-.3-.69-.31H6c-.27.02-.48.13-.69.31-.2.2-.3.44-.31.69h1v3c.02.27.11.5.31.69.2.2.42.31.69.31h1c.27 0 .48-.11.69-.31.2-.19.3-.42.31-.69H8V7.98v.01zM7 2.3c-3.14 0-5.7 2.54-5.7 5.68 0 3.14 2.56 5.7 5.7 5.7s5.7-2.55 5.7-5.7c0-3.15-2.56-5.69-5.7-5.69v.01zM7 .98c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.12-7-7 3.14-7 7-7z"
              }}></path></svg></span>{`A note to ARM64 users `}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`If you're using a machine with an ARM64 CPU (ex. a Mac M1), you won't be able to run VirtualBox. `}<strong parentName="p">{`Windows users should install Ubuntu`}</strong>{` (either via VirtualBox or natively `}<a parentName="p" {...{
            "href": "https://ubuntu.com/tutorials/install-ubuntu-desktop#6-type-of-installation",
            "target": "_self",
            "rel": "nofollow"
          }}>{`on a separate partition`}</a>{`). `}</p></div></div>
    <h1 {...{
      "id": "background",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#background",
        "aria-label": "background permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background`}</h1>
    <p>{`This section provides a bit of background on the key technologies involved in this tutorial.`}</p>
    <h2 {...{
      "id": "whats-linux",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#whats-linux",
        "aria-label": "whats linux permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h2">{`What's Linux?`}</em></h2>
    <p>{`Linux is a common core or `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Kernel_(operating_system)",
        "target": "_self",
        "rel": "nofollow"
      }}><em parentName="a">{`kernel`}</em></a>{` shared by a class of `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Operating_system",
        "target": "_self",
        "rel": "nofollow"
      }}>{`operating systems`}</a>{` (OS).  Commonly, however, people simply use `}<em parentName="p">{`Linux`}</em>{` to refer to operating systems that use the Linux kernel.`}</p>
    <h3 {...{
      "id": "historylineage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#historylineage",
        "aria-label": "historylineage permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`History/Lineage`}</h3>
    <p>{`Linux was developed by `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Linus_Torvalds",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Linus Torvalds`}</a>{` in the 1990s as an extension to the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/GNU",
        "target": "_self",
        "rel": "nofollow"
      }}>{`GNU operating system`}</a>{` (which in turn was a rewrite of AT&T's Unix OS). `}</p>
    <h2 {...{
      "id": "whats-virtualbox",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#whats-virtualbox",
        "aria-label": "whats virtualbox permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h2">{`What's VirtualBox?`}</em></h2>
    <p>{`VirtualBox is a free, `}<a parentName="p" {...{
        "href": "https://www.virtualbox.org/manual/ch01.html#hostossupport",
        "target": "_self",
        "rel": "nofollow"
      }}>{`cross-platform (AMD64 CPUs running MacOS, Windows, Linux, etc.)`}</a>{` application for software virtualization that allows you to run multiple `}<em parentName="p">{`guest`}</em>{` operating systems (virtual machines) at the same time on a single `}<em parentName="p">{`host`}</em>{` OS (the OS of the physical machine on which VirtualBox is running).  If something goes wrong with a virtual machine (for example, perhaps you're experimenting with a completely new OS), you can safely delete or replace it without damaging your host OS.`}</p>
    <h2 {...{
      "id": "why-use-linux",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why-use-linux",
        "aria-label": "why use linux permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h2">{`Why use Linux?`}</em></h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Highly customizable`}</strong>{`: Unlike operating systems such as Window or MacOS, the `}<em parentName="p">{`core`}</em>{` of a Linux OS is open-source software (OSS) making it highly customizable.  `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Democratized access`}</strong>{`: Most Linux distributions are free and some Linux distributions are `}<em parentName="p">{`completely`}</em>{` open source!`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Fast and lightweight`}</strong>{`: Linux distributions can be barebones. Some have no graphical interface at all (these are typically labeled as server distributions). As such, they typically run faster than commercial alternatives.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Common in the developer world`}</strong>{`: While you may not see many computers sold with a Linux distribution preinstalled at large retailers, Linux distributions are quite common.  `}<a parentName="p" {...{
            "href": "https://en.wikipedia.org/wiki/Linux-powered_device",
            "target": "_self",
            "rel": "nofollow"
          }}>{`Many devices`}</a>{` such as TVs and e-readers are powered by Linux.  `}</p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.omgubuntu.co.uk/2017/11/linux-now-powers-100-worlds-top-500-supercomputers",
              "target": "_self",
              "rel": "nofollow"
            }}>{`Most (if not all) of the world's supercomputers run a version of Linux`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://phys.org/news/2013-05-international-space-station-laptop-migration.html",
              "target": "_self",
              "rel": "nofollow"
            }}>{`Linux has been adopted by major space programs`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Red_Star_OS",
              "target": "_self",
              "rel": "nofollow"
            }}>{`North Korea has its own Linux distribution`}</a></li>
          <li parentName="ul">{`Linux is fairly ubiquitious on large cloud computing providers `}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/List_of_Linux_adopters",
              "target": "_self",
              "rel": "nofollow"
            }}>{`Here are some of the other places Linux is used`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "why-is-this-necessary",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why-is-this-necessary",
        "aria-label": "why is this necessary permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h2">{`Why is this necessary?`}</em></h2>
    <p>{`Many of the tools we will use were developed on Linux-based OS.  `}<strong parentName="p">{`This course uses a Linux-based development environment`}</strong>{`. For the sake of uniformity, we'll be using `}<a parentName="p" {...{
        "href": "https://ubuntu.com/about",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Ubuntu`}</a>{` 20.04 LTS which is a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Debian",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Debian-based Linux distribution`}</a>{`.`}</p>
    <h1 {...{
      "id": "prerequisites-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#prerequisites-1",
        "aria-label": "prerequisites 1 permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prerequisites`}</h1>
    <p>{`Before starting, ensure that ...`}</p>
    <ul>
      <li parentName="ul">{`you have a machine with at least 8GB of RAM and 50GB of free space`}</li>
    </ul>
    <h2 {...{
      "id": "part-1-installing-virtualbox",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#part-1-installing-virtualbox",
        "aria-label": "part 1 installing virtualbox permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Part 1: Installing VirtualBox`}</h2>
    <p>{`Follow `}<a parentName="p" {...{
        "href": "https://www.virtualbox.org/wiki/Downloads",
        "target": "_self",
        "rel": "nofollow"
      }}>{`this link`}</a>{` and install VirtualBox according to the instructions provided for your operating system (OS).`}</p>
    <h3 {...{
      "id": "checking-the-version-of-virtualbox",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#checking-the-version-of-virtualbox",
        "aria-label": "checking the version of virtualbox permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Checking the version of VirtualBox`}</h3>
    <ol>
      <li parentName="ol">{`Click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`VirtualBox`}</code>{` `}{`→`}{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`About VirtualBox`}</code></li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/23986bb2b9f96977ae35c706d036b806/6295b/virtualbox-home.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.21621621621622%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAACCUlEQVQoz12QXU9SAQCGz8/qrvvWXT+gu7KlUNFQJn4sJopfUGo5G2aZtbW25uhz5VqBB+LsAAHOUjrnqKh8HlA5H7Pa0+CiCy+evXfv++4RLgS3uRTMctG1zPmeFc51LXPZ85BB/yxOzwTdt31cc/nouuXj6s07OPpGudEXwNk3hrN3DEfvGD1uPw73KA63H2Focp7phecEH60yGFrBMTxH8NlnCqpGYTPNzkYUOZsktrnP+2wKafsdO+ouivILVVU7ube3i6apqKqC4JuaZ3wmjHckiHswgKt/hCcv3/KzoCElv5HL5ZAkifzGBqVymVK5RL1e71AqldB1na2tbYrFfcrlCsLoRIjA1D0Ghv14BnwdIpHXHB4cIMYTiGKcaDRKIhHneyaDLMuk02mSySTNZpNcPs+k30O9usvvUxvBH5hiOjSLd8iHy92PxzvMauQNxeIeoviVRCKGGF8nFot1ikVRJJVKkc1maTZ0rnc7ibxaIhce58eHpwjzC2HCi4+Ze7DAdGiG4N1ZPq2tcbBfRpZVJEkhlcqTyaQ7z9oKNE3DtiyOT0xeLAY4/OIldv8KH5cCCIqicJa23EqlTr1mU61Y6PoxjYbe8dV2V61WOTo6wjAtrFYDXVmnIEdo6DWE9tpZFEWlVqth2wameYJpttP8j2VZGIZBq9WiZZgY9h/s07+YpsU/QswI4sQ6FSkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "virtualbox-home",
            "title": "virtualbox-home",
            "src": "/static/23986bb2b9f96977ae35c706d036b806/fcda8/virtualbox-home.png",
            "srcSet": ["/static/23986bb2b9f96977ae35c706d036b806/12f09/virtualbox-home.png 148w", "/static/23986bb2b9f96977ae35c706d036b806/e4a3f/virtualbox-home.png 295w", "/static/23986bb2b9f96977ae35c706d036b806/fcda8/virtualbox-home.png 590w", "/static/23986bb2b9f96977ae35c706d036b806/efc66/virtualbox-home.png 885w", "/static/23986bb2b9f96977ae35c706d036b806/6295b/virtualbox-home.png 919w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`You can check the version in the window that launches.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2fd3192445d3252ea512c12fa738c1d7/bce72/virtualbox-version.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.37837837837837%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD8ElEQVQ4y5WSf0zUdRjHvy7nGtPZJo1qEU4ks6U5KBOoSB2m0VZqzZBoKcUNqglKMxUVBgEnotg1QkCzgUgEQSAcJ6CEKCCCKCBwByICX49TOe57B3ei932144dbW//03t6f53m2z/Pj/fk8giqtmKO/VaHKPs/POdWositJy7tMRmEzmSXtXIlPQRuxA11CArrYWMTkZESlEjE+HjEubooO38GkJIS5bycxzzeBeT4/4uSjxOmdVF4NKqCsSU9ReTfiSj/kpUsYFwSs07TNmsVDd3dsHi9jXeTOQ1dXHrq5YZszB8FljZLn/ZNxXp3MJ/squdz+AN2gmSnYp8yYBGkqWPMuNkdBZ2esHwQgr1gGS9yxvrKUcU8vrPPnI7ywLpUFfgk87ZPC/pM3J/N7ReNMKZBleoYkuh7DH1l/YxBmY5v9FDg/Q8+ChTS4LEMWBBAEHgkCwvIvz+K1vZCggxqijpQTpTyN4kAmcRllXOkYoLB2kNeC/+LNEDW+P7RSt8h7MrnJ1Yv14Wp897YSvzGR0hWb2BMQg+C26QwnS3T09Q/xzcF01n4RQ9iBDNZvi+HQrxWcVPfgG1bGG6Ea/KOvERxeQvSWY3huL+fZjb/jFaJmbVw3HuGXSC7uQpjrm4hiXy7Kn/IYHNSTW1rL3iOnUdfr2J1awarAQ6zcXsjr20rxCa/Ce1c9L4VU83HUn3yfosYzOJcXP8rD6/NsRo0SwrqwHD5UHMf3092sDoymufk6NVf7eeuzwyz0C+P9r1NxeU+Jk08Si7fk81zAKTZH5j35sJ4799l5pJKztd2TsSCNTZBTVM/qrdF4rItg267D7D9Whod/BBtCYvBX/EJ4bDHp+Y0E7MjHKygLbf8D/gt2WUZwOI9tExxNK2Dx2m8J3XOMlBMalm+IxDswEUWcmpZrPYxbRuns7qVT18+YxUy3rpebXVr0hnuYJLNjGZAdBe12mcd2mf6+u2SdOkuSKoekTDWrgpLZGpFDbMIZ9PoRGpuvc6H2Em3tHZRpzlNUoqFUfY7K6jpu9d2Z3jAZwXE4cH9EovxcC61NHRwvaGDzdycI3ZlOW9utf8uyy0w8mqDX0DwZ375/g2FT37Rk+5RkxyWQEYdN5BXX81XkURRRKqpqriLe1TMk6hkYFDGOGicTJZuB/KsxGMeH0Bka0BoanjR8MqF92g6I98jI01CoqaeoqpGc4mryyy+SX15Hp7aXUaMRvUGk7kYJrdo6WrQ1tGovYDObJt9ZmNE+RYdvZ8xiYdRkQpIkLBYLZrMZsyQhmUwYjUZMJgmLZEUatWC1jDM0bOFi+whtt6WpCWemnLH/F44tOlEHFe3wD76g0j+gYHs2AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "virtualbox-version",
            "title": "virtualbox-version",
            "src": "/static/2fd3192445d3252ea512c12fa738c1d7/fcda8/virtualbox-version.png",
            "srcSet": ["/static/2fd3192445d3252ea512c12fa738c1d7/12f09/virtualbox-version.png 148w", "/static/2fd3192445d3252ea512c12fa738c1d7/e4a3f/virtualbox-version.png 295w", "/static/2fd3192445d3252ea512c12fa738c1d7/fcda8/virtualbox-version.png 590w", "/static/2fd3192445d3252ea512c12fa738c1d7/bce72/virtualbox-version.png 633w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "part-2-downloading-ubuntu-204-lts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#part-2-downloading-ubuntu-204-lts",
        "aria-label": "part 2 downloading ubuntu 204 lts permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Part 2: Downloading Ubuntu 20.4 LTS`}</h2>
    <ol>
      <li parentName="ol">{`Open the `}<a parentName="li" {...{
          "href": "https://www.ubuntu.com/download/desktop",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Ubuntu Desktop home page`}</a>{` in your computer's web browser. You can download the Ubuntu disk image (also known as an ISO file) here. Alternatively, you can use this link: `}</li>
    </ol>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://public.parsertongue.org/ubuntu/ubuntu-20.04-desktop-amd64.iso",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://public.parsertongue.org/ubuntu/ubuntu-20.04-desktop-amd64.iso`}</a></li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`After confirming that the version of your ISO file is `}<strong parentName="li">{`20.04`}</strong>{` (Ubuntu 20.04 LTS),  click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Download`}</code>{`. `}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cea721346a0420165ccf4b2367956fe4/e72de/ubuntu-download.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.45945945945946%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAACN0lEQVQoz43RW08TQRTA8f0mfggSLAloQTDw4KuvvhofjR/AJ5VEo4nRpBqiJAYShaABSbiIiOHSgHKRlAKFlkLLtjt77XZ327L9my0UMRjimfwyl8yZzJyRlm41Yt0JcfdGC82dXbReC9PR1UlLWyvh9nbC7R00h8O0dVynqbmFxlATl0MhQqEQDQ0NRF5FiEajzM3PMTQ0hLRw8wqLVy8x9egBkx+/8G1ghOjIOAvDoyyNjrI2Oc7G1Bg7MxPEZqdZj84RW10lHttgbWWVw0wWSzcxNB1VEUivb9+n7143nx/3MtPzieUPE2yPzXA4v4ixvo6b3MFXZLBNKLlQKYPvUz3yOSpVKJoFDEVDlRWUjIzEBeEDlRPBuHqq3s6HpGta7aoBoShoqooqBIau1wRrQgicYrGWUK1WL3ThDc9GsPm/DiwVbUoF65hdoFyw8FwXz/Mu5LjOP0nWVgwre0Ahk8bc38NIp3D2U7Uk9+TgoK8rOkUqXvlPQf0zBfZByq/9RE5skk9sktveJLcVR0vEMU0TwzDQg1oaRk2wFvxsUtnjzVIf75bf078ySN/KAL0/+hn8NYyU3U+T3k1wkEqSSSU5SO2Sy8nIuRyyLP9F03X8sk9c3ubh16c8+f6Cl/M9PJ+N0D39jMjCW6S8ZiGrJofiWDB3Pff0uXX1JztBbzuUNAdPcygKG0dzcbUSlEFSVQ1FCBShIk7Ytn2c7DjneJ6LbhVIZvPkVYHQVIRpoRgWhaLNb/6qX2cNONS8AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ubuntu-download",
            "title": "ubuntu-download",
            "src": "/static/cea721346a0420165ccf4b2367956fe4/fcda8/ubuntu-download.png",
            "srcSet": ["/static/cea721346a0420165ccf4b2367956fe4/12f09/ubuntu-download.png 148w", "/static/cea721346a0420165ccf4b2367956fe4/e4a3f/ubuntu-download.png 295w", "/static/cea721346a0420165ccf4b2367956fe4/fcda8/ubuntu-download.png 590w", "/static/cea721346a0420165ccf4b2367956fe4/efc66/ubuntu-download.png 885w", "/static/cea721346a0420165ccf4b2367956fe4/c83ae/ubuntu-download.png 1180w", "/static/cea721346a0420165ccf4b2367956fe4/e72de/ubuntu-download.png 1198w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "part-3-creating-a-virtual-machine-vm-using-virtualbox",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#part-3-creating-a-virtual-machine-vm-using-virtualbox",
        "aria-label": "part 3 creating a virtual machine vm using virtualbox permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Part 3: Creating a virtual machine (VM) using VirtualBox`}</h2>
    <ol>
      <li parentName="ol">{`Open VirtualBox (instructions vary depending on your host OS).`}</li>
      <li parentName="ol">{`Click the blue badge in the upper-left corner on the VirtualBox window labeled `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`New`}</code>{`. `}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6f3a6aff19bdd5d46b0e58cf713d87b9/7e4a6/new-buttonon-vm.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.37837837837838%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABtUlEQVQoz62QS2/TQBRG819Z8w+QYAFiAyxYISSExIZFWQISapU6Th1aP0hSp4kdsMePRKkjJ9D4EaeADhqjVBFq2cDiaO6MNOf7dBtav4/hnTMIL7DDb9jBV/RxytBxGA26DM0D2sYx6n6bg/d76L23uGMPxxnhui6O4+BKXLemsX+24OhsjhWWmEGBJTJaoyW9gcNpV2c8sDB7XU7bHQYdhfHwmHg6I45jgiAgiiLiyRQhBEL4NKLAJwwEwveuiMOAMIrodD5iWp/QNI2eqZOEUjCtZVKQZzlJkvDm9UtWxYrq8geN32ZRp0nkHIYhQSBotQ5RVQVNO0JRWjQVheZhE1VVUdU2y+WCu/fu8+L5E7wPeyy+WFIoJbtIoWwSo+t9TNPm5MTA0HUMw8C27TqwKArm85QHd24Tdp7SfngL5dUjGttmu8hdzGYpQhR4XkEcJ0wmcb2vLavVBcX6knPPINGfYb57TPTZvl7o+z6LRcpmU1JVOev1uqaqtmdVN8zzjPXmJ3m5qe/V5vvNwjRNKYqSLMvJ8xuQ0iyj2Jn/KizL8uqzbPAn2/fdgH8SXsd/F/4C6iIeqRFok00AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "new-buttonon-vm",
            "title": "new-buttonon-vm",
            "src": "/static/6f3a6aff19bdd5d46b0e58cf713d87b9/fcda8/new-buttonon-vm.png",
            "srcSet": ["/static/6f3a6aff19bdd5d46b0e58cf713d87b9/12f09/new-buttonon-vm.png 148w", "/static/6f3a6aff19bdd5d46b0e58cf713d87b9/e4a3f/new-buttonon-vm.png 295w", "/static/6f3a6aff19bdd5d46b0e58cf713d87b9/fcda8/new-buttonon-vm.png 590w", "/static/6f3a6aff19bdd5d46b0e58cf713d87b9/efc66/new-buttonon-vm.png 885w", "/static/6f3a6aff19bdd5d46b0e58cf713d87b9/7e4a6/new-buttonon-vm.png 952w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Enter a name for your virtual machine. Since we're installing Ubuntu 20.04, I'll enter 'ubuntu2004'. Note that VirtualBox automatically changes `}<em parentName="li">{`Type`}</em>{` to `}<strong parentName="li">{`Linux`}</strong>{` and `}<em parentName="li">{`Version`}</em>{` to `}<strong parentName="li">{`Ubuntu (64 bit)`}</strong>{`. These two options are what we need, so keep those settings. Click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Continue`}</code>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d9afde77b56a15fe53ad410932ca8c81/c5bb3/vm-name-and-os.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.70270270270271%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACUElEQVQoz22T727iRhTFefm+RfcNKlX9siu1qgoBxxuHZAkBYjABjA3+N2N7xgYMdPOrPOx+60hnzhnde4+udO90kiQhSVJalkIwHr8wGAxwHAfLsuj1eti2bXQYhmRZxq3m/9HJpURKiZCSJC9YhTvWvm+KgyBg4/tst4HRRVlSVRVKa8P6B/9E69NJMkEscuZpyXCvWMoD9vML/YdHnOGQp+Ej1mDAo+Nwb1n0+3d0u13u7npY1gDbvsdxHoxhURR0wjhlFJVYoaa71kwizZ/9r3zpWsa027dYeO8G05nL7M3lzV0wfp3izj1m7tzoNJM3QzeSPMeavq/prRRvSU16uLLOFCPPZzgLGM8DFltBfYXy9AMNyPqDrPq4sWqMaceLc14SjR0oehvNZK+YvAc8zdeMF2se/YKvY497Z8TgYcSru+JltmQ63xDLGn1oiMrv2BtQhaTj7gQLqXH2Cmvbdljhi8p0OFuHbPYxaZpwOh0p8hytyhu04no+UhzBfvL4+8tndFXR+bYMWUaCZV7xnGje85ry8sEqyhjN5mz8Lculx3qzMVO8Xq+cLxea84Xv1xNeCr/9NeVb93cKVdPZRTFPrs/rKsLdZexzhTqeibKCSBQcjwfqukYpZfh8PtM0DZdzw7+XBl2fKI8ftMesTXttdxETz8d6njNZhvi7hDiTaKXM5Nqc1rDdu/bd6lho9llFKitUWSBEdjMUQlAUOZkQtHoXJaRpisgy8ys8z2M6nZrFbuO+7yPSmF//qfnljyOfuge2wQ5vsTDx/wDKkSVgtHTzagAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vm-name-and-os",
            "title": "vm-name-and-os",
            "src": "/static/d9afde77b56a15fe53ad410932ca8c81/fcda8/vm-name-and-os.png",
            "srcSet": ["/static/d9afde77b56a15fe53ad410932ca8c81/12f09/vm-name-and-os.png 148w", "/static/d9afde77b56a15fe53ad410932ca8c81/e4a3f/vm-name-and-os.png 295w", "/static/d9afde77b56a15fe53ad410932ca8c81/fcda8/vm-name-and-os.png 590w", "/static/d9afde77b56a15fe53ad410932ca8c81/c5bb3/vm-name-and-os.png 680w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Select the amount of RAM to use for the virtual machine (VM). Click and drag the slider left or right to decrease or increase the amount of RAM that VirtualBox will make available to your Ubuntu VM. Click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Continue`}</code>{`.`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`The "ideal" amount of RAM will automatically be selected when you get to this page.`}</li>
      <li parentName="ul">{`Make sure `}<strong parentName="li">{`not`}</strong>{` to increase the RAM into the red section of the slider, as performance is likely to be adversely affected; try to keep the slider in the green.`}</li>
    </ul>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "590px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f406348a4aa96dfe2073d9aa27271999/8ce52/vm-memory-size.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "52.02702702702703%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVQoz2WSS2/bMBCE/f+PPfWv9NBDm6Zog9ZJnaSWYkfvJ0VRIiVZj9j4CsmNA7SHIcEBd3YWO6uyLJFSLlClRJYl9/f3rNdrNpsNQgjqumb+p5S63P/iVWc1H8ujLAmkwhY1gax5sGye9nss2yYMA5IkIQgC0jTF932CwCcMIxzHWfiu65bGq0qVxJnAzituYsOXwLBNa75vLG42v1nf/sJ13UUoic+iURhhjKFrWw6HjrZtmaaJahbMhGSfSn6kmk++4bOr2RUdjlDYicAKY/woJE7jBV7gkeYpx2mgMj1bJyUqWrJcUinFKs4LnrKS29xwHWiuvLPDxzRkbxSOkYRGUPYS2UvUVJFUMZ71gOdGWG5GkDdvgu7sIpFspeFbrLkODFaq2aeKrJ3ITU+lW8ZhYOgHxnFEa00YRaiy5HQ6MQwDL/PIVX1eysZN2OeKbdkso3t1jxpOyG4kUwbdtEtR3/cLZtHj8cg4ThwOhwtfVRWrecN+nPHTCtiGgl2uyHWL7kZy1VCZv+7+Q8/L2PMyvXEXwdm6GyTc2R5f73bYXkKUSYQsMdosG31DQ9MYZNUQiYa4aNDaLNycx9VrqOtKIURBLgrCJKMQgqKQl9C/5vX52cF3nrh61Lz70PH+Y8fOz9nv7KX+D4OY6sWXTU8jAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "vm-memory-size",
              "title": "Try to allocate at least 4GB of RAM to your VM",
              "src": "/static/f406348a4aa96dfe2073d9aa27271999/fcda8/vm-memory-size.png",
              "srcSet": ["/static/f406348a4aa96dfe2073d9aa27271999/12f09/vm-memory-size.png 148w", "/static/f406348a4aa96dfe2073d9aa27271999/e4a3f/vm-memory-size.png 295w", "/static/f406348a4aa96dfe2073d9aa27271999/fcda8/vm-memory-size.png 590w", "/static/f406348a4aa96dfe2073d9aa27271999/8ce52/vm-memory-size.png 681w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Try to allocate at least 4GB of RAM to your VM`}</figcaption>{`
  `}</figure></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Accept the default `}<em parentName="li">{`Create a virtual hard drive now`}</em>{` and click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Create`}</code>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cf5e1f5ba3687c93ac67f6a5ef6e2956/1b747/vm-create-hard-disk.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.02702702702703%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB9klEQVQoz2WS23KiQBRF/f/X+YT5iKmap2QkiVGJihjEuxhoaGkQxAvmYaU4NbGmah5O7V1d3avPrWWMIU1T0tRg0pQ8z/A8j5eXF3zfp6oqDocDRVH8F2VZ3n1zp+G0BGYMmTGEacZc52zTgiBSbIKAj4+QXRCw2WzYbreiq9VKdL1ei2/O9/u9QFsNKE408yTDVgXtoMSNch46Nr8eLB6tJyzLot1ui/b7fWazGfP5XHSxWAj0O9uW3qes4pQ3deBPUPK4LnHDA52Rx2NvhNUf0rPfGAwG2LbNaDQSSBNNa1zXZTweS5bH45FWrPf4KmWYlDztCgG+xyUDf0nvfUFn9M5o4glgOp0K0HEcut0uy+VSSo3jWPonGQZKMw1TfFPyGha0twVecsJdx3ScKf2Jjz10mExcgZ5Op/ugzuczn5+f3G43UQEak+JuFLPYMM0qeqrATc48ODue34ZYrzbWc4der4vjjCWb6/VKXdei58uFy+Ui/i/QsAkVw/kOb6eZ6Zy4qMjLisU6IIz1fW0azbLsDqvrK7e6FmDTP5lyU3ueGVZBxNDf8DSYMV0rwiRjb3Iprynz32geC6CsOBQVZXmUKTeftb4X+5BnaL1HxQnbD4VSMYnW0nSttUTjkyRhMpkQRRE/20d+/K5wlhmrhU+kFF9aId3HwhRlpAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vm-create-hard-disk",
            "title": "vm-create-hard-disk",
            "src": "/static/cf5e1f5ba3687c93ac67f6a5ef6e2956/fcda8/vm-create-hard-disk.png",
            "srcSet": ["/static/cf5e1f5ba3687c93ac67f6a5ef6e2956/12f09/vm-create-hard-disk.png 148w", "/static/cf5e1f5ba3687c93ac67f6a5ef6e2956/e4a3f/vm-create-hard-disk.png 295w", "/static/cf5e1f5ba3687c93ac67f6a5ef6e2956/fcda8/vm-create-hard-disk.png 590w", "/static/cf5e1f5ba3687c93ac67f6a5ef6e2956/1b747/vm-create-hard-disk.png 679w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Continue to accept the default option of `}<em parentName="li">{`VDI`}</em>{` drive file type and click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Continue`}</code>{` .`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8426857fab7ef059e9c36c173c446d9d/31198/vm-hard-disk-file-type.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.1891891891892%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACJ0lEQVQ4y32T227aQBRF+f+XPvVTqiqKGqqGJkBJo4Qo3AwGYxvbc7HH3AwiWtUMgUZN1IetMxefdWY8Z9eklCilOMUkSWi1Wtzc3Dg1m023Zowhz3OKovhQds8yakeYQimJ1hJTaAcYDodMfR/P8/B9n+l0etZoNGI8HruxjZPJhCAIXNHaCWbjJJSMAo03nnFV/07n7o52u+1O3Gg06HQ6rkC/32cwGNDr9Vy0c1vUAS0sFYquJ7i+X/DzfkF7UHJ77/PQHfDc6zMaDd1J1us1Ly8vHA4HJzt+Oy/L8nhlP8r53c9odgWtJ403N1x8a/Ll4prLqwb1ep1ut+sSqqpis9mw3W7fxVegZp4YnqcljyOB138iFSVRUhLEhmBhECpHa81qtXJAm/yv7LoDCiFJZMl8kaInP5DjBoneIYsdqarI9Badl2w22zPMxpPeAWfzBUEkWCmPZXxLHPmIYocyFdJUZGpFKrT7f6fEt9B3wDDOmI4HrPIQo0OEkpTriuW6QpstcWrQuvjwih8C7aNE4QwVPlBObvH9mDAxzELFLNQIWZxP9z9Z4LltpNTEUUg0vOOxO2YSKOJEkxf2Vbfs93un3W531n5XcdgfZcd2f7lc2saWrrHta2dSk2XC2agsjbuCrWq/ybLs1WbWghqVG8JsyTxdInR5Nkft5OO/4ONcCIHtAAuyTW1dYS2ZphmLKODZV3y+XPHp65pfA00w9YjjBX8AOqDLoJML2LsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vm-hard-disk-file-type",
            "title": "vm-hard-disk-file-type",
            "src": "/static/8426857fab7ef059e9c36c173c446d9d/fcda8/vm-hard-disk-file-type.png",
            "srcSet": ["/static/8426857fab7ef059e9c36c173c446d9d/12f09/vm-hard-disk-file-type.png 148w", "/static/8426857fab7ef059e9c36c173c446d9d/e4a3f/vm-hard-disk-file-type.png 295w", "/static/8426857fab7ef059e9c36c173c446d9d/fcda8/vm-hard-disk-file-type.png 590w", "/static/8426857fab7ef059e9c36c173c446d9d/31198/vm-hard-disk-file-type.png 694w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Accept the default `}<em parentName="li">{`Dynamically allocated`}</em>{` and click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Continue`}</code>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/604666e12b4285013bf46c1fb7aaf31a/49217/vm-strage.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.1891891891892%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVQ4y5WTW2/aQBCF+ft962+JelNVkRQKhJCSCIMB29yMr9hrG5sCIQ9fNUtopLaq1Iej2fF6z54zs1NLU4VKU9I0RSlFkiT0+316vR6DwUBHybvdLoZhUBQFWZb9ATkrHDWlhOycZColzxTe2sWaTpnPZkwmEyzL0pjP58RxTBiGRFGkIflms9EXCWpaoRIyRRCnLIMML0j42mhqdeOxiWme4TiOVi1KJQ6HQx1Ho5Emr6qKmrarFI6b0jEiGv0Q04lptB+4fxixWrms12uCINDKfN//pU6+SfQ8Tyvdbrdny16U8TBNuDUiOkbGyI64el/n6sMNHz/fUK/XabVaNJtN2u0219fXei3KpCSiXmr/Qqjw44LxsuDRLhhPHJZuSLPzSLP9yF1/zMAwmc1m2Lat67hcLnUu6kS1KMzz/GxZmhGnW9woJ170yZ0vzL2Cb3dDOncDOj2DoTlFZZk+JLg04NJx4SjLUqPmBzGLdUKpXCq/ReINscJnGvcW7dvvjG2X8cTWP59OJ56env4K2dOWozjBsR0qtabMPRIVU+4OlNWeNP9BnO7IipLD4fBPHI/HSw0zAn9N4o3Yrbq4CxcvKvHDnJWXE21ydrvd/xCen41+FnaP4WCKtVR4gSJRBfv9XluSA4JXm0eeT2fIWizrGp5HTpCRpBlRvNFjJJvVS6HlZoGsi+1WNyIvSsK0IkgqVF5SFLnmqF1m8JX4Nf8dSjsJsaYm5iLl7ac9b97t6U4KFrMpQRjxE1rivycF9CP9AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vm-strage",
            "title": "vm-strage",
            "src": "/static/604666e12b4285013bf46c1fb7aaf31a/fcda8/vm-strage.png",
            "srcSet": ["/static/604666e12b4285013bf46c1fb7aaf31a/12f09/vm-strage.png 148w", "/static/604666e12b4285013bf46c1fb7aaf31a/e4a3f/vm-strage.png 295w", "/static/604666e12b4285013bf46c1fb7aaf31a/fcda8/vm-strage.png 590w", "/static/604666e12b4285013bf46c1fb7aaf31a/49217/vm-strage.png 701w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Specify a maximum virtual drive size and click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Create`}</code>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a6f6efd5a90cf2906c9154ce4a744e38/487bb/vm-file-location-size.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.51351351351351%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACSUlEQVQ4y3WT2W7bMBBF8/n9lH5A31qgAdI0rvdNXiI5kqzdIqnNm/xyCtJx4BbtwwXvQNDhJYfzIIRASsF1laRpSq/Xp99/12CAZVmUZYlSiqIoPqTUvVeG8XCFSVMoKSiUJI5CHNvGcWw2mw2u65JlmVGSJCRxTBxHZvM0SYyElBRlyYOGaahOFyQCN1L4YcZgNGFuWUymMxbLFaPxxPhef0B/MGQymZpa+9l8gchzmrq+JsyFZOUKnicpP0YJvxY5T+OAp6FHZxbQtQK68+vas0J+zQP6y5iXqc/clfgKhPApi1QDJV6sGK52vEwzOvOC+Trm62OXx+cxL71rktF4ynA0YTa3TCqdWHtrsTQJPd+lrisNVGyTkoVXMX5VvK5t7E3Et+8desMVSzvEdt7wfY8wDAiCAM9z2W63xodhaPxut6OqKn2HglTU+Ikkf+sgnUei3YEwLYmyhkQcELKkafYcj0eOpxOn08n4uq65XC7keW7gutsP2zBlGwkasaEJn0gjBy8qWKw9krwhL45kueJwOBiQ1vl8NjDdfb1q+H6/vyYM4wzXsdkXEZUKyGVOtW9pDi1FfSbeVQhZfMDupcE3tW1r4KYpUeChohmN38P3E+JdTZjo56PI8uKPdP/TLbUB6mcTRVviVYfJ1MHxJUEkELIyx9G7/53m3LZc2rNR294lvI2e7naWS5I0M2OkPzZNY6T9vfQY6vuSpb7jhqKqTa3/Mwk19DbLRu/1v6Q7ul6/EodbPv/c8+nLke6qwH+zieOE3wsjwqYMu1gKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vm-file-location-size",
            "title": "vm-file-location-size",
            "src": "/static/a6f6efd5a90cf2906c9154ce4a744e38/fcda8/vm-file-location-size.png",
            "srcSet": ["/static/a6f6efd5a90cf2906c9154ce4a744e38/12f09/vm-file-location-size.png 148w", "/static/a6f6efd5a90cf2906c9154ce4a744e38/e4a3f/vm-file-location-size.png 295w", "/static/a6f6efd5a90cf2906c9154ce4a744e38/fcda8/vm-file-location-size.png 590w", "/static/a6f6efd5a90cf2906c9154ce4a744e38/487bb/vm-file-location-size.png 698w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p"><undefined parentName="strong"><span {...{
            "role": "img",
            "aria-label": "exclamation mark"
          }}>{`❗`}</span>{` I recommend at least 40 GB`}</undefined></strong></p>
    <h2 {...{
      "id": "part-4-installing-ubuntu-in-virtualbox",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#part-4-installing-ubuntu-in-virtualbox",
        "aria-label": "part 4 installing ubuntu in virtualbox permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Part 4: Installing Ubuntu in VirtualBox`}</h2>
    <p>{`Now that the virtual machine has been created, we are ready to install Ubuntu in this VM. `}</p>
    <ol>
      <li parentName="ol">{`Select your new VM created in Part 3 and click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Settings`}</code>{` . `}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/10a5f5eeb85e5a13bc335746814c68bb/9b379/virtualbox-setting.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.05405405405405%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACCklEQVQoz3WSW08TQRiGm+At1x74AyQS9VL9ffwA9c4LE73RGBqgKDFqhOhCgrR0C/aws6eZpZRSim0pPZf2MTPFQAhM8mS+ney8+73vt7GMbZEVfwh9H99zDdIXJN19xNoy/ue3pDJ7/EyvksnYCCFwXdeg33WFwHEKBuE4xL5v59n8bbPnBGSFnOAEbOQU9uoCxR+LqGKJnL9DFCmUmiClIudJZHRA+fiv4aB8TCye+MKHtRxLlmBL9dmOhnzLN9kp7JNJJjk+PKJYLCIKLq1Wi8FgQK/XYzQaY22leP3mHR8XP/F+YZnVr2vEvDDCCxReIPHDyBCqfbKuJGVnKJfLlEol6vU63W6X0WhEv99Hr1cvXzA395Dnz55y/95dnjx+REwpSWTQViTqog58n2w2iwxDLMvCtm3y+bwR153qNT8/z/T0NA9mZpiausPs7CwxKSWXXOQThhxWqtTqDdrtNicnJ9RqNVOfnZ3R6XQ4Pz9nd3eXeDzOysoKiUSC9fV13eFl0JOwJVEUURAevyzLTLXZbJpdC2rLw+HQZHnTullQKRzXYyedNmeVSsVY1jlqMZ2lHozuVH/kP/r5VkERKKyNTXOmM9Ndnp6emno8HhvLWvw6t1oOgskwfN+n0WgQBIH5mcMwNJOvVqtm2rrbq9wgONmD6IC8I6hUjozlq5e1Nb3rHK/zDz6VDSshPhLmAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "virtualbox-setting",
            "title": "virtualbox-setting",
            "src": "/static/10a5f5eeb85e5a13bc335746814c68bb/fcda8/virtualbox-setting.png",
            "srcSet": ["/static/10a5f5eeb85e5a13bc335746814c68bb/12f09/virtualbox-setting.png 148w", "/static/10a5f5eeb85e5a13bc335746814c68bb/e4a3f/virtualbox-setting.png 295w", "/static/10a5f5eeb85e5a13bc335746814c68bb/fcda8/virtualbox-setting.png 590w", "/static/10a5f5eeb85e5a13bc335746814c68bb/efc66/virtualbox-setting.png 885w", "/static/10a5f5eeb85e5a13bc335746814c68bb/9b379/virtualbox-setting.png 951w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click on `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Storage`}</code>{` category (`}<strong parentName="li">{`1`}</strong>{` in the image) and then `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Empty`}</code>{` under `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Controller:IDE`}</code>{` (`}<strong parentName="li">{`2`}</strong>{` in the image). Click the "CD/DVD" icon (`}<strong parentName="li">{`3`}</strong>{` in the image) on the right hand side and select the Ubuntu ISO file to mount.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3c214e8938e4a3ef564644d791bb7c10/af192/virtualbox-strage.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.45945945945947%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAC0UlEQVQ4y4WU72/bRBjH/T/zDt7whhdISEiIgXjBi63tqMYGG9OEBmS/EBS1WUlT24njH81ix/b57uy47bJWS/NBvjRZNjqw9NVzp7v73Pd57s7Ws79sdkYebpziZ5q/ux5x6xeGrd8IhzF2MORxp89hMMQZBIwygRMrKiXxE4k/VmglKYrCyNrYTTgIczafRzz2BFutQx7eb/HT3RZ/dgN2Ismt3SN+jxQ7geAgKfixl3OsBDuDnD8GGbWWSKVRUmId9bqEgY/t9khGL0g8F+fONvbtLUZenziOCfouYRCQxDFRGDLw+ozHKUdHEb7vkwvBpNKUdY3V6YUcHDp0HZdRLjg7nzI7nfD6pOL8fEpZHzPwA9rtNsPhECklWmuEEEipELmgnFREbkTy7CnW9Zttrn12l68+ucXnnz7gw43c6KPNnA++Tdl8lHM80XiDAbbjGGgYhuzv79PtdrFtB6Ul7ecxzs1trDs/7HL92j22vvieG9884Ov7L9h+WvLdE83Go5Jf9xsXGWmWGWd1XZsyOI6D67qUZYlSiokuqKXAGk/OkNMzCjkmzWNeHQvWv8nJK9y+T7d7YOrVpNqAq6oyakrQAGUjrbFEdfYWYA7MZnNez+amP5an5EIynb4kCENs2zaApRr4Skq9AV5czJnPFzLgy5ipU9K8uWOCTqdjXDaL16HrWgGXsHWtAweex97eHlEUvd/hespLwCr1d4BSFpdXRa5A/wv8L4dVWZoTXS68CnaFw0tdASzfqdt7HablAji7gIv5QqZ/GVM9Jc0KylL/C/RmA72SNczqhQt5QqZeEhcVg2REnNf4SUkvzMwdu+pEl65KragrxaRSWM3O2gwqk5ZUgjgLkUVBMk4RSUxVLWrXzDXzTbtc/LJETioqvNGEIKmwmieVZjmLuGjneUFmoiDLBWmavaVxmpoxP4gI/R5PDgo+vl3x5c+afwAR3+bNh2uJbgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "virtualbox-strage",
            "title": "virtualbox-strage",
            "src": "/static/3c214e8938e4a3ef564644d791bb7c10/fcda8/virtualbox-strage.png",
            "srcSet": ["/static/3c214e8938e4a3ef564644d791bb7c10/12f09/virtualbox-strage.png 148w", "/static/3c214e8938e4a3ef564644d791bb7c10/e4a3f/virtualbox-strage.png 295w", "/static/3c214e8938e4a3ef564644d791bb7c10/fcda8/virtualbox-strage.png 590w", "/static/3c214e8938e4a3ef564644d791bb7c10/af192/virtualbox-strage.png 645w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{` `}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Select the location of the Ubuntu ISO file that you downloaded in Part 2, and click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Open`}</code>{`. `}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2bdfb08d639d6eeb8816b0345769b6fe/73fd0/ubuntu-iso-location.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.08108108108109%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB/0lEQVQoz42TS2/TQBSF/a9ZIv4BGwQb9mwqKigskBCIojYvO01q3DpJ40ccPxOnTmLjZz5kt2mjIiSOdHRn5uqeOVdzRzDNGbphoOsGhjlDuxljaiOc2RTdtrBmOj1ZQ1JdpGuHs4sp3zrXTKYmnusQBMED5/M5QlsaIskjlIlJS7X50Lc57WuI0y2SltAd3/JZWnIsrnnfjfgorTnuRXirlCJLWW82bDYbyrIkz3OEVlfi649zhsqYzsTn7U+PT2LIkZjwrh1z0t/yL1RVRVkUrFYr4jhuzgRRuqArDRhPbri2AhTLhSw6KCspypKy2j1h1WTbg0u+d0SSNGW32yG02l1Oz1qoqsrIXtDVXBZBQJLmlBWkWd44qVEX7OP+7OXrNzx7/gLPD+4c9sQ+Q1nhZqrj+At+5wVJEpNl2UNbh0KHscZIVRF7vceWL39dcTGUUa5U/Ptb9qgL9zzc16gFdF1nuQzZbre4rtuYEMT+gLNWh0tZwfX8v4SesroXjKIIWZYxTbNZ719aqIXU0QTNmOF5Af+LJEmauavnLy8K0qwgL0oEa6ph2w6O6zXJ9Xr9wDAMHwd3seA2XKCYMUG4IYpuWS6X+L6P7zmsAoso9BBsx2ls1wnLsrBtu4k1jeYH6Xc0DEzDYKxZmLO7/Nx28B2LwWTBqy8xR+chfwCGdjbqpAHqJwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ubuntu-iso-location",
            "title": "ubuntu-iso-location",
            "src": "/static/2bdfb08d639d6eeb8816b0345769b6fe/fcda8/ubuntu-iso-location.png",
            "srcSet": ["/static/2bdfb08d639d6eeb8816b0345769b6fe/12f09/ubuntu-iso-location.png 148w", "/static/2bdfb08d639d6eeb8816b0345769b6fe/e4a3f/ubuntu-iso-location.png 295w", "/static/2bdfb08d639d6eeb8816b0345769b6fe/fcda8/ubuntu-iso-location.png 590w", "/static/2bdfb08d639d6eeb8816b0345769b6fe/73fd0/ubuntu-iso-location.png 793w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{` `}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Check the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Location`}</code>{` field and make sure that the correct ISO file is selected, then click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Ok`}</code>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a3cd823e96086d9601308244ef3e1d73/d30ee/virtualbox-iso-file.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.72972972972974%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1UlEQVQoz32S22sTQRSH9//HF0HENx9F9EEhoOiLUEttaQ0h3Xa73VvTvc5lZ7O5VGiST2bahDagBz5mDjPzm985HO/b7wuO0wkHQcmRnzFKSs7iisOwIMwFJ3FBEGWcXKTUVYWUEiGEW/cRUuINg5hxlHIe33A6vsSPEq5vbvHTCVlRMI4zovQGP56gtcIY49Bao5RySKVotaJrFd7XwysGP3xOfMEo7vkdGk6vDKeB4eii5TJtub4KiMKQoihI09RhXU6nU4zpmPUdk7ojmGi8l59TXrzzef0p5v2B4cNPw9vvmjdfFK8GisGxQjQlRVnSNA1BEDhB66xtW+fUtJpatOS1xtP9kru5ZtErYMN+9PMlcZKQZRlJklCWJV3XObGnGNM6YW92t9o9Xq03D6w23K/sHoRZuIafj8cMh0NGoxFRFDniON5h87qu8frlvXO2Xm/YbB4c2nW7V92SPM8Jw9CVbKmqyj3ex378KAjrR5Et60dBaRauX7ZM2y/Lfrlb7Jk3/7Pmf6GndwghdyPyL6TUCKnxKjVDdQukmT9hQS5q8qYjyyVaqd3cbV0+z1vmU8ViqvDsdDfiOdZR1VRUdYNommdDvC/m7ha3DM56Pv6a8RemITMg9D3KLAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "virtualbox-iso-file",
            "title": "virtualbox-iso-file",
            "src": "/static/a3cd823e96086d9601308244ef3e1d73/fcda8/virtualbox-iso-file.png",
            "srcSet": ["/static/a3cd823e96086d9601308244ef3e1d73/12f09/virtualbox-iso-file.png 148w", "/static/a3cd823e96086d9601308244ef3e1d73/e4a3f/virtualbox-iso-file.png 295w", "/static/a3cd823e96086d9601308244ef3e1d73/fcda8/virtualbox-iso-file.png 590w", "/static/a3cd823e96086d9601308244ef3e1d73/efc66/virtualbox-iso-file.png 885w", "/static/a3cd823e96086d9601308244ef3e1d73/d30ee/virtualbox-iso-file.png 980w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{` `}</p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`From the Oracle VM VirtualBox Manager, click on the new Ubuntu virtual machine and click on the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Start`}</code>{` button. `}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/053d991a3c3ff7e03467c2e3baf1ca4e/9b379/vm-start.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.37837837837838%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACGElEQVQoz22ST08aURTFZ+3KhV9EN34H1sZvZ5PaaNMuu2mjsQp2oTAOQZh/jDPzmAHagQEUBP+A/pr7DI01vuTkvrybe945917DrJ9xFTS5CnyCpkcY+Lh+k1M7pGl+wzKrlH984uz7R+yGh+97eJ5EX8N1XRzH0ZC7cfirSqXmcem3qAepjhdOzIll06gckyQpqnJCq3JCp/ObJElI05RQJfhXIb1exs3NDdfX1/T7fYyvh2W+/Gxw3Mi5SBcUgyklN6fesAnCmOFohGc7dNM288Wch4cHFosF48mE/f3PfNjZYW9vj93dXYrFIkakEqJYaYTRS4xabc6tSzzXodvt8ifLmM5mPD4+arLn52fyvE+hUGB9fZ2NjQ1WVlbY2trCUHGMUq8Qx7SUwvdcba9arXJaKlGr1QiCgDzPeXp6IssyNjc3WV1dZW1tDcMw2N7exojjmBcoYqX0vZW26WQ5w8FAKxsMBtze3v6D2J5MJpRKJQ4ODjg6OtKQzw2lFEtoUiFsJVTMC8rlslbU6XT0u1idz+fausT3zhvCF9tCXK3VcWybXq9HGIZ6JaR/ok4IBff39xp3d3dMp1Nms9kbwleWK1aN8vm5tjsej/VaLNdjNBoxHA51ThxIG+QzUf0f4VJlu93GsixM09SFMgAZSBRFOi9RlAu55IVwqfRdQlFYd5t64tI/KZQCsSRFs1crtFS2bMNfmiwMeHxP334AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vm-start",
            "title": "vm-start",
            "src": "/static/053d991a3c3ff7e03467c2e3baf1ca4e/fcda8/vm-start.png",
            "srcSet": ["/static/053d991a3c3ff7e03467c2e3baf1ca4e/12f09/vm-start.png 148w", "/static/053d991a3c3ff7e03467c2e3baf1ca4e/e4a3f/vm-start.png 295w", "/static/053d991a3c3ff7e03467c2e3baf1ca4e/fcda8/vm-start.png 590w", "/static/053d991a3c3ff7e03467c2e3baf1ca4e/efc66/vm-start.png 885w", "/static/053d991a3c3ff7e03467c2e3baf1ca4e/9b379/vm-start.png 951w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`When the dialog box appears, click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Start`}</code>{`. Note that the installation process that follows may differ a little from version to version. The screenshots here are based on `}<strong parentName="li">{`Ubuntu 20.04`}</strong>{`. `}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "588px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9bafb03b302e80cc3a9b4561e6e27fe4/9bbaf/ubuntu-start.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.72972972972974%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACSUlEQVQoz0WS2W7aUBRF+f3+SFWpwwNVSBqikAAtUxwP2BDjEdvYeDaQQX1alW+K8rB1hod1zt3ndsqypCgK2ljXFUVZ4Yd70rykaRrquqZpag6Hg6g/eo3otfnpdBJqGZ0zLMsL3G2MsnKF3O2O4XjCdL5AVTVkWWaxWDCbzXh4eGC5XKJpmqhd1+Xt7U3AO2VZkGUFaVaimx6jxYqFZuEFO27vRkznD6j6mjhJSdOULMuoqkrovEye57y+vr4Dq6ok2u1RdZsszYn3GbLhsI0SFpLMZCZxN5ryqGioqiq20nUdSZIwTVNsaxiGgLY2dNoJSZIynC0ZTDRcbys824Yxn7985+uPLt9+dOn+vKB3ccHl5SXX19f0ej36/T6j0UgMOvsqgFmaMX1cM5bWXA0lXD/EciO6F7/oXfW56t9y3b/l/v6e6XTKYDBgPB6LWlEUFEWlfakAFv+vLC833PxRuZ/r2F6A40cMx1MGwwmThYLnBSRJIhTHsfCzzcPAx/ds6uZE836Ud1N9L8BYOwJqWi7hvuK39H6gmfKEsfHZuBGWv8N0Q5wgZeMGrIIjZvzC68Gjqg8f3yYMdxgrC8vy2W5DdtmBtbdnZUeYbowbpjjBHn+XiRjtM4osxooOPDoHTi2waui0Zp7lbXc4bkQUJci6zVzeoBk2eV5yPB55Pp2EPc/HBjt+4Ur7i+y+8NzkxElGXVV0HMfhLMuyUFSdu9Gcbm/AzeAPurHGth1s2xZa6gb2Zs3N3OfT94gvgwDzyUCSHgXjH3E9Evrf8U+OAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ubuntu-start",
            "title": "ubuntu-start",
            "src": "/static/9bafb03b302e80cc3a9b4561e6e27fe4/9bbaf/ubuntu-start.png",
            "srcSet": ["/static/9bafb03b302e80cc3a9b4561e6e27fe4/12f09/ubuntu-start.png 148w", "/static/9bafb03b302e80cc3a9b4561e6e27fe4/e4a3f/ubuntu-start.png 295w", "/static/9bafb03b302e80cc3a9b4561e6e27fe4/9bbaf/ubuntu-start.png 588w"],
            "sizes": "(max-width: 588px) 100vw, 588px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{` `}</p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Install`}</code>{`, and follow the steps. `}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2b7c26492f1e16ad7e18ac2c078b5ba3/66caf/ubuntu-welcome-page.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.72972972972973%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC4UlEQVQ4y6VUWU8TURTuL8FHoGLQRKLibzLBEGJM6EKCQBHCgxGeTFxYBAmbBXnx2S0xasQOdJuZ0m2ms3WbaWn7mXOmw+KrN/ky59x7ztfz3XN6faVSCZquwzRNGIZxBbSnqipDUVVQ7L9xpmmhbFnQTZNtX2RmBtd6enBjYAD+/n70d8F2Xx+u+/24PzyMe3fvnJ/7u+jr7cXNwUHcHhrCLX8fDvZ24duPRvFobAzBQAChYBAT4fAV0N7c0zlEpqcvzkMhF107EAxhPjyOo+/f4HMcB5VKhaV0OvivVa3V4PPuStd1BpGXy2WGoigoFArnPp3pugZRTCObzSKTyUCSRM53z3SXMJVKIZfLoVKpotFo8K8tLMzj4cgDhILjGB0dASlpNpt4t7GOzc0NrK+vYWd7C2urKzg8PECn03EJif3k5JjJaDUbDZydtbD+dhW/fv5AOpXEyvJrrqJeryMa3YNpGgiHAlhcfAZN0/DhYB+tVuuCMJ5MwihXmPCs3eZEIpOlNFLJBI6FGCdSlTs7WyyVCJeWnkMURexH36Pdbncl6zpE4QiOrqBTMeCYGhPPRqawt7uNr18+ITD+mIMpafnNK8zOTmNycgKRyBRmpp9w1dRPt8KSAvXjJqz9l6hHX6D6+RCNsxYK+TxisT+ICTHIsgzbtrnCWq0KMZ2GIAg4FgRkTk9ZEZ1TP1iyKErQdMOV3GqhWq2iVqu53TcM9qmLBLKp4+4/xWSbYomU4n2WZSEej7NDiy6XRkWSJBSLCorFIvv5fB75QoGnwdvzQHFUYZfQ5PKJ2CNUFYUJJVlGNpvDaSYDWZJcyDI3yIPefQfOJRNhIpHg8nlsmk2W4Q06QTVMFA0LerkCm+/Lge10QbZts2QmpGRyLsObeu9lUQ0LWZ0Iq25z7DoaDn1t/jZp6BsOq/Tx83VJgieDiLwKabRMo1uxpiGZKyGWKULK5SGIBfxOZBCTTiGJIv4Cx1NdFXUM6OcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ubuntu-welcome-page",
            "title": "ubuntu-welcome-page",
            "src": "/static/2b7c26492f1e16ad7e18ac2c078b5ba3/fcda8/ubuntu-welcome-page.png",
            "srcSet": ["/static/2b7c26492f1e16ad7e18ac2c078b5ba3/12f09/ubuntu-welcome-page.png 148w", "/static/2b7c26492f1e16ad7e18ac2c078b5ba3/e4a3f/ubuntu-welcome-page.png 295w", "/static/2b7c26492f1e16ad7e18ac2c078b5ba3/fcda8/ubuntu-welcome-page.png 590w", "/static/2b7c26492f1e16ad7e18ac2c078b5ba3/66caf/ubuntu-welcome-page.png 853w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{` `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/64ca24698d245ade9623474b50306956/77800/ubuntu-keyboard-layout.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.05405405405406%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC4ElEQVQ4y3WTW28bRRTH98NU4qHqxU4iCqT0CSHUFvFAgAee8m2q9gNUFTSR8tJWSKhNE7VUAoQEUgWIBsV2HDu+rde7s3ev196Lk/1VM4ndtISVfnvO7sz5n5lzZrTewKRjWfRsm554B9umZRhv0RPiGEvQtSx0W9B3HHq2wLQdtPu3bnPz3Ht8XVpg5VJJ8ZXkclnZb8qLrC5/zOpHV9Wc+djlMisXS3y7dIXV5WusXLjEs4eP0O7evsMn5y/wxZUP+HzpfcWNhSWulxe4Xl7kMxl49RpffrisfPnvLUoLfFpaZOXieX754RHawLJ4Vdllp1Lh31pV2Vavx8C2MYSgLwSW52HKrZmD/2CcWNMa4LgOWhRFhEGg8H0fz3UZj8dM85x8RpYzBQ5Pcdb3MIrQpEi308EwDGzbxnEcZJIkSUgmE5I0ZTKKCH7fJvjpAf7TNfwn3zPcWifYvE+wuUbw+DvCFw8JHCEFParVCvV6HV3XOTyU+aAoCoriSPnTLKX52zPaP2/SevGjIvjrV7LqS/Lan2SVl2T7/xB67vEKK7UaO7sV1faznsMCWn0DXbh0BhYHhonhBmSn5hRAOByi+Z5L/dXfHOzuYLebJJ6gGAUcRT5HUUAR+UyjkEajQafdptnYZ3+/TrvVQu92Mc0Bge+pmg+lYGgNMJ9u4G1vkD7fYLy9roi31oi31omf3CP+Y5u9ZlOJ7tVqVKtVGs0m9fqeKlWSpKpUYRiiyZclBJ7vM4pjomikupVmGXk+VWR5rjofx/Ec+T2ZTEjTVJ2ELMuOBWVHPc/DsiyFEOJNl08hAyUycOZLZmPSzgVV9tFonl1mPgsVFARqJ7JeEhkrj5uMmwu+uxrJTGDmz1BJT21fCg5PFvX/gpOENEuJ/CHWgcl4NCYejoijWI1laUIut3/CNM/Is0yVSnNdV125s/BcD9d2VY1nyIvQtTwahk3fFBzogr2WQaNvovd0XgOcSWRgkxvHwwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ubuntu-keyboard-layout",
            "title": "ubuntu-keyboard-layout",
            "src": "/static/64ca24698d245ade9623474b50306956/fcda8/ubuntu-keyboard-layout.png",
            "srcSet": ["/static/64ca24698d245ade9623474b50306956/12f09/ubuntu-keyboard-layout.png 148w", "/static/64ca24698d245ade9623474b50306956/e4a3f/ubuntu-keyboard-layout.png 295w", "/static/64ca24698d245ade9623474b50306956/fcda8/ubuntu-keyboard-layout.png 590w", "/static/64ca24698d245ade9623474b50306956/77800/ubuntu-keyboard-layout.png 855w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b75cc660582099dac6a19ab24b250692/ad12c/ubuntu-updates.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.72972972972973%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqElEQVQ4y32S224SYRDH9yGMt/YUY2p9CaMXLWwbja2narwx8cJn0WdQrxpjr5RUTTCBAqVAwWNbm1LYA7AH9gh70F3+5vvYxRZbL36Z+WYm/52ZHeb73k/kyzsoVj9jq1pDsVqjNiZfKtNYcaeKfKmErcoOtioVSqFcRqlWQ+XLVxSrVRwcHoF59ew5rp47D3ZqBsmJKSQuDElSOwl2cgYrl69gZXYO7OQ0rUlOTFNI3c2Ll7A8O4fFiSnkNjbArL14ieVr1/Fo6QYesIt4yC5SO/SXcH8hgSd37uLxrWXcW0hgNcliNRFB/SRuzyfxlJ1HbTMDxjBNcKKIpiBA6LTBt1oQ2i2InQ4aPA/VMOAHARzfh+N7p+CP0A0DjGEYaHc6IyRJQof47Tb1JUlGr9eD67pwnP+j6zoYTVFx9O0HuPoB6vu7EBt1qC0BksCh2xYhCzxsTYVj6nAMDY6hn4JG81TQMi1okgy+2UTzqA6R56ApCrqKDE2RocoSVEmCY1twezacM3B7vUjQsiC2ROzu7aPRaNBRyV4934fneXDIqK5LR3bH/HGGI6sauMMmeJ6Hoig02O12KZqmod/vR/tzTkBFxt5U0DRMqB0FgihAFEVwHAex1aJJ8sNiQdLt8W5IfCjmnhS0bRu6rlEhgZxORCxOOifEPlkLycuyDE3X/x3ZNE16FqQb4sd23CeQfZMYscfXEHc7EiTjnIkfEb3jNRi6DtOyaDPkXmksPux+9JVxSLFlWLBNG7Zl0/cIko9q4nryExnfczEIQ4RBgDCMCAIMBiH8vgu1IcOUDLhmH8Gv3wiDEBiEND8iHFrPc8FkckVkCfltZPN/LY3ni9gsbCOTKyCbK2AzX0A2k8X6+wxep9JIfUxj/V0aa2/TePPhE1KpDfwByftlr4/Xhc8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ubuntu-updates",
            "title": "ubuntu-updates",
            "src": "/static/b75cc660582099dac6a19ab24b250692/fcda8/ubuntu-updates.png",
            "srcSet": ["/static/b75cc660582099dac6a19ab24b250692/12f09/ubuntu-updates.png 148w", "/static/b75cc660582099dac6a19ab24b250692/e4a3f/ubuntu-updates.png 295w", "/static/b75cc660582099dac6a19ab24b250692/fcda8/ubuntu-updates.png 590w", "/static/b75cc660582099dac6a19ab24b250692/ad12c/ubuntu-updates.png 856w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{` `}</p>
    <p>{`Make sure the `}<em parentName="p">{`Erase disk and install Ubuntu`}</em>{` option is selected and click the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Install Now`}</code>{` button. This may sound scary, but nothing on your computer will be erased.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d039570d55356f572959db7605f02810/42d54/ubuntu-installation-type.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.05405405405406%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAACb0lEQVQ4y5WTT08TQRjG+10IiUCRiHiphRCiFGIiR09W4039RnoxeMCziX4JD5goIdjSdnd2Zmdm/3X/zHa3bfKYmbrYQkLC4bfzzPvuPHnf2XdrfULRczksITHgi1hcokscdAhBxyboEoKB6xr6TMOMtoWAxTls5qL29fgzXmxuod3cxstG84r24ybajSZeNXfwdr+F961DvG7u/MttG7R+s7uHd61DtBvb+HbyBbWTDx+xu7SMZxsPcLC+gYP6fbTW1vF0ZQ37q3U8ubeKo4eP8Hxzy2gd07kZdeyvrGFvpY6j5SV8P/6EGuMcP8/O8OP0FKe/fxl93u3C1u1QigF1QKWEI4TRFqUGk3McE+tTB4Q5kFKgliQJ4jiGH/gYDocIwxBpmmE8nqAoSpRliaIoDFpXjMdjTKfTGZMJJpMptFfNmAkB2utDMAcuseESC8y2IBkFd4hBa0EJOCVwHRu+cKHiIVQcQWUZ8jw3BdXiJIEaxnDOzmF1LtD7M8O67BoDn7uIpEDkaSRCKQyB4PBcioAz5EphNBrNDPWjKEswxtDpdDCwbARhCOl5oIwhjCIkaYo4TpAkKXQBmVJQeX615vnov6FuWW/03Ukp4fs+giAw+ygMzapjnueZuNZZlpkzFbrdG4bVpVcf4DYqk3luGF5/4a5oD+11w3C+hYUDc/nrVFXfucLqkJ7TNE3N3On75JyjmudbDa9XomPaQCllVoNSM2OlFitcIB+hKAskUQLR5+A916BSZUzLYmQwH7IoMC5nmD9Fj4EeDT0S85iYH8D3fPjSN+ssHoCIAJfMA3UF+o7ARd/FJXVBiIO/q6NySnWI938AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ubuntu-installation-type",
            "title": "ubuntu-installation-type",
            "src": "/static/d039570d55356f572959db7605f02810/fcda8/ubuntu-installation-type.png",
            "srcSet": ["/static/d039570d55356f572959db7605f02810/12f09/ubuntu-installation-type.png 148w", "/static/d039570d55356f572959db7605f02810/e4a3f/ubuntu-installation-type.png 295w", "/static/d039570d55356f572959db7605f02810/fcda8/ubuntu-installation-type.png 590w", "/static/d039570d55356f572959db7605f02810/42d54/ubuntu-installation-type.png 858w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{` `}</p>
    <p>{`Click `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Continue`}</code>{` . `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e26b12ab0425b0dbdadcbe914fd7761d/3cd52/ubuntu-installation.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.05405405405406%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAADCElEQVQ4y3WS3U9bZRzHzz9i4sWyMSYD5tDFeOU0cTde4ky8c1siL0tmMq3tWgYxZleagMGEDNcDPbU753Q7p7ALL9S4LcEwYBTawlrawkBCKYHSF1rg4mOeZ+2EgBeffH9vz/d5yaOkXr4kspBgLpUilkoSk/pfPJuIV0lIPTyXZC6d5sXSIrF0itVMBmXwu+/55I03uVzfwKd1Z2g9dUZqjc/qG7jSckFy+fRbh3qtp+r5vKGJL1ou0HqiDuvuIErfnTu8f7KOj99u4aPmc5IPG5u5eLaJi41NfNDQyKWWd7l0/h0Zi5rs1fpnRa2Z906c5P7gLyiPnz7lh75efvypj96f++nt72fg3j1Uv4ZX8+HVNIb8/mpeq1XxvULVNO6qXiaeTaBMTU6i6zqmaRIMBjENA8uyCIVC2LaNbVkyt6uEbFv2RkIhRkdHJY8ejWJZNrPhWZSRkRG+vnmTWy4XLqcT57dOnE4nDsc3uFwuPG43brebLo8Hj8cjZxwOh+z1dHfTLejp4fYtF0/++BPF0HWuXb3K9c5OOjs6uC4QcXu73Oh2V5dEGAv96sYNOSfoaGuTc1+2d+Bou8bY77+hlEol1tbWWFpaYmVlhWw2y8bGxmtEvr6+zubmJltbW+RyOba3tw+Ty5EvlsgXiij5fF4apdNp0ouL0kAsFprNvjIVhmLj3d1dyuXysVTKZWksDYVZNBplfn6eeDxOIhFnYWGBTCYjzYrFIjs7O9L0IAdrIhanV0qlogw2qlcVBjWjQqHA/v4+e3t7Ug+yVylTLhakVioViZhXxHWTySSpVOo14sRCRT2RSBxBnD4eizA98UxqJBJhZmaG1X9WUcbGxlBVFU3T8Pl8hxC14xA9ucbnY3h4SMZeb/Vjj4+PEwgE5Mc2DOMI4tPXOFIXWo39/l+Zmpz6f0MzaBLwB1AHvAwPDqEOqBj3dQzd4EHQxAqaUh8GTeyHDzB1nfB0GGV5eVm+iXiv4xC9GvKtk0kmYkmehF/wPDrH39Pz/DU+y+PnYWLRGP8CUIba8gfBaHkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ubuntu-installation",
            "title": "ubuntu-installation",
            "src": "/static/e26b12ab0425b0dbdadcbe914fd7761d/fcda8/ubuntu-installation.png",
            "srcSet": ["/static/e26b12ab0425b0dbdadcbe914fd7761d/12f09/ubuntu-installation.png 148w", "/static/e26b12ab0425b0dbdadcbe914fd7761d/e4a3f/ubuntu-installation.png 295w", "/static/e26b12ab0425b0dbdadcbe914fd7761d/fcda8/ubuntu-installation.png 590w", "/static/e26b12ab0425b0dbdadcbe914fd7761d/3cd52/ubuntu-installation.png 857w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{` `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4f99e052e78b397eaa78111cdbf29537/ad12c/ubuntu-timezone.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.72972972972973%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAADeElEQVQ4y42T609bdRjHzz9g4hu5zcQszn/DVzITE0VloMnMbr4y/gcas7cuzk0jZJtmGZcBFbFlwEYvFEpbKNCW9rSUcmnXUkZXSi+n93s/5hwGhswXnuST7/f3/T158jy/5AiB589ZWV/H4Raxu92vsSrfiSJOUVS8krtcCmsuF06PB7fPh9MjEo5EEMbv3ef9N97kYts5Ols76Gxpp7P1FS3tfNh2jk/PX+Czd99T/PFdBxdbO/igpZ2P3n6HrvMX6HyrjacjowhPNZNc/qSLr3u/5HpPLzd6vuD6pR6FGz29XP28m2+vXOWbr64oXs6vdfec1lzrvqRw+eMu5nV6hFw+x1HikPjRIWkpSfwoTqlSpt5sUqnVaNLk/35yL2Er+IKpBS86yxYTBh8m+y7+4AHBSJyjZAa7N4x+aQvdkh+tdRP98rGaHQF0Vj9ayyZaWa1+guEogrgVZs4WYsA4yQ/TN1FbrRhs2zh8EeLJHA81a9x9bGV6cVPBYNvBsblP9CjDRiCG3RfBZA8wOutmfSOEIPrDjOs20NkdmKN9HCRfsBNOMja7zm9jy9wbt2F2BonGJUrlymtr7u7FebLgY2jaiWszhLDiDnDrkYn+MQcDhikc2ztojD7uDFu4PWRBt7xNqVJlL5riIC6xFYoRjaeRsgVWPHv8MmLl1xErd4etxw3XxCB9qmUeTNi4+fsqPw6s4N094GUiy+gzNwNPHFjWQ8o0W6FD+sbk2hUezzgZ17lRG738oV6lX7WMvK1gcwWUSfpVS3x/38x3/QtYnEHC0RQPJla59WiRnwbNLIt7ylp3hq3Km94etDBj9rMZjCn5z0Nm1n0hBO92BJXWjcbo4eGUh8EZkUXHLhMGD39qRWWCv+e8qGbd/KUXFS8j5+N6UambnN9QMn9gHyGbzZJMJshmJJqNOo16jWq1qqhM/ZU2G/+eFWrV03OtVqVWrZKRJARJkiiWShSLRQqFY059sUixdELpTJ5IJMjnC+RyOWKxmKJyLyGdTlMoFP6TXC5PJpUhk84gJSVymZyS5/P5M5zUp1IphEq5BM2Gsm7zRBt1oEm1VCbzMkX2UCK1n6CULdBsHv+Ksp7QqNdpNOpUymUEw4IVmTnT0hnVz1sU5kyyNzG3sIjJbMVonEc1pWdIrUU9PcuY+hnDGgMq7QKayWn+AdAgJouXeEj9AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ubuntu-timezone",
            "title": "ubuntu-timezone",
            "src": "/static/4f99e052e78b397eaa78111cdbf29537/fcda8/ubuntu-timezone.png",
            "srcSet": ["/static/4f99e052e78b397eaa78111cdbf29537/12f09/ubuntu-timezone.png 148w", "/static/4f99e052e78b397eaa78111cdbf29537/e4a3f/ubuntu-timezone.png 295w", "/static/4f99e052e78b397eaa78111cdbf29537/fcda8/ubuntu-timezone.png 590w", "/static/4f99e052e78b397eaa78111cdbf29537/ad12c/ubuntu-timezone.png 856w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{` `}</p>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`In the `}<em parentName="li">{`Who are you?`}</em>{` dialog, enter your preferred name, username, and password. Note that this user will have root/sudo privilege. Click  the `}<em parentName="li">{`Continue`}</em>{` button.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ce7fd4ee0309e69d5e84caf1f020cb62/cda19/ubuntu-acount-setting.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.86486486486486%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAABmElEQVQ4y32TT0+DQBDF+ZZK61ET4zc0elETEz14wmhUCiWU/7DdLXQpaJ6ZaUu2tfXwy8Cw+3Z25mGdjUY4G48HxraN0enpGtuGfXKCi/NzXF1e8vPI/H4AK45jBEGAKIo4SinRdR3ath3QWjOr1WonT1CO8xsspRTm8zkjhEBd14OAyXK5ZPQmHsM6tHGxWIAqz/McaZoiSRJ0XY++79F2Lceu7/Dz/YOgDFHIEq1u14LDyVqzEIlkWcqRmE59uK6LievC96d4Dz9QiYpv0zQNmmUz7Ccss1xK0KKiKFBVJcqyxGwWIgxnaOoabuQhymJIqVjQFNo+Wzv90XojMmPSJIHjOHh6fobjvELUEqoqUXk+5lE07DGjZSaUlDxt6hn1MEtivDw+4P7mGne319BCICkCZGUCXTd/BHcqNCvl91ZjIRS8t094ky947hf8iYs8zyCVZMv8O2XzFGZbtVr3S7Ct1tYirx6y1r8Vmn3dN/N2eAStoUj+PejD/SEdMzBZjKqnNfRTUN9J1DpW+jH2W2Pegq78CwaiwLJWxdU3AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ubuntu-acount-setting",
            "title": "ubuntu-acount-setting",
            "src": "/static/ce7fd4ee0309e69d5e84caf1f020cb62/fcda8/ubuntu-acount-setting.png",
            "srcSet": ["/static/ce7fd4ee0309e69d5e84caf1f020cb62/12f09/ubuntu-acount-setting.png 148w", "/static/ce7fd4ee0309e69d5e84caf1f020cb62/e4a3f/ubuntu-acount-setting.png 295w", "/static/ce7fd4ee0309e69d5e84caf1f020cb62/fcda8/ubuntu-acount-setting.png 590w", "/static/ce7fd4ee0309e69d5e84caf1f020cb62/cda19/ubuntu-acount-setting.png 785w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`Wait until the installation is finished. After the installation is complete, click the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Restart now`}</code>{` button.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bba0368bbe87f5f85b21d869c094a191/2e237/vm-restart-now.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.08108108108108%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAADTElEQVQ4y2WMz0/TdxjHPy3KgkApUTrmlB9tgc3stB2ACrSUQjoKll+DFgti6RxCWygFSh0uILSIc7vOubDEuIuZ7raFxIPCadlhf8OU4mmJGYldK5rX8v1+oUV3eOV5/3ieR/gvjhAc9TLpG2Vy9E2U3Ec0EGBuYoKgN7sXHM3Oq6EQ834/M2NjiLjOSExnyFKil4nrDKzuZ/F9JL0i9wauv2vMZKuyVrz4Xm9FoSXDd5VW7lS3cremTfa3DVl+MLRw74ydXz5qZ91o41bmXkHE3nMgsXKIa6XtfH26k2/Lzsk+drKD+Ellb+1UB+vVPdyu6pb9cqlyc/BHzJf0ETmRZe5Er0xU14fUyf54D3PHe4mU9BEt6WOt3M3KqQG5O3wr7Qu/1s2E1s241kVAe55Q8QUmi4ZYKL/MXKmPQMEgV8rGuFl3lRmdV+6mi4cJFQ8T0A4xoR1kvMgtI/0R3oJBfJpBLmk8uAq7sBc0017QQmeRjQ6Njc6iNvrfd+Iq66ZD04o9X+qtOAqt9GqsXCjsx1t4Hm/hICP5boQ7b4ChfDeeoy6+MPkIR8PMhKeZDoeIzEdYu3Gd1dU48dU4sfgKM7NhZmfDTM9MczMaxnfag/uoC88xN668fkR3bg/9xwboEZ1841/nxh+7/L2bJp1M8uSvJ+wknpHYTrCTSLD9dJtnO5LfYfefXZLJl4Q/CeMUTj7L66crtwfRccTJuXe6aRN2bs3+yNPXkHq1x2v2eJF+QepVitTevxmS6STPd5+TfJkkvZdi6uMp7MJBZ64T6Zewqx3YjzhoFq0sdi3x58bvPLr3iMc/b7L5YIvN+1vy3HqgzMf3N9m4u8Gvd37j4U8PGa64SItow57TzqdqB8KqstGssmFVtWIWVkzCTMNbNAoLFtGc0RJW0SJnFiHdHvywIRpEIxKNookmlZlGVdP/aFKbsagtb+iMV5k5+CEh6nLqUKjlbI4JU45J9vX7KLqehpyzci9Nydeqa+XderWiJerUtQijqEChXJ41wki10JPNFaqEnipRub9TwRlh4ENhpEZU8oHQy0i9WAkucZhY8Bqx4DJv58vBRWLBJb4aX2RiZIHJkStEPv8SvyfCJfcUl4fDLPgi/Adw44QI1ynHfwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vm-restart-now",
            "title": "vm-restart-now",
            "src": "/static/bba0368bbe87f5f85b21d869c094a191/fcda8/vm-restart-now.png",
            "srcSet": ["/static/bba0368bbe87f5f85b21d869c094a191/12f09/vm-restart-now.png 148w", "/static/bba0368bbe87f5f85b21d869c094a191/e4a3f/vm-restart-now.png 295w", "/static/bba0368bbe87f5f85b21d869c094a191/fcda8/vm-restart-now.png 590w", "/static/bba0368bbe87f5f85b21d869c094a191/2e237/vm-restart-now.png 790w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{` `}</p>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`When you see a screen with a black background saying `}<strong parentName="li">{`Please remove installation medium, then press ENTER:`}</strong>{`, simply hit the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ENTER`}</code>{` key.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f9318b17e44649c9704229e5fa89cb94/9a86a/vm-black-background.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.08108108108108%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA4UlEQVQ4y+2QuwrCQBBF90HI+/EFyT8kQiqtLAQlilmSjSQ2GlDz//WVXYxY+GhSWhyGmbt7GIZUVQUpJZqmeYvKR+q6/vhuhBBCMCmcc0zJ9Bv+hX/hBELTNOG6LhzH0TUIAniep1H9WMMwBKX0t9CyLERRpD/4vq8Fo1SJVK7mirdCxhheeQ1Vr+SMcy20bRtxnMAwDJ1Tyh7QJ19vmCQxiqJAlqbI8xyLxRyzWfb9hl13RKvpHhyhZ22Hc9/jehtwuVxxOvcYhgFCttjsJdZlg319wGorsSwEljuBshS4A8OscY4wTkCZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vm-black-background",
            "title": "vm-black-background",
            "src": "/static/f9318b17e44649c9704229e5fa89cb94/fcda8/vm-black-background.png",
            "srcSet": ["/static/f9318b17e44649c9704229e5fa89cb94/12f09/vm-black-background.png 148w", "/static/f9318b17e44649c9704229e5fa89cb94/e4a3f/vm-black-background.png 295w", "/static/f9318b17e44649c9704229e5fa89cb94/fcda8/vm-black-background.png 590w", "/static/f9318b17e44649c9704229e5fa89cb94/9a86a/vm-black-background.png 792w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">{`To log in, enter the password you chose earlier and press `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ENTER`}</code>{`
.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d8d264cc37c92e6d9bf18acf3e21e211/44fd6/vm-login.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.08108108108108%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAACmElEQVQ4y33P609SYRwH8N9BOKAgIKItMeFwRy7nICoU3kpdeQGvTFe5kSnaUnTVfOHWVk1ftZWis+laW63NV/2N3/Y8HG5lvfjsec73+X1/26HTjQJKhW2cbb1ocK463djCyUaBK21uVfOKs8I2vr45wOX+K1zsFEFZ0YKMaEZGV5MVa+ZEC7KqOf7diM3MN7chq7fyGVps9+JPS4z9ZssdPuRUy3YfzxZtnmqXpsx+/C2A6RvMmAOYtQSRsQT5OaPmU1V+0ANjGA1MYYxzEUyoJs1RTLQyEUzWYW9str5PQ/oYhgyNhptjGG6RuRGjgrQhyu+jJgUjLYzMsTne0Zel9THQoE5GkhHLUow+hpRewV1DnGfLkSweOsaQFFmmIKWXy9QO6w+qqE8jg0k0KVy/lpHRr1MwIMYR10SxN7+DnLwAmcI84+/aWqeyI66RQVEhihgXgyzEoGhkrk+rIKGLI2VKYtxxH2O3RjFgSCAh9vF3WaiI8S7bw1AvhcGEBSbCRTVR+MiP29SFAIWgiDJ6hQi6qZtnQQohos6yXmUHQwEKoiaEkCYMF0lYS6/iZ+kbLt6f4OroHNel7/h1eY0fH68w3HkPXvIjJPTyTqXvpyDISz7U8wsB9JAT8/I0jvff4vD5a3zYOcSXd59xdVTCp4NjJG39kMjD/6LS8wnlO7nIBUYiqcotuOEgB+xkRyd1oIPsaCMrrGSFjWxwkhNucjd0uqgLTuoBuTROVEgqfte64NZJnEcnwSu64RU98OjckJpcqO/10B2k3WkEjQGQmYwoM/2XRT2tZIKNWtFOZn7ayQILNePlagFBqx+0l99FTfGfivki9p8VUXi6i7XcNh4vbSK/UsBKZh0Ls3lMPXqCzdw6fgM/0u0BB3pOhQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vm-login",
            "title": "vm-login",
            "src": "/static/d8d264cc37c92e6d9bf18acf3e21e211/fcda8/vm-login.png",
            "srcSet": ["/static/d8d264cc37c92e6d9bf18acf3e21e211/12f09/vm-login.png 148w", "/static/d8d264cc37c92e6d9bf18acf3e21e211/e4a3f/vm-login.png 295w", "/static/d8d264cc37c92e6d9bf18acf3e21e211/fcda8/vm-login.png 590w", "/static/d8d264cc37c92e6d9bf18acf3e21e211/44fd6/vm-login.png 789w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Your Ubuntu Desktop installation is ready. Congratulations! `}</p>
    <h2 {...{
      "id": "part-5-installing-guest-additions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#part-5-installing-guest-additions",
        "aria-label": "part 5 installing guest additions permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Part 5: Installing Guest Additions`}</h2>
    <p><em parentName="p">{`This step is optional but highly recommended.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.virtualbox.org/manual/ch04.html#guestadd-intro",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Guest Additions extend the capabilities of VirtualBox in several useful ways`}</a>{`, including ...`}</p>
    <ul>
      <li parentName="ul">{`enabling bidirectional host `}{`↔`}{` guest copy / paste support`}</li>
      <li parentName="ul">{`sharing folders across host and guest systems`}</li>
      <li parentName="ul">{`dragging and dropping files between host and guest`}</li>
      <li parentName="ul">{`graphics improvements`}
        <ul parentName="li">
          <li parentName="ul">{`higher resolutions`}</li>
          <li parentName="ul">{`full screen`}</li>
          <li parentName="ul">{`app windows`}</li>
        </ul>
      </li>
      <li parentName="ul">{`improved mouse integration`}</li>
    </ul>
    <h3 {...{
      "id": "1-install-required-packages",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#1-install-required-packages",
        "aria-label": "1 install required packages permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Install required packages`}</h3>
    <p>{`Open the terminal and run the following command: `}</p>
    <p>{`If you are not sure how to open the terminal, please refer to the FAQ below.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` linux-headers-`}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$(`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`uname`}</span>{` -r`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`)`}</span></span>{` build-essential dkms`}</code></pre></div>
    <h3 {...{
      "id": "2--install-guest-additions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2--install-guest-additions",
        "aria-label": "2  install guest additions permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2.  Install Guest Additions`}</h3>
    <p>{`In order to install VirtualBox Guest Additions on your Ubuntu VM, use the menu to select `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Devices`}</code>{` `}{`→`}{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Insert Guest Additions CD image`}</code>{`.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0ffc6d607617da5f82ead6020b617e7a/59000/vm-guest-additions-cd-image.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAADqUlEQVQ4y4WUa0zbVRjGD1BgXJU5UBYFGbAFouIMxmxAGTCBQqC0ZfQClEzZ2CAMtqJ1AwSmW5ZxC5eC2dQETZYs2T64JTolZPrBRKKJyxKHoonh0pbStaPduOyD+5n+SybfPMkvz/s85+Q9J+ckRzidThwOB2urq2xsrPPLnJuffvsbq3WR9fV11jc2JF1dW+P/xj9PniA8Hg8P3A8Ytlgwt5m4NvkjC1YrS3Y7brcbt8uFx+vl95kZLnR18dGZ03SYTLSbTJL68OXdZjM3rl/3N1xyLHNBp6QhPo6bl67y58I8yw4H/s3cPFxb44/pn+mPT6M3NpWLO1IYfH43/XH+emBnOufDdnLL3OVvuLzs4OOjbdQnZTE18TVzjiUW5hdwud247rtYefSI2elfGUsuZjSxkM93l3DtlXI+S1EwkliIJbmY/pj9THUM+Rp6cdptnPqgl9I9Kr6f+IY5+wL37t7BYVtk/aGHx49XmZ2+Q2dcOZ2x5fQlaBhP1XL2hQo6Y5V0x6sxbzvIV+YxhNfrxbZoY6y/j6b9VXwxcovvZu3cnJ5l8u48t+/Z+eGv+3x7e4butEZat+swPafj/Vg9rTFaWmK0nNyhpyGwjKttlxEerwe7zcG5jnZqMpUcTzlB45tmGjJMHH/dROPe9zjy2ilM+zpoS27i3Ug99dEGGp6p5ki0gfooA0efrcEglEyc/HTzUexO2puPoU7PoyZAizFYg1FWyeGQQ9QFV1Irq5S8MUxLXaSeukgDdRF6DkcZqA3XY4yqRiXKudxyyXdCLw6rldYPR8jfW0tVoBpdpB5DpA5jtIHqSD3acC2GCB36cC36MD9V26okdGFV6CJ0FAkF482f+E9otzs4d6IFTdrblIgKlCEqKoLVqELUqEM1aEI1HAqtpDzYl6tQBqsol/nxrVGGqskV+ViafY/i8WC1Oxk9ZsSY+hYHhIKiwBIKAxQSvrpYohRFYCmFASUUBpZIuSKoVNIiWQn7RA5DTSOIFY8Xl22RMtM4e141kieyyA0oIFfkPUW+qXlb8vyAgxwQ+f48qIA3RCb9jQO+hh5cTicazTukx2UiD8omJziXbFkOWbJssmXZm5qDXCYnRyZHLs37M8mHyskQGfQ29/nv0LnspKxAQZzYzsviRZLES+wSiVK9lV0igSSRQKLk/1vjy2NEFB11ZxArKyvSJ3DlyysMnh/E0j3MaM8wlp4RidGeIcn7GXrqt86PnR1h4HQvUzcm+RdNfcrZMQMUQQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vm-guest-additions-cd-image",
            "title": "vm-guest-additions-cd-image",
            "src": "/static/0ffc6d607617da5f82ead6020b617e7a/fcda8/vm-guest-additions-cd-image.png",
            "srcSet": ["/static/0ffc6d607617da5f82ead6020b617e7a/12f09/vm-guest-additions-cd-image.png 148w", "/static/0ffc6d607617da5f82ead6020b617e7a/e4a3f/vm-guest-additions-cd-image.png 295w", "/static/0ffc6d607617da5f82ead6020b617e7a/fcda8/vm-guest-additions-cd-image.png 590w", "/static/0ffc6d607617da5f82ead6020b617e7a/efc66/vm-guest-additions-cd-image.png 885w", "/static/0ffc6d607617da5f82ead6020b617e7a/59000/vm-guest-additions-cd-image.png 917w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`As soon as you attach the Guest Additions image to the VM, you will get a prompt to run the automatic installation. Click `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Run`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/08170f3da0a249f6cfaad1f63fef7ed7/0c69d/vm-guest-additions-click-run.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.7027027027027%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABVUlEQVQY0y2L/W6aYBhHucBew65t//YGlm7J2qwVGFjXtKAC2gKNpvDy1fKCAopnAX2SX855vpQyKyi3EWX+iSwlmUhJREqR5ZcUJCI5MxYXJmOyJENEMUkkSEU6/ivVzS2fV9+oZ880gP3yjKrpmFMTwzDQdY3p44zH6RRNVTFME1VV+TebMZlM0HUd0zzfllWFUi89vr5fU79v2Hct282G+XyO9WJh2zaWZeE4zkjX88bdYrnEWw2+wFutcF0XPwjZtw1K3bbUQL3fs9vtaJqGLMvI85yiKEYOfSEEeRTzlaSjF7E4eyzIogiZ5zRSovT9kf544HTq6Q4tVV1xPPUMdbrwGIZ0S4dD+E735nPwA5r1K5Xjjj6ke32jDUKUwAvwvYBwFeLaLk/GE+vFmmEeOD6+v2Vzc4f8+Rup/kXeq1QPOvntHz5+/EI+aMh77TzXDP4DaD6079te+1UAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vm-guest-additions-click-run",
            "title": "vm-guest-additions-click-run",
            "src": "/static/08170f3da0a249f6cfaad1f63fef7ed7/fcda8/vm-guest-additions-click-run.png",
            "srcSet": ["/static/08170f3da0a249f6cfaad1f63fef7ed7/12f09/vm-guest-additions-click-run.png 148w", "/static/08170f3da0a249f6cfaad1f63fef7ed7/e4a3f/vm-guest-additions-click-run.png 295w", "/static/08170f3da0a249f6cfaad1f63fef7ed7/fcda8/vm-guest-additions-click-run.png 590w", "/static/08170f3da0a249f6cfaad1f63fef7ed7/0c69d/vm-guest-additions-click-run.png 598w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Press `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Return`}</code>{` key to complete the installation.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/01b17127135337ebadbb9691b6e6c93e/ea7fb/vm-guest-additions-complete.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACf0lEQVQ4y6VT2W7TQBS1qEibSpWgpQuloYmd2I7teI3jNU5iO06cNEtbNoGoWglUCYQKD5QXXnhH/PJBM22pAFWq1IejM3Nn7rn3Ho+ZyFDQaeloqCoMy6Co8jWU2QplSZEhynU0W014gQe5IdOYUBcgKRKFoipQTQOeIYP55bfwI3fh6QFsUYfJa7BFA7ZkIjJ9ZG6MzI8xCFOkThcdM8DAixGqDlzJQtBwoJUlSLsShkIdzHP/EFNrgrHYxoE+xKzRx1js4pmZY8RH6JVamMgx4qcOgkcqnIcymisi3FUF5nIN2iIH/RJygQPz9s1X5PkHTKUIM32AAyvHXM8wbSSYaxmGfIRhrU1FtCUOWpGDXqz+BRpf4mAUq2DOT97h7OUxsmqIiZJgLPWwJ/WwrxOxNnKhQzncMuCuKWg/NhFsaPDWVRrz1zWEm4RVKsqcn57h0+tTpBUfI7GLAR9hJHQwU1NknI+U9ZFWPPRZn+67OzYyLkC3dMHkLNq2aJHWAwnMyWSAoyxDUg5pV2MppsKH5hBZNYC71qA+kqSr5HjXQWfbot05qwr05evxmcN0imk4ol7tyTEFWRPxXIioFWRNisy1PmViw1RJaAFSsPOkSWGtCGAmx98xe/EFQ9bFVO1TD8f1LvaNAR0zqXgYVAMkZRe9kk2tIcmk62TXpT6Srom3Nhn5fSfBx3yOmA3Q27HpwVUCGSnY0OGsymgUKlAX2WuQfaHy5wtfgfmZB/jWC1FbYKEtXiYV2P8u//tUbgJTST+j5hxBu7cNfZm/8eJtRRlnfIpW+Arqwg508vJv2cmNglqJh7p1KUT/hDsK2ooNu2ZBuV++GK3I3UnwN/dAzKBSt6fZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vm-guest-additions-complete.png",
            "title": "vm-guest-additions-complete.png",
            "src": "/static/01b17127135337ebadbb9691b6e6c93e/fcda8/vm-guest-additions-complete.png",
            "srcSet": ["/static/01b17127135337ebadbb9691b6e6c93e/12f09/vm-guest-additions-complete.png 148w", "/static/01b17127135337ebadbb9691b6e6c93e/e4a3f/vm-guest-additions-complete.png 295w", "/static/01b17127135337ebadbb9691b6e6c93e/fcda8/vm-guest-additions-complete.png 590w", "/static/01b17127135337ebadbb9691b6e6c93e/ea7fb/vm-guest-additions-complete.png 788w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Reboot your system. You may need to enter your password in order to restart the VM. `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`reboot`}</span></code></pre></div>
    <h2 {...{
      "id": "faq",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#faq",
        "aria-label": "faq permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FAQ`}</h2>
    <h3 {...{
      "id": "how-do-i-open-the-terminal",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-do-i-open-the-terminal",
        "aria-label": "how do i open the terminal permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`How do I open the terminal?`}</em></h3>
    <p>{`Open the activity menu and type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`terminal`}</code>{` and hit `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Enter`}</code>{` key (or select the terminal icon).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/94df91e69ffe9801f7f4d6e6dbea5393/8ea22/faq-terminal.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.27027027027026%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAADCElEQVQ4y1WP3WsjZRTGzzuTtknTZjKTzGQyyUzmOzOTZptqL9babetuytK6VnSFIoogZf0Cd0EUxIsVWRcFL7xREP/Zn8xsFbx4OPA8z/s755Xr4zssfY9gp0fcN0n7FvGuwWEU896DNVdnb/H4/JyPr664vrjk/fU5+9Og6dTdyOgTmxa5MyKyBsjNGyeUhk2sOuStHarNHpm0eVQd8teLX/n9++f8+ePLRn88/5m/X/7Gw7Si0repNg3y1i6p3mWmOkxVGzl59zNiMyJRBsW2S7nlsGg77HeHZNJlb8Mmk10KMSlbNrF0OOg4rLouRZ1tDElaA6KWRaD3ke+efsVylDKTAWerIyor4R1rzi8PTik3pnx785SzyYr18iGf3vxAJSMOezHLrSm5PibVXWLNIdRsfG2APPnkMYUV4ovLwaCkaMe81sm5b63Ya+ecTu/y+k7FSfgmJ+ExpQqpNmLmekiq+cTahFB5BMrFUzby4QePSI0a6BEpn1SFpCpm3kpZtOaUknFnc8HB9pJSEkoto9RTcj0mUSGh8gnUhIka44qDfPTNC/LRAk9GhFpArEIyLabQM0otZ6GVLLUFe1rFQi8bL9eyplN3Z+IzlQljcXFq4E9Xd6nMAEfGBMonVDMSLSLXUuZazlzmVFrZqFBzcpWRqISohqkZvpoyFg9HRtg1cH15jW9E2GLjicdEpgQSEKqQWMWkKiVTKYkkxJIQScRMZvjNZdPmTQ0bisNQbCS/eILbixiI1QQjcXHFw5NJA/claBbUmorfeF7zRQ9Xxs1VNWggQ0yxkFWe4XRsTDEbsw7rUq1/F7xaMm5m7b3S/0F96WOIgXzx+Zf4lk9XupjSx1IWAzVgqIaNLBkwlCG2cm6n3fh1p+6ayqSvDAzVo6d2kbcvLhkZDh3ZYkd1m9m+1ba0GbZMdqXLtnSa2ZH2f3lbNtmUFhuio4uGEoU8+/oZ6/tr7h0dc3p8j2W2oAoLqqhglZQcVfscZgv2opJlUrGIiluVlNGcIsqZRzl5lJFHKf8AQPx45oxo2JwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "faq-terminal",
            "title": "faq-terminal",
            "src": "/static/94df91e69ffe9801f7f4d6e6dbea5393/fcda8/faq-terminal.png",
            "srcSet": ["/static/94df91e69ffe9801f7f4d6e6dbea5393/12f09/faq-terminal.png 148w", "/static/94df91e69ffe9801f7f4d6e6dbea5393/e4a3f/faq-terminal.png 295w", "/static/94df91e69ffe9801f7f4d6e6dbea5393/fcda8/faq-terminal.png 590w", "/static/94df91e69ffe9801f7f4d6e6dbea5393/efc66/faq-terminal.png 885w", "/static/94df91e69ffe9801f7f4d6e6dbea5393/8ea22/faq-terminal.png 1023w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{` `}</p>
    <h3 {...{
      "id": "how-do-i-copy-and-paste-in-ubuntu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-do-i-copy-and-paste-in-ubuntu",
        "aria-label": "how do i copy and paste in ubuntu permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`How do I copy and paste in Ubuntu?`}</em></h3>
    <p>{`The keyboard shortcuts for copying and pasting differ depending on whether there are executed within the terminal elsewhere.`}</p>
    <h4 {...{
      "id": "terminal",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#terminal",
        "aria-label": "terminal permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Terminal`}</h4>
    <p>{`To copy text from the terminal/CLI prompt, use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`SHIFT+Ctrl+c`}</code>{`.  To past into the terminal, use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`SHIFT+Ctrl+v`}</code>{` `}</p>
    <h4 {...{
      "id": "everywhere-else",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#everywhere-else",
        "aria-label": "everywhere else permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Everywhere else`}</h4>
    <p>{`To copy text elsewhere (VM's web browser, code editor, etc.), use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Ctrl+c`}</code></p>
    <p>{`To paste text anywhere other than the terminal, use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Ctrl+v`}</code>{`.`}</p>
    <h3 {...{
      "id": "how-do-i-copy-and-paste-from-host-to-guest-and-guest-to-host",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-do-i-copy-and-paste-from-host-to-guest-and-guest-to-host",
        "aria-label": "how do i copy and paste from host to guest and guest to host permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`How do I copy and paste from host to guest and guest to host?`}</em></h3>
    <p>{`To enable bidirectional copy / paste, you must first install `}<a parentName="p" {...{
        "href": "https://www.virtualbox.org/manual/ch04.html#guestadd-install",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Guest Additions`}</a>{`.  From the VM menu, navigate to `}<strong parentName="p">{`Settings`}</strong>{` `}{`→`}{` `}<strong parentName="p">{`General`}</strong>{` `}{`→`}{` `}<strong parentName="p">{`Advanced`}</strong>{` and select `}<em parentName="p">{`Bidirectional`}</em>{` from the `}<strong parentName="p">{`Shared Clipboard`}</strong>{` dropdown.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1fe4dcb6f53ed142fc7d662494389d7b/c7dcc/setting-shared-clipboard.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.48648648648649%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB0klEQVQoz62TW2vUQBiG8/8vRG+EXloQoYLghVKr1dbi2j3Uxd12N81hkpl8k2SzyWabRRBvHkl6YCmIB7x4+JibZ96Xb8Z5OhImUcZQKs5MzVuvJMwb3KxhvrjiQkr2L1ImssJLS/RqQ182fN+sObcN86zhW7Omrtes12ucZ+9GnH51efHF5/PccDBW9GaGk1nCOM4ZegkHZz79MGPkCWMlvJkZ6iJn6BoGbkxdLimrirIscUT5pGLIEsMys+RGYWKF8j3c8ylGBUis0CqgyFNU4BNeuigVIUYTqZAszymKguVyiZMWS2KbEoslSoQgsShJCWLNp/6AeRASxTGu62KShCiK8Xwfz/MQsZ1osVhgrUVEcGQ2ZX64j3v8nsvjQ4LTHvHZANX7yOjVS6KTD4jWWJtijCFJkjvac1tztVqR5/m1MO0dMd15yGDnEf3HDwj3drnY3SHce8KP/hGb188xvocRITHmTno7tdYd7blN6dgsR9uU8WTKeHpOEMXMLj20WKqrK2RRdFVvZffZTtsJ25jG6C6u3KS4nVrHGK2vZTfS7cr3628Jf33zn/Jb4b/QLaXdTpZl/4XW5VRVxd/SPpX2m7VVwzCkaZouXV3X/AReUWWBUwdg9AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "setting-shared-clipboard.png",
            "title": "setting-shared-clipboard.png",
            "src": "/static/1fe4dcb6f53ed142fc7d662494389d7b/fcda8/setting-shared-clipboard.png",
            "srcSet": ["/static/1fe4dcb6f53ed142fc7d662494389d7b/12f09/setting-shared-clipboard.png 148w", "/static/1fe4dcb6f53ed142fc7d662494389d7b/e4a3f/setting-shared-clipboard.png 295w", "/static/1fe4dcb6f53ed142fc7d662494389d7b/fcda8/setting-shared-clipboard.png 590w", "/static/1fe4dcb6f53ed142fc7d662494389d7b/c7dcc/setting-shared-clipboard.png 641w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "how-do-i-restart-a-vm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-do-i-restart-a-vm",
        "aria-label": "how do i restart a vm permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`How do I restart a VM?`}</em></h3>
    <p>{`If your VM is unresponsive, you can force it to reset by right-clicking the entry for the VM in left pane of the VirtualBox window and selecting `}<strong parentName="p">{`Restart`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c169f83527f4a8ac46aa32bf59b817f3/38124/faq-reset.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.37837837837838%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB2ElEQVQoz42SS2/aQBSF/f9/QtNN15UqNeqiEYsooVGUKMXBGPDYxjMGYx7mFQcw76+aqYJoQ6UuvoUt3+Nzzr2WY//AEQ4yDAkD3yBDQcWPqTWr+PY1dtXlrnqFXa0gPEEQ+Pi+TxAEhHruBKv02ODuwcZptnCFxNVCQlK2BTfla57vSzQ8n9uHEr4viOMYKSVKKTzPo1ar4bruEevL5Vc+39hc3isqyQbHT3BVRjoYIaOIZ8el2+3y9PiT4TBju91SFIVhPp+T5/kfWEGkCCJJ0FLIuE3drSGjFovFgmVRMJlMWC6XFKuC1WrFZrPhcDiw3+/Z7XbvsJSSxEqhpKTTjkmTmNkkY7tasNssKRY5/X7fdFav1xFCmOcsy1iv1+Ynp1i6j9+dSMKWovzkUZdjwu4c0cnx1Ji01zfDp1F1Av3ubyxdrpSKdiypNiQX30Iuvnf5cNXjYynjUykhaEkjkOevJr6Ofc6dcagFNVEk6XRi0nbIeNBmv3qB3ZzX6YBBv89wOCRNU6bTqVmMFj3HUVDH1ifR9AROrU7aGzB7yRkMM3Nf2qEuXQ+dc/bO4Ztgo9EgiiKSJGE0Gpktz2Yz8/G5zv7RoTyiN6nvTsfT6I2+if2P6C/9ey8NSdaVrQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "faq-reset",
            "title": "faq-reset",
            "src": "/static/c169f83527f4a8ac46aa32bf59b817f3/fcda8/faq-reset.png",
            "srcSet": ["/static/c169f83527f4a8ac46aa32bf59b817f3/12f09/faq-reset.png 148w", "/static/c169f83527f4a8ac46aa32bf59b817f3/e4a3f/faq-reset.png 295w", "/static/c169f83527f4a8ac46aa32bf59b817f3/fcda8/faq-reset.png 590w", "/static/c169f83527f4a8ac46aa32bf59b817f3/efc66/faq-reset.png 885w", "/static/c169f83527f4a8ac46aa32bf59b817f3/38124/faq-reset.png 953w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Warning: From your VM's perspective, this is equivalent to unplugging the power from your computer.`}</strong></p>
    <h3 {...{
      "id": "how-do-i-shut-down-a-vm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-do-i-shut-down-a-vm",
        "aria-label": "how do i shut down a vm permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`How do I shut down a VM?`}</em></h3>
    <p>{`If you are running an Ubuntu 20.04 VM, click the power icon in the upper righthand corner of your screen and select `}<strong parentName="p">{`Power Off / Log Out`}</strong>{` `}{`→`}{`  `}<strong parentName="p">{`Power Off...`}</strong>{`. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/966931eea7245d06ace1f374d8daf3d3/44fd6/faq-shutdown.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.08108108108108%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD0UlEQVQ4y2WR60/bVRjHD+UiY+CCYwwvI7SUwViC4kygQAvtuEPBQOmFW6GAUCiwcSm3ZUxuY0EHOhMyNydR3xgSfbG5ZK+8xsxM3+hfYCTcCgUqDoTix/DDkBFffPL5Pud58pyTHNFot9Pe3Mwlq5X2KittNtsRWqwHZ32tDnodLTSbzNhNpkPbjSauNDTQ31CPs7YG0aFJpDMuCmd8Ek5dJtdfiGI0PJrr4dGMn1YyFh7N2Gklo2FyJhQJPLjxDg8npngw/i5fTUzyaPJ9vn7vFj/NzvJlawei+w0dA+eSuZVqYCq/mttyHdNyHTNns/g0Npvbiot8qMxk+oyGz1RGdvf22AN2tvfw7gfgnwPx+/c/I8pbJzElWBgPzWQ4LI+RFwsYishn4hU9NyOLpHrsZT3DYTlMvVqJ27WCy7XM0tISC/PzeDY22P7rqXTJb/e/Q7xltlIZlc94TjsfXB6lK6iYnlMG+sPL6DtVhvOkgZ4wA50hxYycb8C9soprxcX62hper5ednR22nm5JL/z14WPE9NRNWlVW2qNquaJppznYTPMJMz2RdVyOsGIPMeKMrGf0gpNrr11ieWGJ+YV53G43Ho+Hzc1N/t7elhb+cv8xoq5UjzmuCFuAGdsxM3XPV1AXUk5jaBX1JyqwBVtoiajDHlZLu6KJtdU1FhcXmZubw7WywurqKn9uePDi5ccvfkA0lOVjiCmgMsBCdXA5VUEWaoLLsYVUYD1uOawr/Aw45I0sLyyzML/A3B9zrLvXca+6cS252Nrd4tvPv0FUto2SF2fCKCvBctyEJchEVYiFymCzlI2BRiqCzRj9S7ErGtllF8+2h5UNFwf/DXt4JT959ARhLKsjV6FHL4opDiih5LlSDIGllB0zYAg0SN6n2O9NTKEW7nTd5aOee9zpPvDHfTPcdd5j5uonjBjGELNVWmqVarSikHzfQvJkheTKDlzop0fvVyRR6FdErk8BGqGVUAstGUJHutCSJtJJEWoyxEVEmtaC6qVMdEKHTpaFzucoWp9MydmyHMlZ/rlk+ueQG5AnsZ8P8ctGXGiZ5vVYA2qRhMYngzSh/h9qoSHdJ/2ZnHFYa0Q66mdmhfLkGRJCzqOSqUjxTZFI9k1G9R8HWUWabyqpvimS92eTZEmkyFIO8z7JsiREYlwiMUHRKEQkSiEnVig5KxQoRdQRYoScGKEgRkQRK+TECyXxIoY4oeCcUBAv9eSI4atDDHVfY7hlkGHHICOOtyWGHdeOMLTfax1koGkQR3U/bdW9dNb20mTqoLq0jZryLgZsTv4Fd3q5TXngfysAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "faq-shutdown",
            "title": "faq-shutdown",
            "src": "/static/966931eea7245d06ace1f374d8daf3d3/fcda8/faq-shutdown.png",
            "srcSet": ["/static/966931eea7245d06ace1f374d8daf3d3/12f09/faq-shutdown.png 148w", "/static/966931eea7245d06ace1f374d8daf3d3/e4a3f/faq-shutdown.png 295w", "/static/966931eea7245d06ace1f374d8daf3d3/fcda8/faq-shutdown.png 590w", "/static/966931eea7245d06ace1f374d8daf3d3/44fd6/faq-shutdown.png 789w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{` `}</p>
    <h1 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next Steps`}</h1>
    <p>{`For an introduction to the Linux command line, `}<a parentName="p" {...{
        "href": "/tutorials/linux-command-line-basics"
      }}>{`see this tutorial`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      