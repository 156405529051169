import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`For this assignment, you're going to follow through with the exercise described in the `}<strong parentName="p">{`planning your career`}</strong>{` unit](/courses/hlt-pro/unit-1/planning-your-career/#what-kind-of-career-do-you-want) and add your writeup to `}<a parentName="p" {...{
        "href": "https://github.com/uazhlt-ms-program/professionalism-for-hlt",
        "target": "_self",
        "rel": "nofollow"
      }}>{`https://github.com/uazhlt-ms-program/professionalism-for-hlt`}</a>{`.`}</p>
    <p>{`After creating your directory as outlined in the repo README, add the following to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<net-id>/assignment-1.md`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "markdown"
    }}><pre parentName="div" {...{
        "className": "language-markdown"
      }}><code parentName="pre" {...{
          "className": "language-markdown"
        }}><span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Briefly describe your career goal`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
delete this comment and add your response here.  
The career goal you choose for this exercise can be described in terms of role, company, activities, etc. 
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Identify at least 3 professionals that have achieved this career goal`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
delete this comment and add your response here.  
You can find professionals on sites like LinkedIn (recommended). 
When reviewing professional profiles, 
pay attention to the provided work and education history 
to better understand the individual's progression toward the realized goal. 
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`####`}</span>{` Person/Company 1`}</span>{`
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`title/focus (if company)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- delete this comment and add your response here -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`LinkedIn profile URL (or equivalent)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- delete this comment and add your response here -->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`####`}</span>{` Person/Company 2`}</span>{`
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`title/focus (if company)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- delete this comment and add your response here -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`LinkedIn profile URL (or equivalent)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- delete this comment and add your response here -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`Estimated years of profesional experience`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- delete this comment and add your response here -->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`####`}</span>{` Person/Company 3`}</span>{`
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`title/focus (if company)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- delete this comment and add your response here -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`LinkedIn profile URL (or equivalent)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- delete this comment and add your response here -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`Estimated years of profesional experience`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- delete this comment and add your response here -->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Describe any trends or shared background among the professionals you researched`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
delete this comment and add your response here. 
Consider dimensions like education, prior employers, prior titles, published papers, etc. 
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` How does this compare with your background and current skillset?`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
delete this comment and add your response here. 
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Identify three enabling activities you can pursue that will bring you closer to your ultimate career goal`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
delete this comment and provide any needed context here. 
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`####`}</span>{` Activity 1`}</span>{`

`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`activity/sub-goal`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- delete this comment and add your response here. -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`estimated time to achieve`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- delete this comment and add your response here. -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`motivation`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- delete this comment and add your response here. -->`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
delete this comment and feel free to add any additional information here. 
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`####`}</span>{` Activity 2`}</span>{`

`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`activity/sub-goal`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- delete this comment and add your response here. -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`estimated time to achieve`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- delete this comment and add your response here. -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`motivation`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- delete this comment and add your response here. -->`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
delete this comment and feel free to add any additional information here. 
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`####`}</span>{` Activity 3`}</span>{`

`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`activity/sub-goal`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- delete this comment and add your response here. -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`estimated time to achieve`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- delete this comment and add your response here. -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`motivation`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`: `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- delete this comment and add your response here. -->`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
delete this comment and feel free to add any additional information here. 
-->`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      