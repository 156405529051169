import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`For this assignment, you're going to conduct an `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Informational_interview",
        "target": "_self",
        "rel": "nofollow"
      }}>{`informational interview`}</a>{` with a professional in your target career.`}</p>
    <p>{`This interview can also serve as an opportunity to expand your professional network.  For instance, you might use this as a way of reaching out to a new potential connection on LinkedIn.  I recommend talking with someone who is not too far removed from the position you're targeting in your job search, but you're free to select someone more senior.  You'll submit your assignment through `}<a parentName="p" {...{
        "href": "https://github.com/uazhlt-ms-program/professionalism-for-hlt",
        "target": "_self",
        "rel": "nofollow"
      }}>{`https://github.com/uazhlt-ms-program/professionalism-for-hlt`}</a>{`.`}</p>
    <p>{`In your directory, add the following to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<net-id>/assignment-3.md`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "markdown"
    }}><pre parentName="div" {...{
        "className": "language-markdown"
      }}><code parentName="pre" {...{
          "className": "language-markdown"
        }}><span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`##`}</span>{` Interviewee introduction`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
Delete this comment and add your response here.
Provide a short introduction of the person you decided to interview
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`Name`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`:
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`Title`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`:
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`Organization`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`:
`}<span parentName="code" {...{
            "className": "token bold"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`LinkedIn profile URL (if available)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`**`}</span></span>{`:

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Why I chose this person`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
Delete this comment and add your response here.
Briefly describe why you decided to interview this person, how you met them, and your goals for the interview.
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`##`}</span>{` The interview`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
This informational interview is an opportunity to gain insight into your chosen career.  This could focus on job search/interview preparation, things to expect at a particular organization, responsibilities, or something else.
Out of respect for the person you're interviewing, spend some time preparing a set of thoughtful guiding questions for your conversation.  Of course, you'll also want to introduce yourself and explain why you interested in conducting the interview and what you hope to get out of it.

Below are some sample questions, but feel free to select your own or adapt these to suit your goals:

- How did you find your first job in the HLT industry?
- How did you find your current job?  
  - What was the application process like? 
  - What do you think made you stand out? 
  - How did you prepare for your interview?  
- What advice would you give to someone looking for their first job in the HLT industry?
- If you could go back in time to when you applied to your current job, what would you tell yourself?  
- How do you see the industry/field changing?
- What does it take to be successful in your job/this industry?
- What preparation or prior experience has been most useful to you in your current job?
- What have you found most challenging about your current job? 
- What is the most important lesson you feel you've learned in your current job?
- What are the worst parts of your job?
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Q1: ???`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
Delete this comment and add your response here.
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Q2: ???`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
Delete this comment and add your response here.
-->`}</span>{`


`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Q3: ???`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
Delete this comment and add your response here.
-->`}</span>{`


`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Q4: ???`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
Delete this comment and add your response here.
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Q5: ???`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
Delete this comment and add your response here.
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Q6: ???`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
Delete this comment and add your response here.
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
Please feel free to add additional questions!
-->`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      